<div class="loyalty__card--container fade-in">
  <p class="basic__info--title">
    {{'loyalty_title' | translate}}
  </p>
  <div *ngIf="!userService?.userInfo.QRCode">
    <p class="basic__info--warning">
      {{'loyalty_warning' | translate}}
    </p>
    <p class="basic__info--desc">
      {{'loyalty_desc' | translate}}
    </p>
  </div>
  <div class="loyalty__details--wrapper" *ngIf="userService?.userInfo.QRCode">
    <div class="loyalty__card--details">
      <p class="loyalty__details--text">{{userService?.userInfo.firstname}}</p>
      <p class="loyalty__details--text">{{userService?.userInfo.telephone}}</p>
      <p class="loyalty__details--text">{{'you_have' | translate}} <span class="loyalty__points">{{userService?.userInfo.balance}}</span> {{'points' | translate}}</p>
    </div>
    <img src="{{userService?.userInfo.QRCode}}" class="qr__code--img">
  </div>
</div>
