import {Component, Inject} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {AuthService} from './services/auth.service';
import {BrowserService} from './services/browser.service';
import {UserService} from './services/user.service';
import {Router, NavigationEnd} from '@angular/router';
import {environment} from '../environments/environment';
import {CartService} from './services/cart.service';
import {DOCUMENT} from '@angular/common';
// tslint:disable-next-line:ban-types
declare let gtag: Function;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'yasermall-ng';
  public showCookie = false;
  public lang: string;
  public isMobile = this.browserService.isMobileDevice();
  public showSmartBanner = false;

  public cartCount;

  public showLogin = false;

  constructor(public translate: TranslateService,
              public router: Router,
              private authSerivce: AuthService,
              public browserService: BrowserService,
              public cartService: CartService,
              private userService: UserService,
              @Inject(DOCUMENT) private document: any) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    if (localStorage.getItem('hideCookie') !== 'true') {
      this.showCookie = true;
    }
    if (location.href.includes('?lang=ar')) {
      localStorage.setItem('lang', 'ar');
      this.translate.setDefaultLang('ar');
    } else if (location.href.includes('?lang=en')) {
      localStorage.setItem('lang', 'en');
      this.translate.setDefaultLang('en');
    }
    if (localStorage.getItem('lang') === 'ar' || localStorage.getItem('lang') === 'en') {
      this.lang = localStorage.getItem('lang');
      this.translate.setDefaultLang(this.lang);
    } else {
      localStorage.setItem('lang', 'ar');
    }
    this.document.documentElement.lang = localStorage.getItem('lang');
    this.router.events.subscribe((val: any) => {
      if (val.urlAfterRedirects && val.urlAfterRedirects.includes('?showLogin=true')) {
        this.showLogin = true;
      }
    });
    if (this.userService.userInfo) {
      this.getCart();
    }
    this.checkForSmartBanner();
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        gtag('config', 'G-DYBERZF36P',
          {
            page_path: event.urlAfterRedirects,
            page_title: event.urlAfterRedirects.split('/').join(' ')
          }
        );
      }
    });
  }

  public getUserData(): void {
    this.userService.load();
  }

  public setLoading(): void {
    this.browserService.setLoading(true);
    setTimeout(() => {
      this.browserService.setLoading(false);
    }, 1500);
  }

  public getCart(): void {
    this.cartService.getCartItems(environment.listingImgSize).subscribe(
      (res) => {
        this.cartCount = res.total_products;
      },
      (err) => {

      }
    );
  }

  public checkForSmartBanner(): void {
    setTimeout(
      () => {
        if (localStorage.getItem('smart-banner') !== 'true') {
          this.showSmartBanner = true;
        }
      }, 3000
    );
  }
}
