<div class="static__page--container fade-in container__max-width mobile_menu--padding" [class.header-margin-top]="!isMobileWebView">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <div class="en" *ngIf="translateService.instant('dir') === 'en'">
          <p class="static__page--title">Privacy Policy</p>
          <p class="static__page--title">What information do we collect?</p>
          We collect information from you when you register on our site, place an order, subscribe to our newsletter, respond to
          a survey, and fill out a form or Facebook profile.

          When ordering or registering on our site, you may be asked to enter your name, e-mail address, phone number or
          country. You may, however, visit our site anonymously.

          We implement Google Analytics features based on Display Advertising (e.g. Remarketing, Google Display Network
          Impression Reporting, DoubleClick Campaign Manager Integration, or Google Analytics Demographics and Interest
          Reporting.)

          You can opt-out of Google Analytics for Display Advertising. To prevent your data from being used by Google Analytics,
          go to Google Analytics opt-out page.

          We use data from Google's Internet-based advertising or third-party audience data (such as age, gender and interest)
          with Google Analytics. We, along with third-party vendors (including Google), use first-party cookies (such as the
          Google Analytics cookies) and third-party cookies (such as the DoubleClick cookies) to report how your ad viewings and
          other interactions with these ads are related to visits to our website.

          You can read more about the cookies used by Google Analytics advertising features, by going to the Google Analytics
          Privacy Policy page.

          <p class="static__page--title">What we use your information for:</p>
          Any of the information we collect from you may be used in one of the following ways:
          To personalize your experience: your information helps us to better respond to your individual needs
          To improve our website: We continually strive to improve our website based on the information and feedback we receive
          from you
          To improve customer service: Your information helps us to effectively respond to your customer service requests and
          support needs
          To process transactions: Your information - whether public or private - will not be sold, exchanged, transferred, or
          given to any other company for any reason without your
          consent, other than for the express purpose of delivering the purchased product or service requested
          To administer a contest, promotion, survey or other site feature
          To send periodic emails: The email address you provide for order processing may be used to send you information and
          updates pertaining to your order, in addition to
          receiving occasional company news, updates, related product or service information, etc.
          Note: If at any time you would like to unsubscribe from Yaser mall&bros emails, there is a "one-click unsubscribe"
          button available at every Yaser mall&bros email.

          <p class="static__page--title">How we protect your information:</p>
          We implement a variety of security measures to maintain the safety of your personal information, when you place an
          order with us, or enter, submit, or access your personal information.

          We offer the use of a secure server. All supplied sensitive/credit information is transmitted via Secure Socket Layer
          (SSL) technology and then encrypted into our Payment gateway provider’s database. This is only accessible by those
          authorized with special access rights to such systems, and are required to keep the information confidential.

          After a transaction, your private information (credit cards, social security numbers, financials, etc.) will not be
          stored on our servers.


          <p class="static__page--title">Do we use cookies?</p>
          A Cookie is a small data file that is stored by your Web browser on your computer.
          We use Cookies to keep track of your current shopping session, to enable you to proceed to checkout at any time and to
          personalize your online experience. If you do not accept Cookies you will be unable to use this website.
          Do we disclose any information to outside parties?
          We do not sell, trade, or otherwise transfer your personally identifiable information to outside parties.
          This does not include trusted third parties who assist us in operating our website, conducting our business, or
          servicing you, as long as those parties agree to keep this information confidential.
          We may also release your information when we believe is appropriate. For example; to comply with the law, enforce our
          site policies, protect our property and safety, or to protect our rights and the rights of others. However,
          non-personally identifiable visitor information may be provided to other parties for marketing, advertising, or other
          uses.

          <p class="static__page--title">Customer care</p>
          Please contact our Customer Care team during business hours for immediate assistance:
          Email us at: yasermall@live.com
          Phone: (+962) 65813168
          Yaser mall&bros
          To contact Yaser mall&bros, drop us an email or contact us directly for any inquiries, complaints, or suggestions on
          any of our products or services:

          Direct: (+962) 65813168
          P.O. Box: 144082 Amman 11811
          Email Address: yasermall@live.com
          Our Address:
          5, Radi Annab, Aljnadweel, Amman, Jordan
          Your Consent
          By using our site, you consent to our web site privacy policy.
          Changes to our Privacy Policy
          If we decide to change our privacy policy, we will post such changes on this page.
          <p class="static__page--title">Contacting Us</p>
          If you have any questions regarding this privacy policy, do get in touch using the information above.
          Yaser mall Save More!!
        </div>
        <div class="ar" *ngIf="translateService.instant('dir') === 'ar'">
          <p class="static__page--title">سياسة الخصوصية</p>
          <p class="static__page--title">ما هي المعلومات التي نقوم بجمعها؟</p>
          نقوم بجمع معلومات منك عند التسجيل على موقعنا، وتقديم طلب، والاشتراك في النشرة الإخبارية، والرد على استبيان، وملء نموذج أو ملف شخصي على Facebook.
          عند الطلب أو التسجيل على موقعنا، قد يُطلب منك إدخال اسمك أو عنوان بريدك الإلكتروني أو رقم هاتفك أو بلدك. يمكنك، ومع ذلك، يرجى زيارة موقعنا مجهول.
          نحن ننفذ ميزات Google Analytics استنادًا إلى الإعلانات الصورية (مثل تجديد النشاط التسويقي، أو إعداد تقارير ظهور شبكة Google الإعلانية، أو دمج DoubleClick Campaign Manager، أو تقارير الخصائص الديمغرافية والاهتمامات في Google Analytics).
          يمكنك إلغاء الاشتراك في Google Analytics للإعلان على الشبكة الإعلانية. لمنع استخدام Google Analytics لبياناتك، انتقل إلى صفحة إلغاء الاشتراك في Google Analytics.
          نستخدم بيانات من إعلانات Google المستندة إلى الإنترنت أو بيانات جمهور الطرف الثالث (مثل العمر والجنس والاهتمامات) مع Google Analytics. نحن، جنبًا إلى جنب مع موردي الجهات الخارجية (بما في ذلك Google) ، نستخدم ملفات تعريف ارتباط الطرف الأول (مثل ملفات تعريف ارتباط Google Analytics) وملفات تعريف ارتباط الطرف الثالث (مثل ملفات تعريف الارتباط DoubleClick) للإبلاغ عن كيفية عرض إعلاناتك والتفاعلات الأخرى مع هذه الإعلانات تتعلق بزيارات إلى موقعنا على الإنترنت.
          يمكنك قراءة المزيد حول ملفات تعريف الارتباط التي تستخدمها ميزات إعلانات Google Analytics، بالانتقال إلى صفحة سياسة خصوصية Google Analytics.

          <p class="static__page--title">لماذا نستخدم معلوماتك من أجل:</p>
          يمكن استخدام أي من المعلومات التي نجمعها منك بإحدى الطرق التالية:
          لتخصيص تجربتك: تساعدنا معلوماتك على الاستجابة بشكل أفضل لاحتياجاتك الفردية
          لتحسين موقعنا الإلكتروني: نسعى باستمرار لتحسين موقعنا بناءً على المعلومات والتعليقات التي نتلقاها منك
          لتحسين خدمة العملاء: تساعدنا معلوماتك على الاستجابة بفعالية لطلبات خدمة العملاء واحتياجات الدعم
          لمعالجة المعاملات: لن يتم بيع معلوماتك - سواء كانت عامة أو خاصة - أو تبادلها أو نقلها أو إعطاؤها إلى أي شركة أخرى لأي سبب من الأسباب دون
          الموافقة، بخلاف الغرض الصريح لتقديم المنتج أو الخدمة المشتراة المطلوبة
          لإدارة مسابقة أو ترويج أو استطلاع أو ميزة أخرى في الموقع
          لإرسال رسائل بريد إلكتروني دورية: يمكن استخدام عنوان البريد الإلكتروني الذي تقدمه لمعالجة الطلب لإرسال المعلومات والتحديثات المتعلقة بطلبك، بالإضافة إلى تلقي أخبار الشركة والتحديثات والمعلومات ذات الصلة بالمنتج أو الخدمة وما إلى ذلك.

          ملاحظة: إذا أردت في أي وقت إلغاء الاشتراك في رسائل البريد الإلكتروني الخاصة بـ Yasermall&bros، فهناك زر "إلغاء الاشتراك بنقرة واحدة" متاح في كل بريد إلكتروني لـ Yasermall&bros

          <p class="static__page--title">كيف نحمي معلوماتك:</p>
          نقوم بتنفيذ مجموعة متنوعة من الإجراءات الأمنية للحفاظ على سلامة معلوماتك الشخصية، عند تقديم طلب معنا، أو إدخال معلوماتك الشخصية أو إرسالها أو الوصول إليها.
          نحن نقدم استخدام خادم آمن. يتم إرسال جميع المعلومات الحساسة / الائتمانية المقدمة عبر تقنية Secure Socket Layer (SSL) ثم يتم تشفيرها في قاعدة بيانات مزود بوابة الدفع الخاصة بنا. لا يمكن الوصول إلى هذا إلا من قبل المصرح لهم بحقوق وصول خاصة إلى هذه الأنظمة، وهم مطالبون بالحفاظ على سرية المعلومات.
          بعد إجراء معاملة، لن يتم تخزين معلوماتك الخاصة (بطاقات الائتمان وأرقام الضمان الاجتماعي والبيانات المالية وما إلى ذلك) على خوادمنا.

          <p class="static__page--title">هل نستخدم ملفات تعريف الارتباط؟</p>
          ملف تعريف الارتباط هو ملف بيانات صغير يتم تخزينه بواسطة متصفح الويب الخاص بك على جهاز الكمبيوتر الخاص بك.
          نستخدم ملفات تعريف الارتباط لتتبع جلسة التسوق الحالية ، لتمكينك من متابعة عملية الدفع في أي وقت وإضفاء الطابع الشخصي على تجربتك عبر الإنترنت. إذا كنت لا تقبل ملفات تعريف الارتباط، فلن تتمكن من استخدام هذا الموقع.

          <p class="static__page--title">هل نفصح عن أي معلومات للأطراف الخارجية؟</p>
          نحن لا نبيع أو نتاجر أو ننقل معلومات التعريف الشخصية الخاصة بك إلى أطراف خارجية.
          لا يشمل هذا الجهات الخارجية الموثوقة التي تساعدنا في تشغيل موقعنا على الويب أو إدارة أعمالنا أو تقديم الخدمات لك ، طالما وافقت هذه الأطراف على الحفاظ على سرية هذه المعلومات.
          قد نكشف أيضًا عن معلوماتك عندما نعتقد أنها مناسبة. فمثلا؛ للامتثال للقانون، وفرض سياسات موقعنا، وحماية ممتلكاتنا وسلامتنا، أو لحماية حقوقنا وحقوق الآخرين. ومع ذلك، قد يتم تقديم معلومات الزائر غير الشخصية لأطراف أخرى للتسويق أو الإعلان أو استخدامات أخرى.

          <p class="static__page--title">خدمة الزبائن</p>
          يرجى الاتصال بفريق خدمة العملاء لدينا خلال ساعات العمل للحصول على مساعدة فورية:
          راسلنا على :
          Yasermall@live.com
          رقم الهاتف :009625813168

          ياسر مول واخوانه
          للتواصل مع ياسر مول وإخوانه، أرسل إلينا بريدًا إلكترونيًا أو اتصل بنا مباشرةً لأي استفسارات أو شكاوى أو اقتراحات بشأن أي من منتجاتنا أو خدماتنا:
          مباشر: +96265813168
          ص. ب: 144082 عمان11811
          عنوان البريد الإلكتروني: yasermall@live.com
          عنواننا:
          5، راضي عناب، الجندويل، عمان، الأردن
          موافقتك
          باستخدام موقعنا، فإنك توافق على سياسة الخصوصية لموقع الويب الخاص بنا.
          التغييرات في سياسة الخصوصية الخاصة بنا

          إذا قررنا تغيير سياسة الخصوصية الخاصة بنا، فسنقوم بنشر هذه التغييرات على هذه الصفحة.
          الاتصال بنا
          إذا كانت لديك أي أسئلة بخصوص سياسة الخصوصية هذه، فقم بالتواصل باستخدام المعلومات الواردة أعلاه.
          ياسر مول وفر أكثر!!
        </div>
      </div>
    </div>
  </div>
</div>
