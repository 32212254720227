import {Component, OnInit} from '@angular/core';
import {BrowserService} from '../../services/browser.service';
import {ProductService} from '../../services/product.service';
import {ActivatedRoute, Router} from '@angular/router';
import {CategoryService} from '../../services/category.service';
import {environment as env} from '../../../environments/environment';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-sub-category-page',
  templateUrl: './sub-category-page.component.html',
  styleUrls: ['./sub-category-page.component.scss']
})
export class SubCategoryPageComponent implements OnInit {
  public isMobile = this.browserService.isMobileDevice();
  public showFilter = false;
  public productsOfSubCategory: any;
  public sortOptions = [];
  public subCategoryList = [];
  public currentSelectedCategory;
  public priceArr = [];
  public currentSelectedSort = 'p.sort_order';
  public currentSelectedOrder = 'ASC';
  public isDeals = 'false';

  public showLoading = false;

  public customCategory = false;
  public customCategoryCurrentPage = 1;
  public sortHasChanged = false;

  constructor(public browserService: BrowserService, public productService: ProductService,
              private route: ActivatedRoute,
              private router: Router, private categoryService: CategoryService, private snackBar: MatSnackBar,
              public translateService: TranslateService) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  ngOnInit(): void {
    if (this.route.snapshot.queryParamMap.get('deals')) {
      this.isDeals = this.route.snapshot.queryParamMap.get('deals');
    }
    this.getProducts();
  }

  public sortByUserSelect(sortOption): void {
    this.currentSelectedSort = sortOption.value;
    this.currentSelectedOrder = sortOption.order;
    this.productsOfSubCategory.current_page = 1;
    this.sortHasChanged = true;
    this.customCategoryCurrentPage = 1;
    if (!this.customCategory) {
      this.productService.getProductsByCategory(this.currentSelectedCategory,
        env.listingImgSize, this.currentSelectedSort, this.currentSelectedOrder,
        '1', env.loadLimit, this.priceArr[0], this.priceArr[1], this.isDeals)
        .subscribe(
          (res) => {
            this.productsOfSubCategory.product_total = res.product_total;
            this.productsOfSubCategory.products = res.products;
          },
          (err) => {

          }
        );
    } else {
      this.getCustomCategory(this.currentSelectedCategory);
    }
  }

  public sortByPriceRange(): void {
    this.productsOfSubCategory.current_page = 1;
    this.customCategoryCurrentPage = 1;
    if (!this.customCategory) {
      this.productService.getProductsByCategory(this.currentSelectedCategory,
        env.listingImgSize, this.currentSelectedSort, this.currentSelectedOrder,
        '1', env.loadLimit, this.priceArr[0], this.priceArr[1], this.isDeals)
        .subscribe(
          (res) => {
            this.productsOfSubCategory.product_total = res.product_total;
            this.productsOfSubCategory.products = res.products;
          },
          (err) => {

          }
        );
    } else {
      this.getCustomCategory(this.currentSelectedCategory);
    }
  }

  public loadMoreProducts(): void {
    if (!this.customCategory) {
      if (this.productsOfSubCategory.current_page < this.productsOfSubCategory.no_pages) {
        this.showLoading = true;
        this.productsOfSubCategory.current_page++;
        this.productService.getProductsByCategory(this.currentSelectedCategory, env.listingImgSize, this.currentSelectedSort,
          this.currentSelectedOrder, this.productsOfSubCategory.current_page, env.loadLimit
          , this.priceArr[0], this.priceArr[1], this.isDeals)
          .subscribe(
            (res) => {
              this.showLoading = false;
              this.productsOfSubCategory.product_total = res.product_total;
              this.productsOfSubCategory.current_page = res.current_page;
              this.productsOfSubCategory.products.push(...res.products);
            },
            (err) => {
              this.showLoading = false;
            }
          );
      }
    } else {
      if (this.customCategoryCurrentPage < this.productsOfSubCategory.no_pages) {
        this.showLoading = true;
        this.customCategoryCurrentPage++;
        this.getCustomCategory(this.currentSelectedCategory);
      }
    }
  }

  private getProducts(categoryId?): void {
    if (categoryId) {
      this.currentSelectedCategory = categoryId;
      this.customCategory = false;
    } else {
      this.customCategory = !parseFloat(this.route.snapshot.paramMap.get('id')) && this.route.snapshot.paramMap.get('id') !== '0';
      this.currentSelectedCategory = this.route.snapshot.paramMap.get('id');
    }
    if (!this.priceArr[0] && !this.priceArr[1]) {
      this.priceArr[0] = 0;
      this.priceArr[1] = 1000;
    }
    if (!this.customCategory) {
      this.getSubCategories();
      this.productService.getProductsByCategory(this.currentSelectedCategory,
        env.listingImgSize, 'p.sort_order',
        this.currentSelectedOrder, 1, env.loadLimit, this.priceArr[0], this.priceArr[1], this.isDeals)
        .subscribe(
          (res) => {
            this.productsOfSubCategory = res;
            this.sortOptions = res.sorts;
          },
          (err) => {
            this.router.navigateByUrl(`/${localStorage.getItem('lang')}/home`);
            this.snackBar.open(err.error.error.message, this.translateService.instant('snack_bar_close'), {
              duration: 3500,
              panelClass: this.translateService.instant('dir')
            });
          }
        );
    } else {
      this.getCustomCategory(this.currentSelectedCategory);
    }
  }

  private getSubCategories(): void {
    this.categoryService.getSubCategories(this.route.snapshot.paramMap.get('id'), 100)
      .subscribe(
        (res) => {
          this.subCategoryList = res.categories;
        }, (err) => {
          this.snackBar.open(err.error.error.message, this.translateService.instant('snack_bar_close'), {
            duration: 3500,
            panelClass: this.translateService.instant('dir')
          });
        }
      );
  }

  public getCustomCategory(categoryName): void {
    switch (categoryName) {
      case 'featured': {
        this.productService.getFeaturedProductsViewAll(env.listingImgSize, this.currentSelectedSort,
          this.currentSelectedOrder, this.customCategoryCurrentPage, env.loadLimit
          , this.priceArr[0], this.priceArr[1], this.isDeals).subscribe(
          (res) => {
            if (this.customCategoryCurrentPage > 1) {
              this.productsOfSubCategory.products.push(...res.products);
            } else {
              this.productsOfSubCategory = res;
              if (!this.sortHasChanged) {
                this.sortOptions = res.sorts;
              }
            }
          },
          (err) => {
            this.snackBar.open(err.error.error.message, this.translateService.instant('snack_bar_close'), {
              duration: 3500,
              panelClass: this.translateService.instant('dir')
            });
          }
        );
        break;
      }
      case 'latest': {
        this.productService.getLatestProductsViewAll(env.listingImgSize, this.currentSelectedSort,
          this.currentSelectedOrder, this.customCategoryCurrentPage, env.loadLimit
          , this.priceArr[0], this.priceArr[1], this.isDeals).subscribe(
          (res) => {
            this.productsOfSubCategory = res;
            if (!this.sortHasChanged) {
              this.sortOptions = res.sorts;
            }
          },
          (err) => {
            this.snackBar.open(err.error.error.message, this.translateService.instant('snack_bar_close'), {
              duration: 3500,
              panelClass: this.translateService.instant('dir')
            });
          }
        );
        break;
      }
      case 'recent': {
        this.productService.recentlyViewedViewAll(env.listingImgSize, this.currentSelectedSort,
          this.currentSelectedOrder, this.customCategoryCurrentPage, env.loadLimit
          , this.priceArr[0], this.priceArr[1], this.isDeals).subscribe(
          (res) => {
            this.productsOfSubCategory = res;
            if (!this.sortHasChanged) {
              this.sortOptions = res.sorts;
            }
          },
          (err) => {
            this.snackBar.open(err.error.error.message, this.translateService.instant('snack_bar_close'), {
              duration: 3500,
              panelClass: this.translateService.instant('dir')
            });
          }
        );
        break;
      }
    }
  }
}
