<div class="page-min-height mobile_menu--padding fade-in container__max-width header-margin-top">
  <ng-container *ngIf="unavailableItems && !isMobile">
    <span class="title">{{'unavailable_item' | translate}}</span>
    <div *ngFor="let item of unavailableItems?.items; index as i">
      <ng-container *ngIf="selectedPage ? selectedPage === item.product_id : i==0">
        <div class="d-flex wrapper-row refund unavailable">
          <div class="img_with_desc">
            <img alt="{{ item.product?.name }}" src="{{ item.product.images[0] }}" width="100px" height="100px"/>

            <div class="desc">
              <div class="name">{{item.product.name}}</div>
              <div>{{item.product.sku}}</div>
              <div class="d-flex brand_with_code">
                <div>{{'barcode' | translate}}</div>
                <div>{{item.product.model}}</div>
              </div>
            </div>
          </div>

          <div>
            {{item.product.price}} {{'currency' | translate}}
          </div>
          <div>
            X{{item.quantity}}
          </div>
          <div class="total_price">{{item.amountToPay}} {{'currency' | translate}}</div>
          <div class="replace_button refund"
               (click)="refundUnavailableItem(item.order_id , item.order_product_id , item.product_id)">{{'refund_item' | translate}}
          </div>
        </div>

        <span class="title mt-5">{{'suggested_replacements' | translate}} ({{item.suggested_products.length}})</span>

        <div class="d-flex wrapper-row border-row" *ngFor="let suggest of item.suggested_products; index as i">

          <div class="img_with_desc">
            <img alt="{{ suggest?.name }}" src="{{ suggest.images[0] }}" width="100px" height="100px"/>

            <div class="desc">
              <div class="name">{{suggest.name}}</div>
              <div>{{suggest.sku}}</div>
              <div class="d-flex brand_with_code">
                <div>{{'barcode' | translate}}</div>
                <div>{{suggest.model}}</div>
              </div>
            </div>
          </div>

          <div>
            {{suggest.price}} {{'currency' | translate}}
          </div>
          <div>
            X1
          </div>
          <div class="total_price">{{suggest.price}} {{'currency' | translate}}</div>
          <div class="replace_button replace"
               (click)="onReplaceItem({item:item,suggest:suggest})">{{'replace_item' | translate}}</div>
        </div>
      </ng-container>
    </div>
  </ng-container>


  <!--------------------------------------------------------- Mobile view ---------------------------------------------------------->
  <ng-container *ngIf="unavailableItems && isMobile">

    <span class="title_mobile">{{'unavailable_item' | translate}}</span>
    <div class="mobile_container" *ngFor="let item of unavailableItems?.items; index as i">
      <ng-container *ngIf="selectedPage ? selectedPage === item.product_id : i==0">

        <div class="wrapper_refund_mobile">
          <!--1-->
          <div class="d-flex img_with_desc_mobile">
            <img alt="{{ item.product?.name }}" src="{{ item.product.images[0] }}" width="100px" height="100px"/>
            <div class="desc">
              <div class="name">{{item.product.name}}</div>
              <div>{{item.product.sku}}</div>
              <div class="d-flex brand_with_code">
                <div>{{'barcode' | translate}}</div>
                <div>{{item.product.model}}</div>
              </div>
            </div>
          </div>

          <!--2-->
          <div class="dec_lower_section_mobile">
       <span *ngIf="item.product.options[0]?.option_id == '16' && item.product.options[0]?.value.length">
         **{{item.product.options[0]?.value}}
       </span>

            <div class="row">
              <div
                class="col-6">{{'price' | translate}} {{item.product?.is_unit_piece ? '' : '/ ' + item.product.weight_unit}}</div>
              <div class="col-6 mb-2"
                   [ngClass]="getTextAlignmentClass()">{{item.product.price}} {{'currency' | translate}}</div>
              <div class="col-6">{{'qty' | translate}}</div>
              <div class="col-6 mb-2"
                   [ngClass]="getTextAlignmentClass()">{{item.quantity}} {{item.product?.is_unit_piece ? '' : item.product.weight_unit}}</div>
              <div class="col-6">{{'total_price' | translate}}</div>
              <div class="col-6"
                   [ngClass]="getTextAlignmentClass()">{{item.amountToPay}} {{'currency' | translate}}</div>
            </div>
            <div class="refund_button_mobile mt-2 text-center"
                 (click)="refundUnavailableItem(item.order_id , item.order_product_id , item.product_id)">{{'refund_item' | translate}}
            </div>
          </div>
        </div>
        <!--------------------------------------below suggested replacements------------------------------------------>
        <div class="d-flex justify-content-between">
          <div class="title_mobile">{{'suggested_replacements' | translate}}</div>
          <div class="title_mobile">{{item.suggested_products.length}} {{'items_r' | translate}}</div>
        </div>


        <div *ngFor="let suggest of item.suggested_products; index as i">
          <div class="d-flex img_with_desc_mobile">
            <img alt="{{ suggest?.name }}" src="{{ suggest.images[0] }}" width="100px" height="100px"/>
            <div class="desc">
              <div class="name">{{suggest.name}}</div>
              <div>{{suggest.sku}}</div>
              <div class="d-flex brand_with_code">
                <div>{{'barcode' | translate}}</div>
                <div>{{suggest.model}}</div>
              </div>
            </div>
          </div>

          <!--2-->
          <div class="dec_lower_section_mobile">
           <span *ngIf="suggest.options[0]?.option_id == '16' && suggest.options[0]?.value.length">
           **{{suggest.options[0]?.value}}
            </span>

            <div class="row">
              <div
                class="col-6">{{'price' | translate}} {{suggest?.is_unit_piece ? '' : '/ ' + suggest.weight_unit}}</div>
              <div class="col-6 mb-2"
                   [ngClass]="getTextAlignmentClass()">{{suggest.price}} {{'currency' | translate}}</div>
              <div class="col-6">{{'qty' | translate}}</div>
              <div class="col-6 mb-2" [ngClass]="getTextAlignmentClass()">
                1 {{suggest?.is_unit_piece ? '' : suggest.weight_unit}}</div>
              <div class="col-6">{{'total_price' | translate}}</div>
              <div class="col-6 mb-2"
                   [ngClass]="getTextAlignmentClass()">{{suggest.price}} {{'currency' | translate}}</div>
            </div>
            <div class="replace_button_mobile mt-2 text-center" (click)="onReplaceItem({item:item,suggest:suggest})">
              {{'replace_item' | translate}}
            </div>
          </div>
        </div>
      </ng-container>

    </div>
  </ng-container>
  <!---------------------------------------------------------------------------------------------------------------------------------->
  <div class="pagination mb-5 mt-5">
    <div *ngFor="let item of unavailableItems?.items ; index as i" class="page"
         [class.selected_page]="selectedPage ? selectedPage === item.product_id : i==0"
         (click)="onSelectedPage(item)">{{i + 1}}</div>
  </div>
</div>

<app-add-to-cart *ngIf="showAddToCartModal" (toggleModal)="showAddToCartModal = $event"
                 [productData]="suggestedAndItem.suggest" [orderProductData]="suggestedAndItem.item"
                 [isReplace]="true"></app-add-to-cart>
