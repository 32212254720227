<div class="order__summary--container">
  <div class="order__summary--wrapper" [class.enable]="isEnablePlaceOrder">
    <p class="order__summary--title">
      {{'order_summary' | translate }}
    </p>
    <div class="order__subtotal--container">
      <div class="order__flex--wrapper">
        <p class="order__subtotal--title">{{'subtotal' | translate }}</p>
        <p class="order__subtotal--amount">{{'currency' | translate}} {{cartDetails?.sub_total_amount | number}}</p>
      </div>

    <div class="delivery__charge--container" *ngIf="deliveryFee > -1 && !cartDetails?.coupon?.shipping">
      <p class="order__subtotal--title">{{'delivery_fee' | translate }}</p>
      <p class="order__subtotal--amount"
         [class.strike-through]="cartDetails?.coupon?.shipping">{{'currency' | translate}} {{deliveryFee | number}}</p>
    </div>

    <div class="delivery__charge--container" *ngIf="serviceFee > 0">
      <p class="order__subtotal--title">{{'service_fee' | translate }}</p>
      <p class="order__subtotal--amount">{{'currency' | translate}} {{serviceFee | number}}</p>
    </div>

    <div class="delivery__charge--container discount" *ngIf="calculateGrandTotalAndDiscount().discount > 0">
      <p class="order__subtotal--title">{{'discount' | translate }}</p>
      <p class="order__subtotal--amount">
        {{ 'currency' | translate }} {{
        getCurrentLang() === 'en'
          ? '-' + (calculateGrandTotalAndDiscount().discount | number)
          : (calculateGrandTotalAndDiscount().discount | number) + '-'
        }}
      </p>
    </div>

    <div class="order__grand--container">
      <p class="order__grand--title">{{'grand_total' | translate }}</p>
      <p class="order__grand--amount"
         *ngIf="cartDetails?.total_price">{{'currency' | translate}}
        {{calculateGrandTotalAndDiscount().grandTotal | number}}</p>
    </div>


    <button *ngIf="!isCheckout" class="order__checkout--btn" (click)="onContinueToCheckOut()"
            [class.disabled]="!cartDetails?.checkout_eligible">
      <a>{{'continue_to_checkout' | translate }}</a>
    </button>

    <button *ngIf="isCheckout" class="order__checkout--btn" id="checkout-btn" [class.disabled]="!isEnablePlaceOrder">
      {{'complete_checkout' | translate }}
    </button>

  </div>

  <a class="order__shopping--btn"
     [routerLink]="'/' + RouteEnum[getCurrentLang()].Home">{{'continue_shopping' | translate }}</a>
</div>

