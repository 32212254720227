import { Component, OnInit } from '@angular/core';
import {RouteEnum} from '../../enums/routes.enum';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  public RouteEnum = RouteEnum;
  constructor() { }

  ngOnInit(): void {
  }

  getCurrentLang(): string {
    return localStorage.getItem('lang') || 'ar';
  }

}
