import {Component, NgIterable, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ReplaceItemService} from '../../services/replaceItem.service';
import {Item, IUnavailableItems, Product} from '../../intefaces/IRplaceOrder';
import {BrowserService} from '../../services/browser.service';
import {ProductService} from '../../services/product.service';

@Component({
  selector: 'app-replace-order',
  templateUrl: './replace-order.component.html',
  styleUrls: ['./replace-order.component.scss']
})
export class ReplaceOrderPageComponent implements OnInit {
  public unavailableItems: (IUnavailableItems & NgIterable<IUnavailableItems>) | undefined | null;
  public selectedPage;
  public isMobile = this.browserService.isMobileDevice();
  public showAddToCartModal = false;
  public suggestedAndItem: { item: Item, suggest: Product };

  constructor(private route: ActivatedRoute, private replaceItemService: ReplaceItemService,
              public browserService: BrowserService, private productService: ProductService, private router: Router) {
  }

  ngOnInit(): void {
    this.route.url.subscribe(segments => {
      const orderId = segments[segments.length - 1].path;
      this.getUnavailableItems(orderId);
    });
  }

  public scrollToTop(): void {
    window.scrollTo({top: 0});
  }

  public getUnavailableItems(orderId): void {
    this.replaceItemService.getUnavailableItemsApi(orderId).subscribe(
      (res) => {
        this.unavailableItems = res;
        if (Number(res?.total === 0)) {
          // Redirect
          this.router.navigateByUrl(`/${localStorage.getItem('lang')}/my-account?activeTab=order`);
        }
      },
      (error) => {
        console.log('Error occurred:', error);
        // Redirect
        this.router.navigateByUrl(`/${localStorage.getItem('lang')}/my-account?activeTab=order`);
      }
    );
  }

  public refundUnavailableItem(orderId, orderProductId, productId): void {
    console.log({orderId, orderProductId, productId});
    this.replaceItemService.refundItem(orderId, orderProductId, productId).subscribe(res => {
      if (res) {
        window.location.reload();
      }
    });
  }


  public onReplaceItem(obj): void {
    this.productService.getProductById(obj.suggest.product_id).subscribe(res => {
      this.suggestedAndItem = {item: obj.item, suggest: res};
      this.showAddToCartModal = true;
    });
  }

  public onSelectedPage(item): void {
    this.scrollToTop();
    this.selectedPage = item.product_id;
  }

  getCurrentLang(): string {
    return localStorage.getItem('lang') || 'ar';
  }

  public getTextAlignmentClass(): string {
    return this.getCurrentLang() === 'ar' ? 'text-left' : 'text-right';
  }
}
