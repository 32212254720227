import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {UserService} from '../app/services/user.service';
import {catchError, map} from 'rxjs/operators';
import {BrowserService} from '../app/services/browser.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private userService: UserService,
              private router: Router,
              private browserService: BrowserService) {
  }

  canActivate(): boolean {
    if (!this.userService.userInfo) {
      this.browserService.navigateToLogin();
      return false;
    }
    return true;
  }
}


/**
 * Defined route can be accessed only by unauthorized users.
 */
@Injectable()
export class AuthUnauthorizedGuard implements CanActivate {

  constructor(
    public router: Router,
    public userService: UserService
  ) {
  }

  canActivate() {
    if (this.userService.userInfo) {
      this.router.navigateByUrl(`/${localStorage.getItem('lang')}/home`);
      return false;
    }
    return true;
  }
}
