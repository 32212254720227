<div class="page-min-height mobile_menu--padding fade-in container__max-width header-margin-top wrapper-payment">
  <div class="title mb-3">{{'payment_process' | translate}}</div>


  <app-modal *ngIf="showPaymentForm===true" (isModalClose)="showPaymentForm=$event">
    <div class="wrapper_payment_form">

      <h2>{{'use_english_lang' | translate}}</h2>

      <form [formGroup]="paymentForm" class="form-container payment_form" (ngSubmit)="checkValidationForms()">
        <div class="required-fields" [dir]="'ltr'">

          <div class="d-flex flex-column mat-field">
            <label class="d-flex label">{{'user_name' | translate}}</label>
            <mat-form-field appearance="outline" class="mat-field" style="text-align: left !important;">
              <input matInput formControlName="first_name" placeholder="{{'user_name' | translate}}">
            </mat-form-field>
          </div>

          <div class="d-flex flex-column mat-field">
            <label class="d-flex label">{{'sure_name' | translate}}</label>
            <mat-form-field appearance="outline" class="mat-field" style="text-align: left !important;">
              <input matInput formControlName="sur_name" placeholder="{{'sure_name' | translate}}">
            </mat-form-field>
          </div>

          <div class="d-flex flex-column mat-field">
            <label class="d-flex label">{{'email' | translate}}</label>
            <mat-form-field appearance="outline" class="mat-field" style="text-align: left !important;">
              <input matInput formControlName="email" type="email" placeholder="{{'valid_email' | translate}}">
              <mat-error *ngIf="!paymentForm.controls['email'].valid">{{'valid_email' | translate}}</mat-error>
            </mat-form-field>
          </div>

          <div class="d-flex flex-column  mat-field">
            <label class="d-flex label">{{'billing_address' | translate}}</label>
            <mat-form-field appearance="outline" class="mat-field" style="text-align: left !important;">
              <input matInput formControlName="billing_address" placeholder="{{'billing_address' | translate}}">
            </mat-form-field>
          </div>

        </div>
      </form>

      <button class="next-button"
              [class.disable]="isValidForm ? '' : 'disable'"
              (click)="onSubmitInfo()">
       {{'next' | translate}}
      </button>

    </div>
  </app-modal>

  <form [action]="redirectUrlPayment()" class="paymentWidgets" data-brands="VISA MASTER A MEX"
        [lang]="getCurrentLang()"></form>
</div>


