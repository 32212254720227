<div class="empty__page--container">
  <ng-lottie
    width="300px"
    height="200px"
    containerClass="moving-box another-class"
    [options]="options"
  ></ng-lottie>
  <p class="empty__page--title">
    {{'empty_page_title' | translate}} {{pageTitle}}
  </p>
  <button class="empty__page--btn" [routerLink]="'/home'">
    <a>
      {{'continue_shopping' | translate}}
    </a>
  </button>
</div>
