// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  api: {
    version: '',
    server: 'https://api.yasermall.xyz/index.php?route=api/wkrestapi/',
    folder: 'api'
  },
  loadLimit: 20,
  listingImgSize: 400,
  singleImgSize: 800,
  api_key_google_map: 'AIzaSyDou1x9Pjxc7Cfp6WQcG4rrxyyNkz6vkog',
  payment_url: 'https://eu-prod.oppwa.com/v1/paymentWidgets.js'
};
// https://api.yasermall.xyz/index.php?route=api/wkrestapi/
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
