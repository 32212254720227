import { Component, OnInit } from '@angular/core';
import {AnimationOptions} from 'ngx-lottie';

@Component({
  selector: 'app-load-more-indicator',
  templateUrl: './load-more-indicator.component.html',
  styleUrls: ['./load-more-indicator.component.scss']
})
export class LoadMoreIndicatorComponent implements OnInit {
  options: AnimationOptions = {
    path: '/assets/images/lottie/loading.json',
  };

  constructor() { }

  ngOnInit(): void {
  }

}
