import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-ad-banner',
  templateUrl: './ad-banner.component.html',
  styleUrls: ['./ad-banner.component.scss']
})
export class AdBannerComponent implements OnInit {
  @Input() advert: any;

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  public navigateToSlide(slideData): void {
    if (slideData.type === 'category') {
      this.router.navigateByUrl(`/${localStorage.getItem('lang')}/sub-category/` + slideData.link);
    }
    if (slideData.type === 'product') {
      this.router.navigateByUrl(`/${localStorage.getItem('lang')}/product/` + slideData.link);
    }
  }

}
