import {Component, EventEmitter, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-smart-banner',
  templateUrl: './smart-banner.component.html',
  styleUrls: ['./smart-banner.component.scss']
})
export class SmartBannerComponent implements OnInit {

  @Output() closeBannerOutput: EventEmitter<any> = new EventEmitter();
  constructor() { }

  ngOnInit(): void {
  }

  public closeBanner(): void {
    localStorage.setItem('smart-banner', 'true');
    this.closeBannerOutput.emit(true);
  }

}
