import {Component, OnInit} from '@angular/core';
import {BrowserService} from '../../services/browser.service';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-account-page',
  templateUrl: './account-page.component.html',
  styleUrls: ['./account-page.component.scss']
})
export class AccountPageComponent implements OnInit {

  public activeToggle = 'basic-info';

  constructor(public browserService: BrowserService, public route: ActivatedRoute) {
  }

  ngOnInit(): void {
    if (this.route.snapshot.queryParamMap.get('activeTab')) {
      this.activeToggle = this.route.snapshot.queryParamMap.get('activeTab');
    }
  }

  changeToggle(toggleName): void {
    this.activeToggle = toggleName;
  }

  public logOut(): void {
    this.browserService.logOut();
  }

}
