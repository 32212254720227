import {Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ProductService} from '../../services/product.service';
import {ActivatedRoute, Router} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';
import {environment} from '../../../environments/environment';
import {UserService} from '../../services/user.service';
import {BrowserService} from '../../services/browser.service';

@Component({
  selector: 'app-product-page',
  templateUrl: './product-page.component.html',
  styleUrls: ['./product-page.component.scss']
})
export class ProductPageComponent implements OnInit {

  public slideConfig;
  public currentPage = 1;
  public productData: any = null;
  public showAddToCartModal = false;
  public isSingleProduct = false;
  public searchedProductList;
  public searchedProductListArr = [];
  public showLoading = false;

  constructor(public translate: TranslateService, private productService: ProductService,
              private route: ActivatedRoute, private router: Router, private snackBar: MatSnackBar,
              public userService: UserService, private browserService: BrowserService) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    translate.get('carousel_rtl').subscribe((text: string) => {
      const carouselDir = text === 'true';
      this.slideConfig = {
        draggable: true,
        accessibility: true,
        variableWidth: false,
        arrows: false,
        adaptiveHeight: false,
        dots: true,
        swipeToSlide: true,
        infinite: true,
        rtl: carouselDir
      };
    });
  }

  ngOnInit(): void {
    this.checkIfSingleProduct();
  }

  public getProductDetails(id, width): void {
    this.productService.getProductById(id, width).subscribe(
      (res) => {
        this.productData = res;
        this.isSingleProduct = true;
      },
      (err) => {
        this.router.navigateByUrl(`/${localStorage.getItem('lang')}/home`);
        this.snackBar.open(err.error.error.message, this.translate.instant('snack_bar_close'), {
          duration: 3500,
          panelClass: this.translate.instant('dir')
        });
      }
    );
  }

  public checkIfProductHasOptions(): void {
    if (this.userService.userInfo) {
      this.showAddToCartModal = true;
    } else {
      this.browserService.navigateToLogin();
    }
  }

  public removeProductFromWishList(productId): void {
    this.productService.removeProductFromWishlist(productId).subscribe(
      (res) => {
        this.productData.wishlist_status = false;
        this.snackBar.open(res.message, this.translate.instant('snack_bar_close'), {
          duration: 3500,
          panelClass: this.translate.instant('dir')
        });
      },
      (err) => {
        this.snackBar.open(err.error.error.message, this.translate.instant('snack_bar_close'), {
          duration: 3500,
          panelClass: this.translate.instant('dir')
        });
      }
    );
  }

  public addProductToWishList(productId): void {
    if (this.userService.userInfo) {
      this.productService.addProductToWishlist(productId).subscribe(
        (res) => {
          this.productData.wishlist_status = true;
          this.snackBar.open(res.message, this.translate.instant('snack_bar_close'), {
            duration: 3500,
            panelClass: this.translate.instant('dir')
          });
        },
        (err) => {
          this.snackBar.open(err.error.error.message, this.translate.instant('snack_bar_close'), {
            duration: 3500,
            panelClass: this.translate.instant('dir')
          });
        }
      );
    } else {
      this.browserService.navigateToLogin();
    }
  }

  public searchForProductList(productName, pageNumber): void {
    this.showLoading = true;
    this.productService.searchForProduct(productName, environment.listingImgSize, pageNumber).subscribe(
      (res) => {
        this.showLoading = false;
        this.searchedProductList = res;
        this.searchedProductListArr.push(...res.products);
        this.isSingleProduct = false;
      }, (err) => {
        this.showLoading = false;
        this.router.navigateByUrl(`/${localStorage.getItem('lang')}/home`);
        this.snackBar.open(err.error.error.message, this.translate.instant('snack_bar_close'), {
          duration: 3500,
          panelClass: this.translate.instant('dir')
        });
      }
    );
  }


  public checkIfSingleProduct(): void {
    if (parseFloat(this.route.snapshot.paramMap.get('id'))) {
      this.getProductDetails(this.route.snapshot.paramMap.get('id'), environment.singleImgSize);
    } else {
      this.searchForProductList(this.route.snapshot.paramMap.get('id'), this.currentPage);
    }
  }

  public loadMoreProducts(): void {
    this.currentPage++;
    this.searchForProductList(this.route.snapshot.paramMap.get('id'), this.currentPage);
  }
}
