import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {TranslateService} from "@ngx-translate/core";
import {CheckoutService} from "../../services/checkout.service";
import {OrderStatusEnum} from "../../enums/order.enum";
import {UserService} from "../../services/user.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {environment} from "../../../environments/environment";


@Component({
  selector: 'app-payment',
  templateUrl: './payment-page.component.html',
  styleUrls: ['./payment-page.component.scss']
})
export class PaymentPageComponent implements OnInit {
  public paymentForm: FormGroup;
  public orderId;
  public isValidForm = false;
  public showPaymentForm = true;
  public userAddress;

  constructor(private route: ActivatedRoute, public translateService: TranslateService, private fb: FormBuilder,
              private checkoutService: CheckoutService, private userService: UserService,
              private snackBar: MatSnackBar) {
  }

  ngOnInit(): void {
    const params = this.route.snapshot.queryParams;
    this.orderId = params?.orderId || '';

    this.getOrderById();
    this.paymentForm = this.fb.group({
      first_name: ['', Validators.required],
      sur_name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      billing_address: ['', Validators.required],
    });
    this.checkValidationForms();
  }

  public getOrderById(): void {
    this.userService.getUserOrderById(this.orderId).subscribe(
      (res) => {
        this.userAddress = `${res?.shipping_address.street} ${res?.shipping_address.building_number} ${res?.shipping_address.floor_number}`;
        this.paymentForm.patchValue({
          billing_address: this.userAddress ? this.userAddress : '',
        });

      },
      (err) => {
        console.error(err);
      }
    );
  }

  public checkValidationForms(): void {
    this.paymentForm.statusChanges.subscribe(status => {
      if (status === 'VALID') {
        this.isValidForm = true;
      } else if (status === 'INVALID') {
        this.isValidForm = false;
      }
    });
  }

  public loadPaymentScript(checkoutId): void {
    const script = document.createElement('script');

    script.src = `${environment.payment_url}?checkoutId=${checkoutId}`;
    const storeTran = this.getCurrentLang() === 'ar' ? 'حفظ عملية الدفع' : 'Store payment details?';

    (window as any).wpwlOptions = {
      locale: this.getCurrentLang(),
      style: "card",
      onReady(): void {
        const createRegistrationHtml = `
        <div class="customLabel">${storeTran}</div>
        <div class="customInput"><input type="checkbox" name="createRegistration" value="true"/></div>
      `;
        const wpwlFormCard = document.querySelector('form.wpwl-form-card');
        const wpwlButton = wpwlFormCard.querySelector('.wpwl-button');
        wpwlButton.insertAdjacentHTML('beforebegin', createRegistrationHtml);
      }
    };

    document.head.appendChild(script);
  }

  public redirectUrlPayment(): string {
    return `/${localStorage.getItem('lang')}/my-account?activeTab=order&orderId=${this.orderId}`;
  }

  getCurrentLang(): string {
    return localStorage.getItem('lang') || 'ar';
  }

  public isEnglishInput(inputValue): boolean {
    // tslint:disable-next-line:forin
    for (const key in inputValue) {
      const englishRegex = /^[a-zA-Z0-9!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~\s]+$/;
      if (englishRegex.test(inputValue[key].trim()) === false) {
        return false;
      }
    }
    return true;
  }

  public onSubmitInfo(): void {

    if (!this.isValidForm) {
      return null;
    }

    if (this.isEnglishInput(this.paymentForm.value) === false) {
      this.snackBar.open(this.translateService.instant('english_lang_notify'), this.translateService.instant('snack_bar_close'), {
        duration: 5000,
        panelClass: this.translateService.instant('dir')
      });
      return;
    }

    const body = {
      userData: {
        'customer.email': this.paymentForm.value.email,
        'customer.givenName': this.paymentForm.value.first_name,
        'billing.street1': this.paymentForm.value.billing_address,
        'customer.surname': this.paymentForm.value.sur_name
      }
    }

    this.checkoutService.checkoutPayment(Number(this.orderId), body).subscribe((res) => {
      this.showPaymentForm = false;
      const checkoutId = res?.checkout_id;
      this.loadPaymentScript(checkoutId)
    }, (err) => {
      this.snackBar.open(err.error.error.message, this.translateService.instant('snack_bar_close'), {
        duration: 5000,
        panelClass: this.translateService.instant('dir')
      });
    });
  }
}
