import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class VisualService {

  constructor(private apiService: ApiService) {
  }

  public getHomePage(): Observable<any> {
    const url = 'common/getHomepage';
    return this.apiService.get(url);
  }

  public getHomePageSlides(width?, count?): Observable<any> {
    const url = `common/getHomepageBanners&width=${width}&count=${count}`;
    return this.apiService.get(url);
  }

  public getHomePageAds(width?, count?): Observable<any> {
    const url = `common/getHomepageAds&width=${width}&count=${count}`;
    return this.apiService.get(url);
  }
}
