<div class="category__listing--container">
  <div class="category__title-section">
    <p class="category__listing--title font-bold">
      {{'top_categories' | translate}}
    </p>
    <a class="view_all--btn" [routerLink]="'/' + RouteEnum[getCurrentLang()].Category">
      {{'view_all' | translate}}
    </a>
  </div>
  <div class="category__listing--slider" *ngIf="slideConfig">
    <ngx-slick-carousel [config]="slideConfig" dir="{{'html_dir' | translate}}">
      <div *ngFor="let category of categoryArr" ngxSlickItem class="slide" [routerLink]="['/', getCurrentLang(), 'sub-category' , category.path, category.name]">
        <app-single-category [categoryData]="category"></app-single-category>
      </div>
    </ngx-slick-carousel>
  </div>
</div>
