<div class="checkout__component--container">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-8">

        <div class="checkout__type--container">
          <!-------------------------CHOOSE Shipping METHODE------------------>
          <!--          <p class="checkout__component&#45;&#45;title">{{'shipping_method' | translate}}</p>-->
          <!--          <div class="delivery-and-payment-type-container" [ngClass]="!isMobile && 'd-flex'">-->

          <!--            <div class="delivery__type" [class.active]="deliveryType === 'zones.zones' || deliveryType === 'flat.flat'"-->
          <!--                 (click)="onChooseDeliveryZonesType()">-->

          <!--              <div class="d-flex cont-delivery-type">-->
          <!--                <img alt="img-disabled" class="delivery__type&#45;&#45;img-disabled" src="/assets/images/global/deliveryV2.svg">-->
          <!--                <img alt="img-active" class="delivery__type&#45;&#45;img-active"-->
          <!--                     src="/assets/images/global/delivery-activeV2.svg">-->

          <!--                <div>-->
          <!--                  <div class="title-delivery__type">{{'delivery' | translate}}</div>-->
          <!--                  <div class="desc-delivery__type">{{'orderDelivered' | translate}}</div>-->
          <!--                </div>-->
          <!--              </div>-->

          <!--              <div class="circle">-->
          <!--                <div class="circle-active"></div>-->
          <!--              </div>-->

          <!--            </div>-->

          <!--            <div class="delivery__type" [class.active]="deliveryType === 'pickup.pickup'"-->
          <!--                 (click)="onChooseDeliveryPickUpType()">-->
          <!--              <div class="d-flex cont-delivery-type">-->
          <!--                <img alt="img-disabled" class="delivery__type&#45;&#45;img-disabled" src="/assets/images/global/pickupV2.svg">-->
          <!--                <img alt="img-active" class="delivery__type&#45;&#45;img-active"-->
          <!--                     src="/assets/images/global/pickup-activeV2.svg">-->

          <!--                <div>-->
          <!--                  <div class="title-delivery__type">{{'pickup' | translate}}</div>-->
          <!--                  <div class="desc-delivery__type">{{'receiveFromMall' | translate}}</div>-->
          <!--                </div>-->
          <!--              </div>-->

          <!--              <div class="circle">-->
          <!--                <div class="circle-active"></div>-->
          <!--              </div>-->
          <!--            </div>-->

          <!--          </div>-->
          <!------------------------------------------------------------------>

          <!-------------------------CHOOSE SLOTS FOR PICKUP------------------>
          <!--          <div *ngIf="deliveryType === 'pickup.pickup'" class="pickup__time&#45;&#45;container fade-in">-->
          <!--            <p class="checkout__component&#45;&#45;title">{{'pickup_date_time' | translate}}</p>-->

          <!--            <span-->
          <!--              [class.selected_date_active]="selectedDeliverySlotId"-->
          <!--              class="selected_date">{{selectedDeliverySlotId ? selectedSelectedDeliveryDate +-->
          <!--              ' , ' + selectedTimeSlot : 'No Date and Time Selected'}}</span>-->

          <!--            <div class="cont_pick__slot bottom">-->
          <!--              <div class="pickup__slot" *ngFor="let slot of deliverySlots; let i = index"-->
          <!--                   (click)="selectedDeliverySlotIdx = i; selectedSelectedDeliveryDate = slot?.date; selectedDeliverySlotId=null; validateCheckOutFlow()"-->
          <!--                   [class.active]="selectedDeliverySlotIdx === i">-->

          <!--                <p>{{formatDate.formatDate(slot.date, getCurrentLang())}}</p>-->
          <!--              </div>-->

          <!--            </div>-->

          <!--            <div *ngIf="deliverySlots" class="cont_pick__slot">-->
          <!--              <div class="pickup__slot pickup__slot_time"-->
          <!--                   *ngFor="let slotTime of deliverySlots[selectedDeliverySlotIdx]?.slots"-->
          <!--                   (click)="selectedDeliverySlotId = slotTime?.solt_id ; selectedTimeSlot = slotTime?.title;validateCheckOutFlow()"-->
          <!--                   [class.active]="selectedDeliverySlotId === slotTime?.solt_id">-->
          <!--                <p>{{slotTime.title}}</p>-->
          <!--              </div>-->
          <!--            </div>-->
          <!--          </div>-->

        </div>

        <!-------------------------CHOOSE ADDRESS------------------>
        <div class="address__chosen--container"
             *ngIf="deliveryType === 'zones.zones' || deliveryType === 'flat.flat'"
             (click)="resetProprietiesBasedOnFlow('address');validateCheckOutFlow()">
          <app-address-container
            (selectedAddressOutput)="chosenAddressId = $event"
            (deliveryMethodsRes)="deliveryMethods = $event"
            [isCheckout]="true"></app-address-container>
        </div>

        <!-------------------------CHOOSE DELIVERY METHODS (EXPRESS , NORMAL , PRIME , PRIME NOW) ------------------>

        <ng-container *ngIf="deliveryMethods && chosenAddressId">
          <p class="checkout__component--title">{{'delivery_method' | translate}}</p>
          <div class="delivery-and-payment-type-container delivery__methods--container"
               [ngClass]="!isMobile && 'd-flex'">
            <div *ngFor="let method of deliveryMethods ; let i = index"
                 (click)="onChooseDeliveryMethod({id:i,method:method})"
                 class="delivery__methods" [class.active]="selectedDeliveryMethod?.id===i"
                 [class.disabled]="method?.isEnabled === false">
              <div>
                <div class="title-delivery__type">{{method.name}}</div>
                <div class="desc-delivery__type">{{method.notes}}</div>
              </div>
              <div class="circle">
                <div class="circle-active"></div>
              </div>
            </div>
          </div>
        </ng-container>

        <!-------------------------CHOOSE SLOTS FOR DELIVERY (FOR NORMAL AND PRIME)------------------>

        <div
          *ngIf="(deliveryType === 'zones.zones' || deliveryType === 'flat.flat') && selectedDeliveryMethod
          && (selectedDeliveryMethod?.method?.type !== 'express' && selectedDeliveryMethod?.method?.type !== 'prime_now' )"
          class="delivery__time--container fade-in">

          <p class="checkout__component--title">{{'pickup_date_time' | translate}}</p>
          <span
            [class.selected_date_active]="selectedDeliverySlotId"
            class="selected_date">{{selectedDeliverySlotId ? selectedSelectedDeliveryDate +
            ' , ' + selectedTimeSlot : 'No Date and Time Selected'}}</span>

          <div class="cont_pick__slot bottom">
            <div class="pickup__slot" *ngFor="let slot of deliverySlots; let i = index"
                 (click)="selectedDeliverySlotIdx = i; selectedSelectedDeliveryDate = slot.date ; selectedDeliverySlotId=null; resetProprietiesBasedOnFlow('timeslots');
                 validateCheckOutFlow()"
                 [class.active]="selectedDeliverySlotIdx === i">
              <p>{{formatDate.formatDate(slot.date, getCurrentLang())}}</p>
            </div>

          </div>

          <div *ngIf="deliverySlots" class="cont_pick__slot">
            <div class="pickup__slot pickup__slot_time"
                 *ngFor="let slotTime of deliverySlots[selectedDeliverySlotIdx]?.slots"
                 (click)="selectedDeliverySlotId = slotTime.solt_id ; selectedTimeSlot = slotTime.title; validateCheckOutFlow()"
                 [class.active]="selectedDeliverySlotId === slotTime?.solt_id">
              <p>{{slotTime.title}}</p>
            </div>
          </div>

        </div>


        <!-------------------------CHOOSE PAYMENT METHOD------------------>
        <div *ngIf="deliveryType !== 'pickup.pickup' && selectedDeliveryMethod "
             class="payment__type--container fade-in">
          <p class="checkout__component--title">{{'choose_payment_method' | translate}}</p>

          <div class="delivery-and-payment-type-container" [ngClass]="!isMobile && 'd-flex'">
            <div *ngFor="let method of selectedDeliveryMethod?.method?.payment_methods ; let i = index"
                 class="delivery__methods"
                 [class.active]="selectedPaymentMethod?.id === i"
                 (click)="selectedPaymentMethod={id:i,method:method}; resetProprietiesBasedOnFlow('paymentMethod'); validateCheckOutFlow()">
              <div class="d-flex align-items-center cont-delivery-type">
                <div class="title-delivery__type">{{method.name}}

                  <div *ngIf="method.id == 'vep'" class="subtract_payment">  {{method.id == 'vep' && isSubTotal ?
                    ('subtract_payment_msg' | translate).replace('SUBTOTAL', cartItems?.sub_total_amount) : ''}}</div>

                  <div *ngIf=" method.id == 'vod' && method.cards.length > 0 "
                       class="subtract_payment">  {{translateCardOnDeliveryNote()}}</div>

                </div>
              </div>

              <div class="circle">
                <div class="circle-active"></div>
              </div>
            </div>
          </div>

        </div>
        <!--------------------------------- Payment by --------------------------------------->

        <div *ngIf="selectedPaymentMethod?.method.cards.length > 0"
             class="payment__type--container fade-in">
          <p class="checkout__component--title">{{'payment_by' | translate}}</p>

          <div class="delivery-and-payment-type-container" [ngClass]="!isMobile && 'd-flex'">
            <div *ngFor="let card of selectedPaymentMethod?.method.cards ; let i = index"
                 class="delivery__methods"
                 [class.active]="selectedPaymentCardType?.id === i"
                 (click)="selectedPaymentCardType={id:i,card:card} ; validateCheckOutFlow()">

              <div class="d-flex align-items-center cont-delivery-type">
                <img alt="img-disabled" class="delivery__type--img-disabled" width="35" height="35"
                     [src]="card.icon_url">
                <div class="title-delivery__type">{{card.name}}</div>
              </div>

              <div class="circle">
                <div class="circle-active"></div>
              </div>
            </div>
          </div>

        </div>


        <!-----------------------Coupon---------------------------->
        <div *ngIf="isShowCoupon()" class="coupon">
          <p class="checkout__component--title">{{'have_coupon' | translate}}</p>
          <div class="d-flex gap-10">
            <div class="couponInput">
              <input type="text" [disabled]="isAppliedCoupon"
                     [(ngModel)]="isAppliedCoupon? cartItems?.coupon?.code :selectedCoupon"
                     (ngModelChange)="onChangeCoupon($event)"/>
            </div>
            <button *ngIf="!isAppliedCoupon" [class.active]="selectedCoupon?.length > 0" class="applyCoupon px-4"
                    (click)="onApplyCoupon()">{{'apply_coupon' | translate}}</button>
            <button *ngIf="isAppliedCoupon === true" class="px-4 removeCoupon"
                    (click)="onRemoveCoupon()">{{'remove_coupon' | translate}}</button>
          </div>
          <span *ngIf="isAppliedCoupon === true">{{translateAppliedMethod()}}</span>
        </div>
        <!-------------------------ADD NOTE------------------>
        <div class="checkout__notes--container">
          <p class="checkout__component--title">{{'notes' | translate}}</p>
          <textarea class="checkout__notes--text" [(ngModel)]="notes"></textarea>
        </div>

      </div>


      <!-------------------------SUBMIT ORDER BOX------------------>
      <div class="col-md-4 submit_order" (click)="placeOrder($event)">
        <app-order-summary [isCheckout]="true" [checkOutObj]="checkoutObj" [cartDetails]="cartItems"
                           [isEnablePlaceOrder]="isEnablePlaceOrder"
                           [deliveryFee]="deliveryFee" [serviceFee]="serviceFee"></app-order-summary>
      </div>

      <!-------------------------------CONFIRM PAYMENT------------------------------------------------>
      <app-modal *ngIf="openConfirmPayment" (isModalClose)="openConfirmPayment=$event">
        <div class="wrapper-payment-confirmation d-flex gap-15">
          <div class="title_confirm">{{'payment_confirmation' | translate}}</div>
          <div
            class="dec_confirm">{{paymentConfirmationDesc()}}</div>
          <div class="d-flex cont_btns">
            <div class="cancel_btn" (click)="openConfirmPayment=false">{{'cancel' | translate}}</div>
            <div class="confirm_payment_btn" (click)="placeOrderApi()">{{'confirm_payment' | translate}}</div>
          </div>
        </div>
      </app-modal>
      <!-------------------------------APPLY COUPON MODAL------------------------------------------------>
      <app-modal *ngIf="openModalApplyCoupon" (isModalClose)="openModalApplyCoupon=$event">
        <div class="coupon-modal d-flex align-items-center flex-column justify-content-between gap-15">
          <div class="title-remove-coupon">{{'title_removal_coupon' | translate}}</div>
          <p>{{'desc_removal_coupon' | translate}}</p>
          <div class="d-flex gap-10">
            <button (click)="onRemoveCoupon()" class="confirm_payment_btn">{{'confirm' | translate}}</button>
            <button (click)="openModalApplyCoupon=false;callBackModalCoupon=null"
                    class="cancel_btn">{{'cancel' | translate}}</button>
          </div>
        </div>
      </app-modal>

    </div>
  </div>
</div>
