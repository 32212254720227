import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-single-category',
  templateUrl: './single-category.component.html',
  styleUrls: ['./single-category.component.scss']
})
export class SingleCategoryComponent implements OnInit {
  @Input() categoryData: any;
  constructor() { }

  ngOnInit(): void {
  }

}
