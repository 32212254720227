import {EventEmitter, Injectable, Output} from '@angular/core';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class BrowserService {
  @Output() isLoading: EventEmitter<boolean> = new EventEmitter();

  constructor(public router: Router) {
  }

  public isMobileDevice(): boolean {
    return (window.innerWidth < 700);
  }

  public isAndroid(): boolean {
    const ua = navigator.userAgent.toLowerCase();
    return ua.indexOf('android') > -1;
  }

  public expandList(element, chev?): void {
    if (element.classList.contains('hidden')) {
      element.classList.remove('hidden');
      element.classList.add('active');
      chev.classList.remove('rotate-180');
      chev.classList.add('rotate-0');
    } else {
      element.classList.remove('active');
      element.classList.add('hidden');
      chev.classList.remove('rotate-0');
      chev.classList.add('rotate-180');
    }
  }

  public navigateToLogin(): void {
    this.router.navigate([], {
      queryParams: {
        showLogin: 'true'
      },
      queryParamsHandling: 'merge',
    });
  }

  public logOut(): void {
    localStorage.removeItem('wk_token');
    this.router.navigateByUrl(`/${localStorage.getItem('lang')}/home`);
    location.reload();
  }

  public setLoading(loading: boolean): void {
    this.isLoading.emit(loading);
  }
}
