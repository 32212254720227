import {Component, OnInit} from '@angular/core';
import {CartService} from '../../services/cart.service';
import {TranslateService} from '@ngx-translate/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-cart-page',
  templateUrl: './cart-page.component.html',
  styleUrls: ['./cart-page.component.scss']
})
export class CartPageComponent implements OnInit {

  public cartObj: any;

  constructor(private cartService: CartService, public translateService: TranslateService, public snackBar: MatSnackBar) {
  }

  ngOnInit(): void {
    this.getCart();
  }

  public getCart(): void {
    this.cartService.getCartItems(environment.listingImgSize).subscribe(
      (res) => {
        this.cartObj = res;
        if (this.cartObj?.checkout_eligible === false) {
          this.snackBar.open(this.cartObj?.error_warning, this.translateService.instant('snack_bar_close'), {
            duration: 3500,
            panelClass: this.translateService.instant('dir')
          });
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

}
