<div class="addtocart__wrapper">
  <div class="scale-in-center">
    <div class="addtocart__container">
      <div class="addtocart__header">
        <p class="addtocart__title">{{productData?.name}}</p>
        <img class="addtocart__close" src="/assets/images/global/close.svg" (click)="toggleModal.emit(false)">
      </div>
      <div class="addtocart__body">
        <div *ngIf="!productData?.weight.isQuantity" class="addtocart__quantity--container">
          <p class="quantity__title">{{'weight' | translate}} ({{productData?.weight.unit}})</p>
          <button class="quantity__minus--btn" [disabled]="weightValue <= productData?.weight.min"
                  (click)="decreaseWeight(productData?.weight.step)">
            <i class="fa fa-minus-circle quantity__minus" aria-hidden="true"></i>
          </button>
          <p class="quantity__number">{{weightValue}}</p>
          <button class="quantity__plus--btn" (click)="addWeight(productData?.weight.step)">
            <i class="fa fa-plus-circle quantity__plus" aria-hidden="true"></i>
          </button>
        </div>
        <div *ngIf="productData?.weight.isQuantity" class="addtocart__quantity--container">
          <p class="quantity__title">{{'quantity' | translate}}</p>
          <button class="quantity__minus--btn" [disabled]="weightValue <= productData?.weight.min"
                  (click)="decreaseWeight(productData?.weight.step)">
            <i class="fa fa-minus-circle quantity__minus" aria-hidden="true"></i>
          </button>
          <p class="quantity__number">{{weightValue}}</p>
          <button class="quantity__plus--btn" (click)="addWeight(productData?.weight.step)">
            <i class="fa fa-plus-circle quantity__plus" aria-hidden="true"></i>
          </button>
        </div>
        <div *ngFor="let option of productData?.options">
          <div [ngSwitch]="option?.option_id">
            <div *ngSwitchCase="17" class="chicken__weight--container">
              <label>{{'choose_chicken_weight' | translate}}</label>
              <select class="custom__chicken--select"
                      (ngModelChange)="chooseChickenWeight($event, option?.product_option_id)"
                      [(ngModel)]="selectedChickenObj">
                <option [ngValue]="weightType" *ngFor="let weightType of option?.product_option_value">
                  {{weightType?.name}} G
                </option>
              </select>
            </div>
            <div *ngSwitchCase="18" class="add__spices--container">
              <section class="checkbox-section">
                <mat-checkbox class="options__checkbox" [(ngModel)]="serviceChecked"
                              (ngModelChange)="addCheckboxService(option?.product_option_value[0].price, option?.product_option_id, option?.product_option_value[0].product_option_value_id)">
                  {{'add' | translate}} {{option?.product_option_value[0].name}}
                  *{{option?.product_option_value[0].price}} {{'currency' | translate}} {{'extra_charge' | translate}}</mat-checkbox>
              </section>
            </div>
            <div *ngSwitchCase="19" class="addtocart__quantity--container service">
              <p class="quantity__title">{{'add_service' | translate}}</p>
              <span
                class="service__note">*{{cuttingServiceSelectedPrice}} {{'currency' | translate}} {{'extra_charge' | translate}}</span>
              <div class="text-center">
                <div class="service__type"
                     *ngFor="let service of option?.product_option_value"
                     (click)="addCuttingOrRemovingService(service.name, service?.price, service?.product_option_value_id, option?.product_option_id)"
                     [class.active]="cuttingServiceSelected === service.name"
                ><p>{{service.name}}
                </p></div>
              </div>
            </div>
            <div *ngSwitchCase="21" class="addtocart__quantity--container service">
              <p class="quantity__title">{{'add_service'| translate}}</p>
              <span
                class="service__note">*{{cuttingServiceSelectedPrice}} {{'currency' | translate}} {{'extra_charge' | translate}}</span>
              <div class="text-center">
                <div class="service__type beef"
                     *ngFor="let service of option?.product_option_value"
                     (click)="addCuttingOrRemovingService(service.name, service?.price, service?.product_option_value_id, option?.product_option_id)"
                     [class.active]="cuttingServiceSelected === service.name"
                ><p>{{service.name}}
                </p></div>
              </div>
            </div>
            <div *ngSwitchCase="16" class="addtocart__notes--container"
                 (click)="notesOptionKey = option?.product_option_id">
              <p class="addtocart__notes--title">{{'notes' | translate}}</p>
              <textarea class="addtocart__notes--text" (keyup)="test()"
                        placeholder="{{'notes_placeholder' | translate}}" [(ngModel)]="customNotes"></textarea>
            </div>
          </div>
        </div>
      </div>
      <div class="addtocart__footer--container">
        <p class="addtocart__price--title fade-in" *ngIf="priceAfterChange">{{'total_price' | translate}}</p>
        <p class="addtocart__price--number fade-in"
           *ngIf="priceAfterChange">{{priceAfterChange | number}} {{'currency' | translate}}</p>
        <button class="addtocart__btn" [class.replace]="isReplace"
                (click)="isReplace ? onReplaceProduct() : addProductToCart() ">{{(isReplace ? 'replace' : 'add_to_cart') | translate}}</button>
      </div>
    </div>
  </div>
</div>
