import {Component, ElementRef, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {AnimationOptions} from 'ngx-lottie';
import {BrowserService} from '../../services/browser.service';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnInit, OnDestroy {
  options: AnimationOptions = {
    path: '/assets/images/lottie/cart-loading.json',
  };

  public showLoading = false;

  constructor(private renderer: Renderer2, public browserService: BrowserService, public elementRef: ElementRef) {
    this.browserService.isLoading.subscribe(
      (change) => {
        if (change) {
          this.showLoading = change;
        } else {
          this.ngOnDestroy();
        }
      }
    );
  }

  ngOnInit(): void {
    this.renderer.addClass(document.body, 'modal-open');
  }

  ngOnDestroy(): void {
    this.renderer.removeClass(document.body, 'modal-open');
    this.showLoading = false;
  }

}
