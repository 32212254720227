<div class="product__listing--container">
  <div class="category__title-section">
    <p class="product__listing--title font-bold">
      {{title}}
    </p>
    <a *ngIf="showAll" class="view_all--btn" [routerLink]="'/' + getCurrentLang() +'/sub-category/' + link + '/' + title.toString().replaceAll(' ','-')" [queryParams]="{deals: deals}">
      {{'view_all' | translate}}
    </a>
  </div>
  <div class="product__listing--slider" *ngIf="slideConfig">
    <ngx-slick-carousel [config]="slideConfig" dir="{{'html_dir' | translate}}">
      <div ngxSlickItem class="slide" *ngFor="let product of productArr; index as i">
        <app-single-product [productData]="product"></app-single-product>
      </div>
    </ngx-slick-carousel>
  </div>
</div>
