<div class="single__product--container">
  <div class="discount__banner" *ngIf="productData?.discount_rate">
    {{productData?.discount_rate}}%
  </div>
  <img class="empty__heart" *ngIf="productData.wishlist_status === false"
       (click)="addProductToWishList(productData?.product_id)" src="/assets/images/global/empty-heart.svg">
  <img class="empty__heart" *ngIf="productData.wishlist_status === true"
       (click)="removeProductFromWishList(productData?.product_id)" src="/assets/images/global/heart-red.svg">
  <img onError="this.src='/assets/images/global/no-img.png'" class="product__image" src="{{productData?.thumb}}" [routerLink]="['/' + getCurrentLang() + '/product',productData.product_id, productData.name.replaceAll(' ','-')]">
  <p class="product__name" [routerLink]="['/' + getCurrentLang() + '/product',productData.product_id + '/' + productData.name.replaceAll(' ','-')]">
    {{productData.name}}
  </p>
  <div class="product__price--container" [routerLink]="['/' + getCurrentLang() + '/product',productData.product_id + '/' + productData.name.replaceAll(' ','-')]">
    <span class="product__price" *ngIf="!productData?.special">{{productData.price}} {{'currency' | translate}} </span><span
    *ngIf="!productData?.special" class="product__unit">/ <span *ngIf="!productData?.weight.isQuantity">{{productData?.weight?.unit}}</span><span *ngIf="productData?.weight.isQuantity">{{'piece' | translate}}</span></span>
    <span class="product__price" *ngIf="productData?.special"
          [class.hot-deal-price]="productData?.special">{{productData?.special}} {{'currency' | translate}} </span><span
    *ngIf="productData?.special" class="product__unit">/ <span *ngIf="!productData?.weight.isQuantity">{{productData?.weight?.unit}}</span><span *ngIf="productData?.weight.isQuantity">{{'piece' | translate}}</span></span>
  </div>
  <div *ngIf="productData?.weight.isQuantity" class="addtocart__quantity--container">
    <button class="quantity__minus--btn" [disabled]="quantityValue <= productData?.weight.min"
            (click)="decreaseQuantity(productData?.weight.step)">
      <i class="fa fa-minus-circle quantity__minus" aria-hidden="true"></i>
    </button>
    <p class="quantity__number">{{quantityValue}}</p>
    <button class="quantity__plus--btn" (click)="addQuantity(productData?.weight.step)">
      <i class="fa fa-plus-circle quantity__plus" aria-hidden="true"></i>
    </button>
  </div>
  <div *ngIf="!productData?.weight.isQuantity" class="addtocart__quantity--container">
    <p class="product__view--details" [routerLink]="['/' + getCurrentLang() + '/product',productData.product_id + '/' + productData.name.replaceAll(' ','-')]">
      {{'view_details' | translate}}
    </p>
  </div>
  <button class="add__cart--btn" (click)="checkIfProductHasOptions()"
          [disabled]="!productData?.stock" [class.add__cart--disabled]="!productData?.stock">
    <span *ngIf="productData?.stock">{{'add_to_cart' | translate}}</span>
    <span *ngIf="!productData?.stock">{{'out_of_stock' | translate}}</span>
  </button>
</div>
