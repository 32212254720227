<ng-container *ngIf="orderObj">
  <div class="order__status--container custom-header-margin-top" [ngClass]="'order-' + orderObj?.status_id">
    <div class="container__max-width container_order_details">
      <p class="order__status--title p">
        {{orderObj?.status}}
      </p>
      <p class="order__status--amount p">
        {{'order_details_total' | translate}} {{orderObj?.total_price}}
      </p>
    </div>
  </div>
  <div class="order__page--container fade-in container__max-width page-min-height mobile_menu--padding">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-8">
          <app-cart-listing *ngIf="orderObj" [isDelivered]="isDelivered" [isOrderDetails]="true"
                            (reorder)="onReOrder($event)"
                            [cartItems]="orderObj?.items"></app-cart-listing>
          <div class="replacement_items_title" *ngIf="isReplaced">{{'replacement_items' | translate}}</div>

          <app-cart-listing *ngIf="orderObj && isReplaced" [isReplaced]="isReplaced" [isDelivered]="isDelivered"
                            [isOrderDetails]="true"
                            (reorder)="onReOrder($event)"
                            [cartItems]="replacedItems"></app-cart-listing>
        </div>
        <div class="col-md-4">

          <div class="order__summary--container" [ngClass]="'order-' + orderObj?.status_id">

            <div class="order__summary upper">
              <div class="order__summary--key p">
                {{'order_details_number' | translate}}
              </div>
              <div class="order__summary--value p">
                #{{orderId}}
              </div>
            </div>

            <div class="order__summary">
              <div class="order__summary--key p">
                {{'order_details_date' | translate}}
              </div>
              <div class="order__summary--value p">
                {{orderObj?.date_added}}
              </div>
            </div>


            <div class="order__summary">
              <div class="order__summary--key p">
                {{'delivery_fee' | translate}}
              </div>
              <div class="order__summary--value p">
                {{orderObj?.is_free_delivery_fees ? (getCurrentLang() === "en" ? '-' + orderObj?.deliveryFees : orderObj?.deliveryFees + '-') : orderObj?.deliveryFees }}   {{'currency' | translate}}
              </div>
            </div>


            <div class="order__summary" *ngIf="orderObj?.serviceFees > 0" (click)="setupPaymentStatusPaid()">
              <div class="order__summary--key p">
                {{'service_fee' | translate}}
              </div>
              <div class="order__summary--value p">
                {{orderObj?.is_free_service_fees ? (getCurrentLang() === "en" ? '-' + orderObj?.serviceFees : orderObj?.serviceFees + '-') : orderObj?.serviceFees }}   {{'currency' | translate}}
              </div>
            </div>


            <div class="order__summary" *ngIf="orderObj?.payment_code === 'vep'">
              <div class="order__summary--key p">
                {{ 'payment_status' | translate}}
              </div>
              <div *ngIf="checkPaymentStatus(orderObj).bool === false" class="order__summary--value p">
                {{ checkPaymentStatus(orderObj).status  | translate }}
              </div>
              <div *ngIf="checkPaymentStatus(orderObj).bool === true" class="order__summary--value p">
                {{  'paid'   | translate }}
              </div>
            </div>

            <div *ngIf="setupPaymentStatusPaid().bool && orderObj?.payment_code === 'vep' && checkPaymentStatus(orderObj).bool" class="txt_payment">
              {{setupPaymentStatusPaid().msg}}</div>

            <div *ngIf="setupCardOnDeliveryStatus().bool && orderObj?.payment_code === 'vod'" class="txt_payment">
              {{setupCardOnDeliveryStatus().msg}}</div>

          </div>

          <div *ngIf="orderObj?.payment_code === 'vep' && checkPaymentStatus(orderObj).bool === false"
               class="retry_payment"
               (click)="prepareCheckoutPayment(orderObj?.order_id)">{{'retry_payment' | translate}}</div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<app-add-to-cart *ngIf="product"
                 [productData]="product" (toggleModal)="product = $event"></app-add-to-cart>
