import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ProductService} from '../../services/product.service';
import {Router} from '@angular/router';
import {BrowserService} from '../../services/browser.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';
import {HeaderComponent} from '../header/header.component';
import {AppComponent} from '../../app.component';

@Component({
  selector: 'app-single-product',
  templateUrl: './single-product.component.html',
  styleUrls: ['./single-product.component.scss']
})
export class SingleProductComponent implements OnInit {

  public localIsFavorite = false;
  public showAddToCartModal = false;
  public quantityValue;
  @Input() productData: any;

  @Output() changeWishList: EventEmitter<any> = new EventEmitter();

  constructor(public productService: ProductService, private router: Router,
              public browserService: BrowserService, private snackBar: MatSnackBar,
              public translateService: TranslateService, public app: AppComponent) {
  }

  ngOnInit(): void {
    this.localIsFavorite = this.productData.wishlist_status;
    this.quantityValue = this.productData?.weight.min;
  }

  public addProductToWishList(productId): void {
    this.productService.addProductToWishlist(productId).subscribe(
      (res) => {
        this.productData.wishlist_status = true;
        this.snackBar.open(res.message, this.translateService.instant('snack_bar_close'), {
          duration: 3500,
          panelClass: this.translateService.instant('dir')
        });
      },
      (err) => {
        this.browserService.navigateToLogin();
      }
    );
  }

  public checkIfProductHasOptions(): void {
    this.showAddToCartModal = true;
    if (this.productData.hasOption || !this.productData?.weight.isQuantity) {
      this.router.navigate([`/${localStorage.getItem('lang')}/product/` + this.productData.product_id + '/' + this.productData.name]);
    } else {
      const productObj = {
        product_id: this.productData.product_id,
        quantity: this.quantityValue
      };
      this.productService.addProductToCart(productObj).subscribe(
        (res) => {
          this.app.getCart();
          this.snackBar.open(res.message, this.translateService.instant('snack_bar_close'), {
            duration: 3500,
            panelClass: this.translateService.instant('dir')
          });
        },
        (err) => {
          this.browserService.navigateToLogin();
        }
      );
    }
  }

  public removeProductFromWishList(productId): void {
    this.productService.removeProductFromWishlist(productId).subscribe(
      (res) => {
        this.changeWishList.emit(true);
        this.productData.wishlist_status = false;
        this.snackBar.open(res.message, this.translateService.instant('snack_bar_close'), {
          duration: 3500,
          panelClass: this.translateService.instant('dir')
        });
      },
      (err) => {
        this.browserService.navigateToLogin();
      }
    );
  }

  public addQuantity(step): void {
    this.quantityValue = this.quantityValue + step;
  }

  public decreaseQuantity(step): void {
    this.quantityValue = this.quantityValue - step;
  }

  getCurrentLang(): string {
    return localStorage.getItem('lang') || 'ar';
  }

}
