<div class="basic__info--container fade-in">
  <p class="basic__info--title">
    {{'basic_info' | translate}}
  </p>
  <p class="basic__info--desc">
    {{'basic_info_text' | translate}}
  </p>

  <div class="info__inputs--container">
    <form [formGroup]="infoForm">

      <div class="info__single--input">
        <mat-form-field appearance="legacy">
          <mat-label>{{'first_name' | translate}}</mat-label>
          <input formControlName="userFirstName" matInput>
          <mat-error *ngIf="!infoForm.controls['userEmail'].valid">
            {{'first_name_error' | translate}}
          </mat-error>
        </mat-form-field>
      </div>

      <div class="info__single--input">
        <mat-form-field appearance="legacy">
          <mat-label>{{'last_name' | translate}}</mat-label>
          <input formControlName="userLastName" matInput>
          <mat-error *ngIf="!infoForm.controls['userEmail'].valid">
            {{'last_name_error' | translate}}
          </mat-error>
        </mat-form-field>
      </div>

      <div class="info__single--input">
        <mat-form-field class="example-full-width">
          <mat-label>{{'email' | translate}}</mat-label>
          <input type="email" matInput formControlName="userEmail" placeholder="Ex. pat@example.com">
          <mat-error *ngIf="!infoForm.controls['userEmail'].valid">
            {{'email_error' | translate}}
          </mat-error>
        </mat-form-field>
      </div>

      <div class="info__single--input">
        <mat-form-field class="example-full-width">
          <mat-label>{{'telephone' | translate}}</mat-label>
          <span matSuffix><i class="fa fa-mobile" aria-hidden="true"></i> &nbsp;</span>
          <input readonly formControlName="phoneNumber" type="tel" matInput placeholder="07x-xxxxxxx">
          <mat-error *ngIf="!infoForm.controls['userEmail'].valid">
            {{'telephone_error' | translate}}
          </mat-error>
        </mat-form-field>
      </div>



      <div class="info__single--input">
        <mat-form-field appearance="legacy">
          <mat-label>{{'choose_date' | translate}}</mat-label>
          <input (dateChange)="dateChange = true" [min]="minDate" [max]="maxDate" matInput formControlName="dateOfBirth" [matDatepicker]="picker">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
          <mat-error *ngIf="!infoForm.controls['dateOfBirth'].valid">
            {{'choose_date_error' | translate}}
          </mat-error>
        </mat-form-field>
      </div>

      <button class="info__save--btn" (click)="submitUserInfo()" [disabled]="!infoForm.valid">{{'save' | translate}}</button>
    </form>
  </div>
</div>
