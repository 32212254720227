/**
 * Route enums
 */
export const RouteEnum = {
  ar: {
    Empty: '',
    Home: 'ar/home',
    Login: 'ar/login',
    Category: 'ar/categories',
    SubCategory: 'ar/sub-category/:id/:name',
    Product: 'ar/product/:id',
    ReplaceOrder: 'ar/replace/order/:id',
    ProductWithName: 'ar/product/:id/:name',
    Cart: 'ar/cart',
    Contact: 'ar/contact-us',
    Contact_mobile: 'ar/contact-us-mobile',
    Checkout: 'ar/checkout',
    Wishlist: 'ar/wishlist',
    Account: 'ar/my-account',
    Order: 'ar/order/:id',
    Privacy: 'ar/privacy-policy',
    Privacy_mobile: 'ar/privacy-policy-mobile',
    Terms: 'ar/terms-of-use',
    Terms_mobile: 'ar/terms-of-use-mobile',
    Payment: 'ar/checkout/payment'
  },
  en: {
    Empty: '',
    Home: 'en/home',
    Login: 'en/login',
    Category: 'en/categories',
    SubCategory: 'en/sub-category/:id/:name',
    Product: 'en/product/:id',
    ReplaceOrder: 'en/replace/order/:id',
    ProductWithName: 'en/product/:id/:name',
    Cart: 'en/cart',
    Contact: 'en/contact-us',
    Contact_mobile: 'en/contact-us-mobile',
    Checkout: 'en/checkout',
    Wishlist: 'en/wishlist',
    Account: 'en/my-account',
    Order: 'en/order/:id',
    Privacy: 'en/privacy-policy',
    Privacy_mobile: 'en/privacy-policy-mobile',
    Terms: 'en/terms-of-use',
    Terms_mobile: 'en/terms-of-use-mobile',
    Payment: 'en/checkout/payment'
  },
  Empty: '',
  Home: 'home',
  Login: 'login',
  Category: 'categories',
  SubCategory: 'sub-category/:id',
  Product: 'product/:id',
  ReplaceOrder: 'replace/order/:id',
  ProductWithName: 'product/:id/:name',
  Cart: 'cart',
  Contact: 'contact-us',
  Contact_mobile: 'contact-us-mobile',
  Checkout: 'checkout',
  Wishlist: 'wishlist',
  Account: 'my-account',
  Order: 'order/:id',
  Privacy: 'privacy-policy',
  Privacy_mobile: 'privacy-policy-mobile',
  Terms: 'terms-of-use',
  Terms_mobile: 'terms-of-use-mobile',
  Payment: 'checkout/payment'
};
