import {Component, ElementRef, EventEmitter, HostListener, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {
  @Output() isModalClose: EventEmitter<boolean> = new EventEmitter();

  constructor(private elementRef: ElementRef) {
  }

  @HostListener('document:click', ['$event'])
  onClick(event: MouseEvent): void {
    if (!this.elementRef.nativeElement.querySelector('.modal__popup--container').contains(event.target as Node)) {
      this.closeModal();
      if (sessionStorage.getItem('popupShown')) {
        sessionStorage.setItem('popupShown', 'true');
      }
    }
  }

  ngOnInit(): void {
  }


  public closeModal(): void {
    this.isModalClose.emit(true);
  }
}
