import {Component, OnInit} from '@angular/core';
import {RouteEnum} from '../../enums/routes.enum';

@Component({
  selector: 'app-mobile-menu',
  templateUrl: './mobile-menu.component.html',
  styleUrls: ['./mobile-menu.component.scss']
})
export class MobileMenuComponent implements OnInit {
  public RouteEnum = RouteEnum;

  constructor() {
  }

  ngOnInit(): void {
  }

  getCurrentLang(): string {
    return localStorage.getItem('lang') || 'ar';
  }

}
