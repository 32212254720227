import { Injectable } from '@angular/core';
import {ApiService} from './api.service';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private apiService: ApiService) {
  }

  public authApi(): Observable<any> {
    const url = 'common/apiLogin';
    const key = {
      apiKey: 'YaserMallAPIKey-20201103-JOR-OJEED-@3-Ds4lf40',
      apiPassword: '42BEDF6923F1B683C3A8DF685CAF1B10'
    };
    const updated = JSON.stringify(key);
    return this.apiService.postToken(url, updated);
  }
}
