<div class="address__component--container fade-in">
  <p class="basic__info--title">
    {{'delivering_to' | translate}}
  </p>
  <p class="basic__info--desc">
    {{'address_p' | translate}}
  </p>
  <div class="address__wrapper">
    <div class="add__address--container" (click)="triggerAddAddress()">
      <i class="fa fa-plus-circle" aria-hidden="true"></i>
      <p>{{'add_address' | translate}}</p>
    </div>
    <div class="edit__address--container"
         [ngClass]="!isCheckout && 'my-accounts-address' "
         *ngFor="let address of addressList"
         (click)="selectAddress(address.address_id, address.delivery_fees[0].cost)"
         [class.active]="selectedAddress === address.address_id">
      <div>
        <i class="fa fa-pencil item__edit" *ngIf="!isCheckout" aria-hidden="true"
           (click)="triggerEditAddress(address)"></i>
      </div>
      <div class="wrapper-address-info">
       <span class="address_name" data-toggle='tooltip' title="{{address.address_name}}">
       {{address.address_name}}
     </span>
        <p class="address_desc">
          {{address.city}}
        </p>
        <p class="address_desc">
          {{address.street}}
        </p>
      </div>
    </div>
  </div>
</div>
<app-address-map-popup *ngIf="showAddressPopup" (updatedAddress)="getAddresses()"
                   (showAddress)="showAddressPopup = $event; selectedAddressToPopUp = null ; isEditAddress = $event"
                   [title]="addressPopupTitle"
                   [addressData]="selectedAddressToPopUp" [isEditAddress]=isEditAddress></app-address-map-popup>
