<div class="smart-banner__container slide-in-top">
  <img src="/assets/images/global/close-white.svg" class="banner--close__icon" (click)="closeBanner()">
  <img src="/assets/images/global/banner-logo.svg" class="banner--logo">
  <div class="banner--text__container">
    <p class="tile">{{'banner_title' | translate}}</p>
    <p class="desc">{{'banner_desc' | translate}}</p>
  </div>
  <a class="banner--get__btn" href="http://onelink.to/6kgw7q" target="_blank" (click)="closeBanner()">
    {{'banner_btn' | translate}}
  </a>
</div>
