<div class="checkout__page--container fade-in container__max-width page-min-height header-margin-top mobile_menu--padding">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <p class="checkout__page--tile">{{'secure_checkout' | translate}}</p>
        <app-checkout (checkoutObj)="checkOutObj = $event" [cartItems]="cartObj" [isAppliedCoupon]="!!cartObj?.coupon"></app-checkout>
        <app-cart-listing [cartItems]="cartObj?.products" [isCheckout]="true"></app-cart-listing>
      </div>
    </div>
  </div>
</div>
