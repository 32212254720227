import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AddressService} from '../../services/address.service';
import {TranslateService} from '@ngx-translate/core';
import {IDeliveryMethodsByAddress} from "../../intefaces/Address";


@Component({
  selector: 'app-address-container',
  templateUrl: './address-container.component.html',
  styleUrls: ['./address-container.component.scss']
})
export class AddressContainerComponent implements OnInit {

  public showAddressPopup = false;
  public addressPopupTitle: string;
  public addressList: any;
  public selectedAddress: any;
  public selectedAddressToPopUp: any;
  public isEditAddress = false;
  @Input() isCheckout;
  @Output() deliveryMethodsRes: EventEmitter<IDeliveryMethodsByAddress> = new EventEmitter();
  @Output() selectedAddressOutput: EventEmitter<number> = new EventEmitter();
  @Output() selectedAddressDeliveryPrice: EventEmitter<any> = new EventEmitter();

  constructor(private addressService: AddressService, public translationService: TranslateService) {
  }

  ngOnInit(): void {
    this.getAddresses();
  }

  public triggerAddAddress(): void {
    this.selectedAddress = null;
    this.showAddressPopup = true;
    this.addressPopupTitle = this.translationService.instant('add_address');
  }

  public triggerEditAddress(address): void {
    this.showAddressPopup = true;
    this.isEditAddress = true;
    this.selectedAddressToPopUp = address;
    this.addressPopupTitle = this.translationService.instant('edit_address');
  }

  public selectAddress(id, price): void {
    this.selectedAddress = id;
    this.selectedAddressOutput.emit(id);
    this.selectedAddressDeliveryPrice.emit(price);
    this.getDeliveryMethods(id);
  }

  public getAddresses(): void {
    this.addressService.getAddresses().subscribe(
      (res) => {
        this.addressList = res.addressData;
      },
      (err) => {

      }
    );
  }

  public getDeliveryMethods(addressId): void {
    this.addressService.getDeliveryMethodsByAddress(addressId).subscribe((res => {
      this.deliveryMethodsRes.emit(res);
    }), (err) => {
      console.log(err);
    });
  }

}
