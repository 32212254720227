import {Component, Input, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {RouteEnum} from '../../enums/routes.enum';

@Component({
  selector: 'app-category-hor-listing',
  templateUrl: './category-hor-listing.component.html',
  styleUrls: ['./category-hor-listing.component.scss']
})
export class CategoryHorListingComponent implements OnInit {
  public slideConfig;
  public RouteEnum = RouteEnum;
  @Input() categoryArr = [];

  constructor(public translate: TranslateService) {
    translate.get('carousel_rtl').subscribe((text: string) => {
      const carouselDir = text === 'true';
      this.slideConfig = {
        draggable: true,
        accessibility: false,
        variableWidth: true,
        arrows: true,
        dots: false,
        swipeToSlide: true,
        infinite: true,
        rtl: carouselDir
      };
    });
  }

  ngOnInit(): void {
  }

  getCurrentLang(): string {
    return localStorage.getItem('lang') || 'ar';
  }

}
