import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {Observable} from 'rxjs';
import {BrowserService} from './browser.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  public userInfo: any;

  constructor(private apiService: ApiService, public browserService: BrowserService) {
  }

  public registerUser(userInfo): Observable<any> {
    const url = 'customer/addCustomer';
    return this.apiService.post(url, userInfo);
  }

  public load(): any {
    const currentUrl = location.href;
    if (currentUrl.toString().includes('/ar/')) {
      localStorage.setItem('lang', 'ar');
    } else if (currentUrl.toString().includes('/en/')) {
      localStorage.setItem('lang', 'en');
    }
    const url = 'customer/myAccount';
    return new Promise((resolve, reject) => {
      this.apiService
        .get(url)
        .subscribe((response) => {
            this.userInfo = response;
            if ((window as any).hasUnavailableOrders) {
              this.modifyElementStyles();
            }
            resolve(true);
          },
          (err) => {
            resolve(true); // Fallback for any failure on API level
          });
    });
  }

  public modifyElementStyles(): void {
    setTimeout(() => {
      const arrayNodes = Array.from(document.getElementsByClassName('custom-header-margin-top') as HTMLCollectionOf<HTMLElement>)
        .concat(Array.from(document.getElementsByClassName('header-margin-top') as HTMLCollectionOf<HTMLElement>))
        .concat(Array.from(document.getElementsByClassName('header-margin-top-home') as HTMLCollectionOf<HTMLElement>));
      const marginTop = !this.browserService.isMobileDevice() ? '240px' : '10px';

      for (const element of arrayNodes) {
        element.style.marginTop = marginTop;
      }
    }, 800);
  }


  public getUserInfo(): Observable<any> {
    const url = 'customer/myAccount';
    return this.apiService.get(url);
  }

  public getUserOrders(): Observable<any> {
    const url = 'customer/getOrders&limit=20';
    return this.apiService.get(url);
  }

  public getUserOrderById(id): Observable<any> {
    const url = `customer/getOrderById&order_id=${id}`;
    return this.apiService.get(url);
  }

  public editUserInfo(userInfo): Observable<any> {
    const url = 'customer/editCustomer';
    return this.apiService.post(url, userInfo);
  }

  public contactUsSubmission(formObj): Observable<any> {
    const url = 'common/contactUs';
    return this.apiService.post(url, formObj);
  }

  public sendOtpCode(mobileNumber): Observable<any> {
    const url = 'mobile_customer/loginOTP';
    return this.apiService.post(url, mobileNumber);
  }

  public submitCode(mobileInfo): Observable<any> {
    const url = 'mobile_customer/customerLogin';
    return this.apiService.post(url, mobileInfo);
  }


}
