import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {RouteEnum} from '../../enums/routes.enum';
import {UserService} from '../../services/user.service';
import {ProductService} from '../../services/product.service';
import {environment} from '../../../environments/environment.prod';
import {OrderStatusEnum} from '../../enums/order.enum';
import {PaymentStatusEnum} from "../../components/order-history/order-history.component";
import {CheckoutService} from "../../services/checkout.service";
import {PaymentAmountPaymentEnum} from "../../components/checkout/checkout.component";
import {TranslateService} from "@ngx-translate/core";
import {IOrderDetails} from "../../intefaces/OrderDetails";

@Component({
  selector: 'app-order-details-page',
  templateUrl: './order-details-page.component.html',
  styleUrls: ['./order-details-page.component.scss']
})

export class OrderDetailsPageComponent implements OnInit {
  public RouteEnum = RouteEnum;
  public orderObj: IOrderDetails;
  public orderId: string;
  public isDelivered = false;
  public isReplaced = false;
  public product;
  public replacedItems;

  constructor(public route: ActivatedRoute, public router: Router,
              public userService: UserService, private productService: ProductService, private checkoutService: CheckoutService,
              private translate: TranslateService
  ) {
  }

  ngOnInit(): void {
    this.getOrderById();
  }

  public getOrderById(): void {
    if (this.route.snapshot.paramMap.get('id')) {
      this.orderId = this.route.snapshot.paramMap.get('id');
      this.userService.getUserOrderById(this.orderId).subscribe(
        (res) => {
          this.orderObj = res;
          this.isDelivered = [OrderStatusEnum.ORDER_STATUS_DELEIVERED.id.toString(), OrderStatusEnum.ORDER_STATUS_COMPLETED.id.toString(),
            OrderStatusEnum.ORDER_STATUS_OUT_FOR_DELEIVRY.id.toString()].includes(res?.status_id?.toString());
          this.isReplaced = !!res?.replaced_items?.length;
          this.replacedItems = res?.replaced_items.map(it => it?.replaced_product) || [];
        },
        (err) => {
          console.error(err);
        }
      );
    } else {
      this.router.navigateByUrl(`/${localStorage.getItem('lang')}/home`).then();
    }
  }

  public onReOrder(product): void {
    this.productService.getProductById(product?.product_id, environment.singleImgSize).subscribe((res) => {
      this.product = res;
    });
  }

  public checkPaymentStatus(order): { bool: boolean, status: string } {
    switch (order?.payment_status) {
      case PaymentStatusEnum.SUCCESS_CP:
      case PaymentStatusEnum.SUCCESS_DB:
      case PaymentStatusEnum.SUCCESS_RF:
      case PaymentStatusEnum.SUCCESS_PA:
        return {bool: true, status: 'paid'};
      case PaymentStatusEnum.PENDING:
        return {bool: false, status: 'unpaid'};
      case PaymentStatusEnum.FAILED:
        return {bool: false, status: 'failed'};
      default:
        return {bool: false, status: 'unknown'};
    }
  }

  public getCurrentLang(): string {
    return localStorage.getItem('lang') || 'ar';
  }

  public prepareCheckoutPayment(orderId: number): void {
    window.location.href = `${this.getCurrentLang()}/checkout/payment?orderId=${orderId}`
  }


  public setupPaymentStatusPaid(): { bool: boolean, msg: string } {
    let remainingAmount: number | string = null;

    // Check if orderObj and lastTransaction exist and payment_amount_type is SUB_TOTAL.
    if (this.orderObj && this.orderObj.lastTransaction && (Number(this.orderObj.lastTransaction.amount) > 0) &&
      this.orderObj.lastTransaction.payment_amount_type === PaymentAmountPaymentEnum.SUB_TOTAL) {
      // Check payment status for SUCCESS_RF.
      if (this.orderObj.payment_status === PaymentStatusEnum.SUCCESS_RF) {
        // Check if both deliveryFees and serviceFees are not free.
        if (!(this.orderObj.is_free_delivery_fees && this.orderObj.is_free_service_fees)) {
          remainingAmount = 0;

          // Add deliveryFees if not free and not zero.
          if (!this.orderObj.is_free_delivery_fees && this.orderObj.deliveryFees !== 0) {
            remainingAmount += Number(this.orderObj.deliveryFees);
          }

          // Add serviceFees if not free and not zero.
          if (!this.orderObj.is_free_service_fees && this.orderObj.serviceFees !== 0) {
            remainingAmount += Number(this.orderObj.serviceFees);
          }
        }
      } else {
        // Calculate remainingAmount based on lastTransaction amount.
        remainingAmount = this.orderObj.lastTransaction.amount !== null
          ? Number(this.orderObj.total_price) - Number(this.orderObj.lastTransaction.amount)
          : null;
      }


      // Check if remainingAmount is zero or null.
      if (remainingAmount === 0 || remainingAmount === 0.0 || remainingAmount < 0) {
        return {bool: false, msg: ''};
      }

      // Check if remainingAmount is a number.
      if (typeof remainingAmount === 'number') {
        // Format remainingAmount and create a message.
        remainingAmount = remainingAmount.toFixed(2);
        const msg = this.translate.instant('order_payment_msg')
          .replace('TAMOUNT', Number(this.orderObj.lastTransaction.amount).toFixed(2))?.replace('SAMOUNT', remainingAmount)
          .replace('METHOD', this.translate.instant('visa'));
        return {bool: true, msg};
      }
    }

    // Return default values if conditions are not met.
    return {bool: false, msg: ''};
  }

  public setupCardOnDeliveryStatus(): { bool: boolean, msg: string } {

    if (this.orderObj?.payment_card_type?.id) {
      // Conditions
      const isFreeDeliveryFees = this.orderObj.is_free_delivery_fees;
      const isFreeServiceFees = this.orderObj.is_free_service_fees;
      const deliveryFees = this.orderObj.deliveryFees || 0.0;
      const serviceFees = this.orderObj.serviceFees || 0.0;
      // Translations
      const deliveryFeesLabel = this.translate.instant('delivery_fee');
      const serviceFeesLabel = this.translate.instant('service_fee');
      const deliveryAndServiceLabel = this.translate.instant('delivery_and_service_fees');

      let extraText = ''
      if (isFreeDeliveryFees && isFreeServiceFees) {
        return {bool: false, msg: ""}
      } else if (!isFreeDeliveryFees && !isFreeServiceFees && deliveryFees !== 0.0 && serviceFees !== 0.0) {
        extraText = this.translate.instant('card_on_delivery_order_details').replace('__FEES__', deliveryAndServiceLabel);
        return {bool: true, msg: extraText}
      } else if (deliveryFees !== 0.0 && !isFreeDeliveryFees) {
        extraText = this.translate.instant('card_on_delivery_order_details').replace('__FEES__', deliveryFeesLabel);
        return {bool: true, msg: extraText}
      } else if (serviceFees !== 0.0 && !isFreeServiceFees) {
        extraText = this.translate.instant('card_on_delivery_order_details').replace('__FEES__', serviceFeesLabel);
        return {bool: true, msg: extraText}
      } else {
        return {bool: false, msg: ""}
      }

    }

    return {bool: false, msg: ""}
  }


}
