<div class="cart__page--container fade-in container__max-width page-min-height header-margin-top mobile_menu--padding">
  <div class="container-fluid">
    <p class="cart__cont--text">{{cartObj?.total_products}} {{'items' | translate}}</p>
    <div class="row">
      <div class="col-md-8">
        <app-cart-listing *ngIf="cartObj?.total_products" (productDeleted)="getCart()" [cartItems]="cartObj?.products" (cartObj)="cartObj = $event"></app-cart-listing>
      </div>
      <div class="col-md-4">
        <app-order-summary *ngIf="cartObj?.total_products" [checkOutObj]="false" [cartDetails]="cartObj"></app-order-summary>
      </div>
    </div>
  </div>
  <app-empty-page *ngIf="!cartObj?.total_products" [pageTitle]="translateService.instant('cart')"></app-empty-page>
</div>
