import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {HomePageComponent} from './pages/home-page/home-page.component';
import {RouteEnum} from './enums/routes.enum';
import {CategoryPageComponent} from './pages/category-page/category-page.component';
import {SubCategoryPageComponent} from './pages/sub-category-page/sub-category-page.component';
import {ProductPageComponent} from './pages/product-page/product-page.component';
import {CartPageComponent} from './pages/cart-page/cart-page.component';
import {CheckoutPageComponent} from './pages/checkout-page/checkout-page.component';
import {WishlistPageComponent} from './pages/wishlist-page/wishlist-page.component';
import {AccountPageComponent} from './pages/account-page/account-page.component';
import {AuthGuard} from '../auth/auth.guard';
import {LoginComponent} from './components/login/login.component';
import {TermsPageComponent} from './pages/terms-page/terms-page.component';
import {PrivacyPageComponent} from './pages/privacy-page/privacy-page.component';
import {OrderDetailsPageComponent} from './pages/order-details-page/order-details-page.component';
import {ContactPageComponent} from './pages/contact-page/contact-page.component';
import {ReplaceOrderPageComponent} from './pages/replace-order/replace-order.component';
import {PaymentPageComponent} from "./pages/payment/payment-page.component";

const routes: Routes = [
  {path: RouteEnum.en.Home, component: HomePageComponent},
  {path: RouteEnum.en.Empty, component: HomePageComponent},
  {path: RouteEnum.en.Category, component: CategoryPageComponent},
  {path: RouteEnum.en.SubCategory, component: SubCategoryPageComponent},
  {path: RouteEnum.en.ReplaceOrder, component: ReplaceOrderPageComponent, canActivate: [AuthGuard]},
  {path: RouteEnum.en.Product, component: ProductPageComponent},
  {path: RouteEnum.en.ProductWithName, component: ProductPageComponent},
  {path: RouteEnum.en.Cart, component: CartPageComponent, canActivate: [AuthGuard]},
  {path: RouteEnum.en.Login, component: LoginComponent},
  {path: RouteEnum.en.Checkout, component: CheckoutPageComponent, canActivate: [AuthGuard]},
  {path: RouteEnum.en.Account, component: AccountPageComponent, canActivate: [AuthGuard]},
  {path: RouteEnum.en.Wishlist, component: WishlistPageComponent, canActivate: [AuthGuard]},
  {path: RouteEnum.en.Order, component: OrderDetailsPageComponent, canActivate: [AuthGuard]},
  {path: RouteEnum.en.Contact, component: ContactPageComponent},
  {path: RouteEnum.en.Terms, component: TermsPageComponent},
  {path: RouteEnum.en.Terms_mobile, component: TermsPageComponent},
  {path: RouteEnum.en.Contact_mobile, component: ContactPageComponent},
  {path: RouteEnum.en.Privacy, component: PrivacyPageComponent},
  {path: RouteEnum.en.Privacy_mobile, component: PrivacyPageComponent},
  {path: RouteEnum.en.Payment, component: PaymentPageComponent , canActivate: [AuthGuard]},
  /*AR*/
  {path: RouteEnum.ar.Home, component: HomePageComponent},
  {path: RouteEnum.ar.Empty, component: HomePageComponent},
  {path: RouteEnum.ar.Category, component: CategoryPageComponent},
  {path: RouteEnum.ar.SubCategory, component: SubCategoryPageComponent},
  {path: RouteEnum.ar.ReplaceOrder, component: ReplaceOrderPageComponent , canActivate: [AuthGuard]},
  {path: RouteEnum.ar.Product, component: ProductPageComponent},
  {path: RouteEnum.ar.ProductWithName, component: ProductPageComponent},
  {path: RouteEnum.ar.Cart, component: CartPageComponent, canActivate: [AuthGuard]},
  {path: RouteEnum.ar.Login, component: LoginComponent},
  {path: RouteEnum.ar.Checkout, component: CheckoutPageComponent, canActivate: [AuthGuard]},
  {path: RouteEnum.ar.Account, component: AccountPageComponent, canActivate: [AuthGuard]},
  {path: RouteEnum.ar.Wishlist, component: WishlistPageComponent, canActivate: [AuthGuard]},
  {path: RouteEnum.ar.Order, component: OrderDetailsPageComponent, canActivate: [AuthGuard]},
  {path: RouteEnum.ar.Contact, component: ContactPageComponent},
  {path: RouteEnum.ar.Terms, component: TermsPageComponent},
  {path: RouteEnum.ar.Terms_mobile, component: TermsPageComponent},
  {path: RouteEnum.ar.Contact_mobile, component: ContactPageComponent},
  {path: RouteEnum.ar.Privacy, component: PrivacyPageComponent},
  {path: RouteEnum.ar.Privacy_mobile, component: PrivacyPageComponent},
  {path: RouteEnum.ar.Payment, component: PaymentPageComponent , canActivate: [AuthGuard]},
  {path: '', redirectTo: RouteEnum.ar.Home, pathMatch: 'full'},
  {path: '**', redirectTo: RouteEnum.ar.Home}
]; // sets up routes constant where you define your routes


@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled', onSameUrlNavigation: 'reload'})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
