import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {IDeliveryMethodsByAddress} from "../intefaces/Address";

@Injectable({
  providedIn: 'root'
})
export class AddressService {
  private location: { lat: number, lng: number } = {lat: 0, lng: 0};

  constructor(private apiService: ApiService, private httpClient: HttpClient) {
  }

  public getDeliveryZones(): Observable<any> {
    const url = `cart/getCountry`;
    return this.apiService.get(url);
  }

  public addAddress(addressBody): Observable<any> {
    const url = `customer/addAddress`;
    return this.apiService.post(url, addressBody);
  }

  public getAddresses(): Observable<any> {
    const url = `customer/getAddresses`;
    return this.apiService.get(url);
  }

  public deleteAddress(id): Observable<any> {
    const url = `customer/deleteAddress&address_id=${id}`;
    return this.apiService.delete(url);
  }

  public editAddress(): Observable<any> {
    const url = `customer/getAddresses`;
    return this.apiService.get(url);
  }

  getCurrentLocation(): Observable<any> {
    return new Observable(obs => {

      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          success => {
            this.location.lat = success.coords.latitude;
            this.location.lng = success.coords.longitude;
            obs.next(this.location);
          },
          error => {
            obs.error(error);
          }
        );
      } else {
        console.error('Geolocation is not supported by this browser.');
      }

    });
  }

  getCurrentLang(): string {
    return localStorage.getItem('lang') || 'ar';
  }


  getAddressInfo(latitude: number, longitude: number): Observable<any> {
    const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&language=${this.getCurrentLang()}&key=${environment.api_key_google_map}`;
    return this.httpClient.get(url);
  }


  getDeliveryMethodsByAddress(addressId): Observable<IDeliveryMethodsByAddress> {
    const url = `delivery/getDeliveryMethodsByAddress&address_id=${addressId}`;
    return this.apiService.get(url);
  }

}

