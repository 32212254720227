import { Component, OnInit } from '@angular/core';
import {CartService} from '../../services/cart.service';
import {environment} from '../../../environments/environment';
import {ICartView} from "../../intefaces/Cart";

@Component({
  selector: 'app-checkout-page',
  templateUrl: './checkout-page.component.html',
  styleUrls: ['./checkout-page.component.scss']
})
export class CheckoutPageComponent implements OnInit {

  public cartObj: ICartView;
  public checkOutObj: any;

  constructor(private cartService: CartService) { }

  ngOnInit(): void {
    this.getCart();
  }

  public getCart(): void {
    this.cartService.getCartItems(environment.listingImgSize).subscribe(
      (res) => {
        this.cartObj = res;
      },
      (err) => {
      console.error(err);
      }
    );
  }

}
