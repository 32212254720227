import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {HomePageComponent} from './pages/home-page/home-page.component';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {HeaderComponent} from './components/header/header.component';
import {FooterComponent} from './components/footer/footer.component';
import {SlickCarouselModule} from 'ngx-slick-carousel';
import {HomeSliderComponent} from './components/home-slider/home-slider.component';
import {ProductHorListingComponent} from './components/product-hor-listing/product-hor-listing.component';
import {SingleProductComponent} from './components/single-product/single-product.component';
import {CategoryHorListingComponent} from './components/category-hor-listing/category-hor-listing.component';
import {SingleCategoryComponent} from './components/single-category/single-category.component';
import {AdBannerComponent} from './components/ad-banner/ad-banner.component';
import {CookieBannerComponent} from './components/cookie-banner/cookie-banner.component';
import {LoginComponent} from './components/login/login.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {OrderStatusComponent} from './components/order-status/order-status.component';
import {CategoryPageComponent} from './pages/category-page/category-page.component';
import {SubCategoryPageComponent} from './pages/sub-category-page/sub-category-page.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatInputModule} from '@angular/material/input';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {FilterComponent} from './components/filter/filter.component';
import {NgxSliderModule} from '@angular-slider/ngx-slider';
import {ResultsHeaderComponent} from './components/results-header/results-header.component';
import {ProductPageComponent} from './pages/product-page/product-page.component';
import {MobileMenuComponent} from './components/mobile-menu/mobile-menu.component';
import {AddToCartComponent} from './components/add-to-cart/add-to-cart.component';
import {CartPageComponent} from './pages/cart-page/cart-page.component';
import {OrderSummaryComponent} from './components/order-summary/order-summary.component';
import {CartListingComponent} from './components/cart-listing/cart-listing.component';
import {CheckoutPageComponent} from './pages/checkout-page/checkout-page.component';
import {CheckoutComponent} from './components/checkout/checkout.component';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import {WishlistPageComponent} from './pages/wishlist-page/wishlist-page.component';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {AccountPageComponent} from './pages/account-page/account-page.component';
import {BasicInfoComponent} from './components/basic-info/basic-info.component';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material/core';
import {UserService} from './services/user.service';
import {AddressContainerComponent} from './components/address-container/address-container.component';
import {MatSelectModule} from '@angular/material/select';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {EmptyPageComponent} from './components/empty-page/empty-page.component';
import {OrderHistoryComponent} from './components/order-history/order-history.component';
import {PrivacyPageComponent} from './pages/privacy-page/privacy-page.component';
import {TermsPageComponent} from './pages/terms-page/terms-page.component';
import {OrderDetailsPageComponent} from './pages/order-details-page/order-details-page.component';
import {LoadingComponent} from './components/loading/loading.component';
import {LottieModule} from 'ngx-lottie';
import player from 'lottie-web';
import {LoadMoreIndicatorComponent} from './components/load-more-indicator/load-more-indicator.component';
import {ContactPageComponent} from './pages/contact-page/contact-page.component';
import {LoyaltyCardComponent} from './components/loyalty-card/loyalty-card.component';
import {ClipboardModule} from '@angular/cdk/clipboard';
import {SmartBannerComponent} from './components/smart-banner/smart-banner.component';
import {ServiceWorkerModule} from '@angular/service-worker';
import {environment} from '../environments/environment';
import {AgmCoreModule} from '@agm/core';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {AddressMapPopupComponent} from './components/address-map-popup/address-map-popup.component';
import { ModalComponent } from './components/modal/modal.component';
import {ReplaceOrderPageComponent} from './pages/replace-order/replace-order.component';
import { PaymentPageComponent } from './pages/payment/payment-page.component';
import { ManageCardsComponent } from './components/manage-cards/manage-cards.component';


// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient): any {
  return new TranslateHttpLoader(httpClient);
}

// by the AOT compiler.
export function playerFactory(): any {
  return player;
}

// User Service API Preload
export function userProviderFactory(provider: UserService): any {
  return () => provider.load();
}

@NgModule({
  declarations: [
    AppComponent,
    HomePageComponent,
    HeaderComponent,
    FooterComponent,
    HomeSliderComponent,
    ProductHorListingComponent,
    SingleProductComponent,
    CategoryHorListingComponent,
    SingleCategoryComponent,
    AdBannerComponent,
    CookieBannerComponent,
    LoginComponent,
    OrderStatusComponent,
    CategoryPageComponent,
    SubCategoryPageComponent,
    FilterComponent,
    ResultsHeaderComponent,
    ReplaceOrderPageComponent,
    ProductPageComponent,
    MobileMenuComponent,
    AddToCartComponent,
    CartPageComponent,
    OrderSummaryComponent,
    CartListingComponent,
    CheckoutPageComponent,
    CheckoutComponent,
    WishlistPageComponent,
    AccountPageComponent,
    BasicInfoComponent,
    AddressContainerComponent,
    EmptyPageComponent,
    OrderHistoryComponent,
    PrivacyPageComponent,
    TermsPageComponent,
    OrderDetailsPageComponent,
    LoadingComponent,
    LoadMoreIndicatorComponent,
    ContactPageComponent,
    LoyaltyCardComponent,
    SmartBannerComponent,
    AddressMapPopupComponent,
    ModalComponent,
    PaymentPageComponent,
    ManageCardsComponent,
  ],
  providers: [
    MatDatepickerModule,
    UserService,
    AppComponent,
    {
      provide: APP_INITIALIZER,
      useFactory: userProviderFactory,
      deps: [UserService],
      multi: true
    }
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    SlickCarouselModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      defaultLanguage: 'ar'
    }),
    FormsModule,
    BrowserAnimationsModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    NgxSliderModule,
    InfiniteScrollModule,
    MatAutocompleteModule,
    MatDatepickerModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSelectModule,
    MatSnackBarModule,
    MatCheckboxModule,
    LottieModule.forRoot({player: playerFactory}),
    ClipboardModule,
    ServiceWorkerModule.register('ngsw-worker.js', {enabled: environment.production}),
    AgmCoreModule.forRoot({
      apiKey: environment.api_key_google_map
    }),
    MatButtonToggleModule
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {
}
