import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CheckoutService {

  constructor(private apiService: ApiService) {
  }

  public getDeliveryTimes(): Observable<any> {
    const url = `checkout/getDateTimeSlots`;
    return this.apiService.get(url);
  }

  public getDeliveryTimesForPrimeOrNormal(deliveryMethod: string): Observable<any> {
    const url = `checkoutv2/getDateTimeSlots&methodName=${deliveryMethod}`;
    return this.apiService.get(url);
  }

  public postCheckout(checkoutObj): Observable<any> {
    const url = `checkoutv2/checkout`;
    return this.apiService.post(url, checkoutObj);
  }

  public checkoutPayment(orderId: number, body): Observable<any> {
    const url = `epayment/prepareCheckout&orderId=${orderId}`;
    return this.apiService.post(url, body);
  }

  public paymentStatus(resourcePath): Observable<any> {
    const url = `epayment/paymentStatus`;
    const body = {
      resourcePath
    }
    return this.apiService.post(url, body)
  }

  public paymentAmountType(): Observable<any> {
    const url = `epayment/paymentAmountType`;
    return this.apiService.get(url);
  }

  public getSavedCards(): Observable<any> {
    const url = `epayment/savedCards`;
    return this.apiService.get(url);
  }

  public deleteSavedCards(registrationId): Observable<any> {
    const url = `epayment/deleteCard&registrationId=${registrationId}`;
    return this.apiService.delete(url);
  }
}
