<div class="wishlist__page--container page-min-height fade-in container__max-width header-margin-top">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <div class="sub--single_product" *ngFor="let wishProduct of wishList">
          <app-single-product (changeWishList)="getWishList()" [productData]="wishProduct"></app-single-product>
        </div>
      </div>
    </div>
  </div>

  <app-empty-page [pageTitle]="translateService.instant('wishlist')" *ngIf="wishList?.length === 0"></app-empty-page>
</div>
