import { Component, OnInit } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Router} from '@angular/router';
import {RouteEnum} from '../../enums/routes.enum';

@Component({
  selector: 'app-terms-page',
  templateUrl: './terms-page.component.html',
  styleUrls: ['./terms-page.component.scss']
})
export class TermsPageComponent implements OnInit {
  public isMobileWebView = false;
  public RouteEnum = RouteEnum;
  constructor(public translateService: TranslateService, public router: Router) { }

  ngOnInit(): void {
    if (this.router.url.split('/').join('').includes(this.RouteEnum.Terms_mobile)) {
      this.isMobileWebView = true;
    }
  }

}
