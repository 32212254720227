<div class="result__header--container">
  <p class="result__header--title">
    {{title}}
  </p>
  <div class="results__stats">
    <span class="result__count">
      {{resultCount}} {{'results' | translate}}
    </span>
    <div class="sort__select--container">
      <label class="sort__label" for="select-sort">
        {{'sort_by' | translate}}
      </label>
      <img class="sort__img" (click)="toggleFilterModal()" src="/assets/images/global/sortby.svg">

      <select class="sort__select--list" id="select-sort" #sortList (change)="selectSortOption($event.target.value)">
        <option value="{{i}}" *ngFor="let option of sortOptions; index as i">{{option?.text}}</option>
      </select>
    </div>
  </div>
</div>
