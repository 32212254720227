<div class="filters__container">
  <div class="sub-category__filter" *ngIf="subCategoryList?.length">
    <p class="sub__category--title" (click)="browserService.expandList(categoryWrapper, categoryChev)">
      {{'sub_categories' | translate}} <i #categoryChev class="fa fa-chevron-down chev__down" aria-hidden="true"></i>
    </p>
    <div class="sub-category__wrapper" #categoryWrapper>
      <div class="sub-category__container" *ngFor="let sub of subCategoryList">
              <span class="sub-category--border">
                <input [checked]="activeCategoryNumber === sub.path" class="sub-category__input--box" type="checkbox" name="food" (change)="setCategoryId(sub?.path)" id="{{sub?.path}}">
              </span>
        <label class="sub-category__label" for="{{sub?.path}}"> {{sub?.name}}</label>
      </div>
    </div>
  </div>
  <!--<div class="brand__filter">
    <p class="sub__category&#45;&#45;title" (click)="browserService.expandList(brandWrapper, brandChev)">
      Brands <i #brandChev class="fa fa-chevron-down chev__down" aria-hidden="true"></i>
    </p>
    <div class="sub-category__wrapper" #brandWrapper>
      <div class="sub-category__container" *ngFor="let brand of productCategoryData?.manufacturers">
              <span class="sub-category&#45;&#45;border">
                <input [checked]="activeBrandNumber === brand?.manufacturer_id" class="sub-category__input&#45;&#45;box" type="checkbox" (change)="setBrandId(brand?.manufacturer_id)" id="{{brand?.manufacturer_id}}">
              </span>
        <label class="sub-category__label" for="{{brand?.manufacturer_id}}"> {{brand?.name}}</label>
      </div>
    </div>
  </div>-->
  <div class="price__filter">
    <p class="sub__category--title">
      {{'price' | translate}}
    </p>
    <div class="price__slider--container">
      <ngx-slider [(value)]="minValue" [(highValue)]="maxValue" [options]="options"></ngx-slider>
    </div>
    <mat-form-field appearance="none" class="price__filter--input min">
      <mat-label>{{'min' | translate}} ({{'currency' | translate}})</mat-label>
      <input [(ngModel)]="minValue" matInput type="text">
    </mat-form-field>
    <i class="fa fa-minus input-dash" aria-hidden="true"></i>
    <mat-form-field appearance="none" class="price__filter--input max">
      <mat-label>{{'max' | translate}} ({{'currency' | translate}})</mat-label>
      <input [(ngModel)]="maxValue" matInput type="text">
    </mat-form-field>
  </div>
  <div class="filter__buttons">
    <button class="filter__clear" (click)="toggleFilterModal();resetValues();changePriceRange()">
      {{'clear' | translate}}
    </button>
    <button class="filter__apply" (click)="toggleFilterModal();changePriceRange()">
      {{'apply' | translate}}
    </button>
  </div>
</div>
