import {Injectable} from '@angular/core';

interface MyDateTimeFormatOptions extends Intl.DateTimeFormatOptions {
  calendar?: string;
}

@Injectable({
  providedIn: 'root'
})
export class FormatDateService {
  constructor() {
  }

  formatDate(date: string, lang: string): string {
    if (!date) {
      return '';
    }

    const locale = lang === 'ar' ? 'ar-SA-u-nu-latn' : 'en-US';
    const calendar = lang === 'ar' ? 'gregory' : undefined;

    const parsedDate = new Date(date);
    const formattedDate = parsedDate.toLocaleDateString(locale, {
      weekday: 'long',
      month: 'long',
      day: 'numeric',
      calendar
    } as MyDateTimeFormatOptions);

    return formattedDate.replace(/,/g, '،');
  }
}
