import {Component, EventEmitter, Input, OnInit, Output, Renderer2} from '@angular/core';
import {BrowserService} from '../../services/browser.service';
import {Options} from '@angular-slider/ngx-slider';
import {CategoryService} from '../../services/category.service';
import {ProductService} from '../../services/product.service';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit {
  @Input() productCategoryData: any;
  @Input() subCategoryList = [];
  @Output() filterCategoryId: EventEmitter<string> = new EventEmitter();
  @Output() filterBrandId: EventEmitter<string> = new EventEmitter();
  @Output() toggleModal: EventEmitter<boolean> = new EventEmitter();
  @Output() priceFilterArr: EventEmitter<any> = new EventEmitter();
  public activeCategoryNumber;
  public activeBrandNumber;
  private isModalToggeled = false;
  value = 100;
  maxValue = 200;
  minValue = 1;
  options: Options = {
    floor: 0,
    ceil: 200
  };

  constructor(public browserService: BrowserService,
              private productService: ProductService,
              private renderer: Renderer2,
              private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.activeCategoryNumber = this.route.snapshot.paramMap.get('id');
  }

  public setCategoryId(categoryId): void {
    this.activeCategoryNumber = categoryId;
    this.filterCategoryId.emit(categoryId);
  }

  public setBrandId(categoryId): void {
    this.activeCategoryNumber = categoryId;
    this.filterBrandId.emit(categoryId);
  }

  public toggleFilterModal(): void {
    this.toggleModal.emit(false);
    this.renderer.removeClass(document.body, 'modal-open');
    this.isModalToggeled = !this.isModalToggeled;
  }

  public changePriceRange(): void {
    this.priceFilterArr.emit([this.minValue, this.maxValue]);
  }

  public resetValues(): void {
    this.minValue = 0;
    this.maxValue = 200;
  }

}
