import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  constructor(private apiService: ApiService) {
  }

  public getBestProducts(): Observable<any> {
    const url = 'common/getBestProducts';
    return this.apiService.get(url);
  }

  public getPopularProducts(): Observable<any> {
    const url = 'common/getPopularProducts';
    return this.apiService.get(url);
  }

  public getLatestProducts(width?, count?): Observable<any> {
    const url = `common/getHomepageLatest&width=${width}&count=${count}`;
    return this.apiService.get(url);
  }

  public getLatestProductsViewAll(width?, sort?, order?, page?, limit?, startPrice?, endPrice?, deal?): Observable<any> {
    const url = `common/latestProduct&width=${width}&sort=${sort}&order=${order}&page=${page}&limit=${limit}&price_from=${startPrice}&price_to=${endPrice}&deal=${deal}`;
    return this.apiService.get(url);
  }

  public getFeaturedProducts(width?, count?): Observable<any> {
    const url = `common/getHomepageFeatured&width=${width}&count=${count}`;
    return this.apiService.get(url);
  }

  public getFeaturedProductsViewAll(width?, sort?, order?, page?, limit?, startPrice?, endPrice?, deal?): Observable<any> {
    const url = `common/getFeatured&width=${width}&sort=${sort}&order=${order}&page=${page}&limit=${limit}&price_from=${startPrice}&price_to=${endPrice}&deal=${deal}`;
    return this.apiService.get(url);
  }

  public getHotDeals(width?, count?): Observable<any> {
    const url = `common/getHomepageHotDeals&width=${width}&count=${count}`;
    return this.apiService.get(url);
  }

  public getSeasonalProducts(width?, count?): Observable<any> {
    const url = `common/getHomepageSeasonal&width=${width}&count=${count}`;
    return this.apiService.get(url);
  }

  public frequentlyBought(width?, count?): Observable<any> {
    const url = `common/getHomepageFrequentlyBought&width=${width}&count=${count}`;
    return this.apiService.get(url);
  }

  public recentlyViewed(width?, count?): Observable<any> {
    const url = `common/getHomepageRecentlyViewed&width=${width}&count=${count}`;
    return this.apiService.get(url);
  }

  public recentlyViewedViewAll(width?, sort?, order?, page?, limit?, startPrice?, endPrice?, deal?): Observable<any> {
    const url = `common/recentlyViewed&width=${width}&sort=${sort}&order=${order}&page=${page}&limit=${limit}&price_from=${startPrice}&price_to=${endPrice}&deal=${deal}`;
    return this.apiService.get(url);
  }

  public getProductById(productId, width?): Observable<any> {
    const url = `catalog/getProduct&product_id=${productId}&width=${width}`;
    return this.apiService.get(url);
  }

  public getProductsByCategory(categoryId, width?, sort?, order?, page?, limit?, startPrice?, endPrice?, deal?): Observable<any> {
    const url = `catalog/productCategory&path=${categoryId}&width=${width}&sort=${sort}&order=${order}&page=${page}&limit=${limit}&price_from=${startPrice}&price_to=${endPrice}&deal=${deal}`;
    return this.apiService.get(url);
  }

  public addProductToWishlist(productid): Observable<any> {
    const url = `catalog/addToWishlist`;
    const body = {product_id: productid};
    return this.apiService.post(url, body);
  }

  public getWishList(): Observable<any> {
    const url = `customer/getWishlist`;
    return this.apiService.get(url);
  }

  public removeProductFromWishlist(productid): Observable<any> {
    const url = `customer/removeFromWishlist&product_id=${productid}`;
    return this.apiService.delete(url);
  }

  public addProductToCart(productObj): Observable<any> {
    const url = `cart/addToCart`;
    return this.apiService.post(url, productObj);
  }

  public searchForProduct(productName, width?, page?): Observable<any> {
    const url = `catalog/productSearch&width=${width}&page=${page}`;
    const body = {
      search: productName,
      limit: environment.loadLimit
    };
    return this.apiService.post(url, body);
  }

  public searchSuggestForProduct(productName): Observable<any> {
    const url = `catalog/searchSuggest`;
    const body = {
      search: productName,
      limit: 3
    };
    return this.apiService.post(url, body);
  }

  public getUnavailableOrdersForUser(): Observable<any> {
    const url = `customer/getUnavailableItemOrders&limit=20`;
    return this.apiService.get(url);
  }

}
