<div class="address__popup--wrapper" *ngIf="!isMobile">
  <div class="scale-in-center">
    <div class="address__popup--container">

      <div class="address__popup--header" [ngClass]="isEditAddress && 'header_edit_address'">
        <img alt="close.svg" class="login__popup--close" src="/assets/images/global/close.svg"
             (click)="showAddress.emit(false)">
        <span class="title_address" [ngClass]="!isEditAddress && 'default_title_address'">{{title}}</span>
        <span *ngIf="isEditAddress" class="delete_address" (click)="deleteAddressBtn()">{{'delete' | translate}}</span>
      </div>

      <div *ngIf="!isShowForm">

        <div class="map-test">
          <agm-map
            [latitude]="lat"
            [longitude]="lng"
            [zoom]="zoom"
            [usePanning]="true"
            (centerChange)="centerChanged($event)"
            (mapReady)="mapReady($event)"
          >
            <agm-polygon [fillColor]="'transparent'" [strokeColor]="'gray'"
                         [strokeWeight]="1">
            </agm-polygon>

          </agm-map>
          <div class="map-center-overlay">
            <img alt="icon-marker" src="assets/images/global/marker.svg" width="40" height="40">
          </div>
        </div>
        <div class="address__popup--bottom">
          <button class="confirm-btn address-buttons"
                  [ngStyle]="{'background':isDeliverable ? 'var(--bg-green)' : '#f4f4f5' , 'color' : isDeliverable ? '#FFF' : '#999999' }"
                  (click)="onConfirmLocation()">{{'confirm' | translate}}
          </button>
        </div>
      </div>


      <form [formGroup]="addressForm" *ngIf="isShowForm" (ngSubmit)="onConfirmLocation()" class="form-container">
        <div class="required-fields" [dir]="translateService.instant('dir') === 'en' ? 'ltr' : 'rtl'">
          <mat-form-field appearance="outline" class="mat-field">
            <input matInput formControlName="address_name" placeholder="{{'address_name' | translate}}">
            <mat-error *ngIf="!addressForm.controls['address_name'].valid">
              {{'address_name_error' | translate}}
            </mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline" class="mat-field">
            <input matInput formControlName="country" placeholder="city name" readonly>
          </mat-form-field>


          <mat-form-field appearance="outline" class="mat-field">
            <input formControlName="city" matInput placeholder="{{'select_area' | translate}}" readonly>
            <mat-error *ngIf="!addressForm.controls['city'].valid">
              {{'area_name_error' | translate}}
            </mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline" class="mat-field">
            <input matInput formControlName="street" placeholder="{{'street_name' | translate}}">
            <mat-error *ngIf="!addressForm.controls['street'].valid">
              {{'street_name_error' | translate}}
            </mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline" class="mat-field">
            <input matInput formControlName="building_number" placeholder=" {{'bldg_number' | translate}}">
            <mat-error *ngIf="!addressForm.controls['building_number'].valid">
              {{'bldg_number_error' | translate}}
            </mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline" class="mat-field">
            <input matInput formControlName="floor_number" placeholder=" {{'floor_number' | translate}}">
            <mat-error *ngIf="!addressForm.controls['floor_number'].valid">
              {{'floor_number_error' | translate}}
            </mat-error>
          </mat-form-field>


          <mat-form-field appearance="outline" floatLabel="never" class="mat-field">
            <input type="number" matInput formControlName="phone_number"
                   placeholder="{{'default_mobile_number' | translate}}">
            <mat-error *ngIf="!addressForm.controls['phone_number'].valid">
              {{'default_mobile_number_error' | translate}}
            </mat-error>

          </mat-form-field>
        </div>


        <div class="mat-form-field-buttons-container">
          <span>{{'chooseTypeAddress' | translate}}</span>
          <div class="mat-form-field-buttons">
            <input type="button" value="{{'home' | translate}}"
                   [class]=" this.selectedType == 1 ? 'outer-green-btn' : 'normal-type' "
                   (click)="onToggleAddressTypes( 1)"/>
            <input type="button" value="{{'work' | translate}}"
                   [class]=" this.selectedType == 2 ? 'outer-green-btn' : 'normal-type' "
                   (click)="onToggleAddressTypes(2)"/>
            <input type="button" value="{{'other' | translate}}"
                   [class]=" this.selectedType == 3 ? 'outer-green-btn ' : 'normal-type ' "
                   (click)="onToggleAddressTypes(3)"/>
          </div>

          <mat-checkbox formControlName="default_address"><span
            class="mat-check-box-text">{{'defaultAddress' | translate}}</span></mat-checkbox>
        </div>

      </form>
      <div class="address__popup--bottom" *ngIf="isShowForm">
        <button class="view-map-btn address-buttons"
                (click)="viewMap()">{{isEditAddress ? ('edit_map' |translate) : ('viewMap' | translate)}}
        </button>
        <button class="confirm-btn address-buttons"
                [ngStyle]="{'background':isValidForm ? 'var(--bg-green)' : '#f4f4f5' , 'color':isValidForm ? '#FFF' : '#a1a1a1' }"
                (click)="submitAddress()"> {{isEditAddress ? ('save' | translate) : ('add_address' | translate)}}
        </button>

      </div>
    </div>
  </div>
</div>

<!--------------------------------------------------------mobile template below ------------------------------------------>
<div class="address__popup--wrapper-mobile" *ngIf="isMobile" [ngStyle]="{'height': '100vh - ' + navbarHeight + 'px'}">

  <div class="address__popup--header-mobile">
    <img alt="icon"
         [src]="isShowForm && !isEditAddress ? '/assets/images/global/arrow-back.svg' : '/assets/images/global/close.svg'"
         (click)="isShowForm && !isEditAddress ? viewMap() : showAddress.emit(false)"
         [ngStyle]="{'transform': isRTL && 'rotate(180deg)'  }"/>

    <span>{{title}}</span>

  </div>


  <div class="map-cont" *ngIf="!isShowForm">
    <agm-map
      [latitude]="lat"
      [longitude]="lng"
      [zoom]="zoom"
      [usePanning]="true"
      (centerChange)="centerChanged($event)"
      (mapReady)="mapReady($event)"
      gestureHandling="greedy"
      class="agm-map-mobile"
    >
      <agm-polygon [fillColor]="'transparent'" [strokeColor]="'gray'"
                   [strokeWeight]="1">
      </agm-polygon>
    </agm-map>
    <div class="map-center-overlay">
      <img alt="icon-marker" src="assets/images/global/marker.svg" width="40" height="40">
    </div>
  </div>

  <div>
    <agm-map *ngIf="isShowForm" [latitude]="lat" [longitude]="lng" [scrollwheel]="false" [mapDraggable]="false"
             [draggable]="false"
             [disableDefaultUI]="true"
             [zoom]="zoom-1"
             class="agm-map-mobile-static"
             (click)="viewMap()">
      <agm-marker [latitude]="lat" [longitude]="lng" [iconUrl]="'assets/images/global/marker.svg'"
                  (markerClick)="viewMap()"
      ></agm-marker>
    </agm-map>
  </div>


  <form [formGroup]="addressForm" *ngIf="isShowForm" (ngSubmit)="onConfirmLocation()" class="form-container-mobile">
    <div class="required-fields-mobile" [dir]="translateService.instant('dir') === 'en' ? 'ltr' : 'rtl'">
      <mat-form-field appearance="outline" class="mat-field-mobile">
        <input matInput formControlName="address_name"
               placeholder="{{'address_name' | translate}}">
        <mat-error *ngIf="!addressForm.controls['address_name'].valid">
          {{'address_name_error' | translate}}
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline" class="mat-field-mobile">
        <input matInput formControlName="country" placeholder="city name" readonly>
      </mat-form-field>


      <mat-form-field appearance="outline" class="mat-field-mobile">
        <input formControlName="city" matInput placeholder="{{'select_area' | translate}}" readonly>
        <mat-error *ngIf="!addressForm.controls['city'].valid">
          {{'area_name_error' | translate}}
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline" class="mat-field-mobile">
        <input matInput formControlName="street" placeholder="{{'street_name' | translate}}">
        <mat-error *ngIf="!addressForm.controls['street'].valid">
          {{'street_name_error' | translate}}
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline" class="mat-field-mobile">
        <input matInput formControlName="building_number" placeholder=" {{'bldg_number' | translate}}">
        <mat-error *ngIf="!addressForm.controls['building_number'].valid">
          {{'bldg_number_error' | translate}}
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline" class="mat-field-mobile">
        <input matInput formControlName="floor_number" placeholder=" {{'floor_number' | translate}}">
        <mat-error *ngIf="!addressForm.controls['floor_number'].valid">
          {{'floor_number_error' | translate}}
        </mat-error>
      </mat-form-field>


      <mat-form-field appearance="outline" floatLabel="never" class="mat-field-mobile">
        <input type="text" pattern="\d*" matInput formControlName="phone_number"
               placeholder="{{'default_mobile_number' | translate}}">
        <mat-error *ngIf="!addressForm.controls['phone_number'].valid">
          {{'default_mobile_number_error' | translate}}
        </mat-error>

      </mat-form-field>
    </div>


    <div class="mat-form-field-buttons-container-mobile">
      <span class="choose-address-types-mobile"> {{'chooseTypeAddress' | translate}}</span>
      <div class="mat-form-field-buttons-mobile">
        <input type="button" value="{{'home' | translate}}"
               [class]=" this.selectedType == 1 ? 'outer-green-btn' : 'normal-type' "
               (click)="onToggleAddressTypes( 1)"/>
        <input type="button" value="{{'work' | translate}}"
               [class]=" this.selectedType == 2 ? 'outer-green-btn' : 'normal-type ' "
               (click)="onToggleAddressTypes(2)"/>
        <input type="button" value="{{'other' | translate}}"
               [class]=" this.selectedType == 3 ? 'outer-green-btn' : 'normal-type' "
               (click)="onToggleAddressTypes(3)"/>
      </div>

      <div class="mat-check-mobile">
        <span
          class="mat-check-box-text">{{'defaultAddress' | translate}}</span>
        <mat-checkbox class="example-margin" formControlName="default_address"></mat-checkbox>
      </div>
    </div>
  </form>

  <button class="confirm-btn-mobile" *ngIf="!isShowForm"
          [ngStyle]="{'background':isDeliverable ? 'var(--bg-green)' : '#f4f4f5' , 'color' : isDeliverable ? '#FFF' : '#999999' , 'right' : 0 , left :'0' }"
          (click)="onConfirmLocation()">{{'confirm' | translate}}
  </button>


  <button class="confirm-btn-mobile" *ngIf="isShowForm"
          [ngStyle]="{'background':isValidForm ? 'var(--bg-green)' : '#f4f4f5' ,'color':isValidForm ? '#FFF' : '#a1a1a1' , 'right' : isEditAddress ? '50px' : '0' , 'left' : !isEditAddress && '0' , 'width' :  isEditAddress && '35%'}"
          (click)="submitAddress()"> {{isEditAddress ? ('save' | translate) : ('add_address' | translate)}}
  </button>

  <button class="delete-address-btn-mobile" *ngIf="isEditAddress && isShowForm"
          [ngStyle]="{'left' : isEditAddress ? '50px' : '0' ,'width' : isEditAddress && '35%' }"
          (click)="deleteAddressBtn()"> {{isEditAddress ? ('delete' | translate) : ('add_address' | translate)}}
  </button>


</div>
