<div class="fade-in" [ngClass]="translate.instant('dir')">
  <app-header [cartObjCount]="cartCount" *ngIf="!router.url.includes('/terms-of-use-mobile') && !router.url.includes('/privacy-policy-mobile') && !router.url.includes('/contact-us-mobile')"></app-header>
  <app-smart-banner (closeBannerOutput)="showSmartBanner = !showSmartBanner" *ngIf="isMobile && showSmartBanner"></app-smart-banner>
  <!--<app-order-status></app-order-status>-->
  <router-outlet (activate)="getUserData(); setLoading()"></router-outlet>
  <app-cookie-banner *ngIf="router.url !== '/terms-of-use-mobile' && router.url !== '/privacy-policy-mobile'" [showCookie]="showCookie"></app-cookie-banner>
  <app-footer *ngIf="!isMobile && !router.url.includes('/terms-of-use-mobile') && !router.url.includes('/privacy-policy-mobile') && !router.url.includes('/contact-us-mobile')"></app-footer>
  <app-mobile-menu *ngIf="isMobile && !router.url.includes('/terms-of-use-mobile') && !router.url.includes('/privacy-policy-mobile') && !router.url.includes('/contact-us-mobile')"></app-mobile-menu>
  <app-loading></app-loading>
  <app-login *ngIf="showLogin" [showLogin]="showLogin" (closeLogin)="showLogin = false"></app-login>
</div>
