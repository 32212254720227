<div>
  <h2>{{'manage_cards' | translate}}</h2>

  <div *ngFor="let card of savedCards" class="one_card d-flex">
    <div>
      <div class="holder">
        {{card.card_data.holder}}
      </div>

      <div class="gray digits mt-2">
        **********{{card.card_data.last4Digits}}
      </div>
      <div class="gray">
        {{card.card_data.expiryMonth}}/{{card.card_data.expiryYear}}
      </div>
    </div>

    <img *ngIf="!openCheckPopup" alt="delete_card" src="/assets/images/global/delete.svg"
         (click)="openCheckPopup=true"/>

    <app-modal *ngIf="openCheckPopup" (isModalClose)="openCheckPopup=$event">
      <div class="wrapper_popup">
        <div class="title_popup">
          {{'delete_card' | translate}}
        </div>

        <div class="dec_card ">
          <div> {{'confirm_delete_card' | translate}}
            **********{{card.card_data.last4Digits}}
            {{getCurrentLang() === 'en' ? '?' : '؟'}}
          </div>
        </div>

        <div class="delete_btn" (click)="deleteCard(card.registration_id)">
          {{'delete' | translate}}
        </div>

        <div class="cancel" (click)="openCheckPopup=false">
          {{'cancel' | translate}}
        </div>
      </div>

    </app-modal>
  </div>
</div>


