import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CartService {

  constructor(private apiService: ApiService) {}

  public getCartItems(width): Observable<any> {
    const url = `cart/viewCart&width=${width}`;
    return this.apiService.get(url);
  }


  public removeProductFromCart(productid): Observable<any> {
    const url = `cart/removeFromCart&key=${productid}`;
    return this.apiService.delete(url);
  }

  public clearCart(): Observable<any> {
    const url = `cart/clearCart`;
    return this.apiService.get(url);
  }

  public updateQuantityInCart(quantityObj): Observable<any> {
    const url = `cart/updateCart`;
    return this.apiService.put(url, quantityObj);
  }

  public removeCoupon(coupon): Observable<any> {
    const url = `cart/removeCoupon`;
    return this.apiService.post(url, coupon);
  }
  public applyCoupon(coupon): Observable<any> {
    const url = `cart/applyCoupon`;
    return this.apiService.post(url, coupon);
  }
}
