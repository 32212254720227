<div class="category__page--container page-min-height fade-in container__max-width header-margin-top" *ngIf="categoryArr">
  <div class="container-fluid custom-padding">
    <div class="row">
      <div class="col-md-3 col-6" *ngFor="let category of categoryArr">
        <div class="category__page--single">
          <app-single-category [categoryData]="category" [routerLink]="['/', getCurrentLang() ,'sub-category' , category.path, category.name]"></app-single-category>
        </div>
      </div>
    </div>
  </div>
</div>
