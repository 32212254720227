<div class="home__page--container fade-in container__max-width header-margin-top-home mobile_menu--padding">
  <div class="home-margin-x home-slider-container">
    <app-home-slider [slides]="homePageSlides"></app-home-slider>
  </div>
  <div class="home-margin-x global__page--padding" *ngIf="hotDeals?.products?.length > 0">
    <app-product-hor-listing [productArr]="hotDeals?.products" [deals]="true" [link]="'0'"
                             [title]="hotDeals?.name"></app-product-hor-listing>
  </div>
  <div class="home-margin-x global__page--padding">
    <app-category-hor-listing [categoryArr]="topCategoryList"></app-category-hor-listing>
  </div>
  <div class="home-margin-x global__page--padding" *ngIf="featuredProducts?.products?.length > 0">
    <app-product-hor-listing [productArr]="featuredProducts?.products" [link]="'featured'"
                             [title]="featuredProducts?.name"></app-product-hor-listing>
  </div>
  <div class="home-margin-x global__page--padding" *ngIf="recentlyViewed?.products?.length > 0">
    <app-product-hor-listing [productArr]="recentlyViewed?.products" [link]="'recent'"
                             [title]="translate.instant('recently_viewed_title')"></app-product-hor-listing>
  </div>
  <div class="home-margin-x global__page--padding" *ngIf="homePageAds">
    <app-home-slider [slides]="homePageAds" [isAds]="true"></app-home-slider>
  </div>
  <div class="home-margin-x global__page--padding" *ngIf="latestProducts?.products?.length > 0">
    <app-product-hor-listing [productArr]="latestProducts?.products" [link]="'latest'"
                             [title]="latestProducts?.name"></app-product-hor-listing>
  </div>
  <app-modal *ngIf="!closePopup && popupAds && popupShown === 'false' " (isModalClose)="closePopup=$event">
    <div class="wrapper_offer_popup" (click)="onAdsClick()">
      <div class="title_and_close">
        <span>{{popupAds?.title}}</span>
        <img class="modal__popup--close"
             src="/assets/images/global/close.svg" (click)="closePopup=true;onClosePopup()">
      </div>
      <div class="border"></div>
      <img class="img_offer_popup" alt="{{popupAds?.title}}" src="{{popupAds?.image}}"/>
    </div>
  </app-modal>
</div>
