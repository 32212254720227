<div class="footer__container">
  <p class="footer__cc--text do">
    2020 ® Yaser Mall
  </p>
  <div class="footer__nav--container">
    <a class="footer__nav&#45;&#45;item" [routerLink]="'/' + RouteEnum[getCurrentLang()].Contact">{{'menu_contact' | translate}}</a>
    <a class="footer__nav&#45;&#45;item">{{'menu_about' | translate}}</a>
    <!--<a class="footer__nav&#45;&#45;item">Location</a>-->
    <a class="footer__nav--item" [routerLink]="'/' + RouteEnum[getCurrentLang()].Privacy">{{'privacy_policy' | translate}}</a>
    <a class="footer__nav--item" [routerLink]="'/' + RouteEnum[getCurrentLang()].Terms">{{'terms' | translate}}</a>
    <!--<a class="footer__nav&#45;&#45;item">Rate Us</a>-->
    <p class="footer__cc--text mo">
      2020 ® Yaser Mall
    </p>
  </div>
</div>
