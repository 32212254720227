<div
  class="product__page--container page-min-height mobile_menu--padding fade-in container__max-width header-margin-top">
  <div class="container-fluid" *ngIf="isSingleProduct">
    <div class="row">
      <div class="col-md-6">
        <div class="product__slider--container">
          <div class="discount__banner" *ngIf="productData?.discount_rate">
            {{productData?.discount_rate}}%
          </div>
          <div class="slider__container" *ngIf="slideConfig && productData?.images">
            <ngx-slick-carousel [config]="slideConfig" dir="{{'html_dir' | translate}}">
              <div ngxSlickItem *ngFor="let img of productData?.images" class="slide">
                <img onError="this.src='/assets/images/global/no-img.png'" class="product__image--slide" width="75%" src="{{img.popup}}">
              </div>
            </ngx-slick-carousel>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="product__desc--container">
          <p class="product__title">
            {{productData?.name}}
          </p>
          <p class="brand__name">
            {{productData?.manufacturer}}
          </p>
          <p class="product__description--title" *ngIf="productData?.description">
            {{'description' | translate}}
          </p>
          <p class="product__description--text" *ngIf="productData?.description">
            {{productData?.description}}
          </p>
          <div class="product__price--container">
            <p class="product__price--title">
              {{'price' | translate}}
            </p>
            <span class="normal__old--price" [class.product__old--price]="productData?.special">
              {{productData?.price}} {{'currency' | translate}} / <span
              *ngIf="!productData?.weight.isQuantity">{{productData?.weight?.unit}}</span><span
              *ngIf="productData?.weight.isQuantity">{{'piece' | translate}}</span>
            </span>
            <span class="product__discount--price" *ngIf="productData?.special">
              {{productData?.special}} {{'currency' | translate}}
            </span>
          </div>
          <div class="product__action--container">
            <button class="product__add--btn" [disabled]="!productData?.stock"
                    [class.add__cart--disabled]="!productData?.stock" (click)="checkIfProductHasOptions()">
              <span *ngIf="productData?.stock">{{'add_to_cart' | translate}}</span>
              <span *ngIf="!productData?.stock">{{'out_of_stock' | translate}}</span>
            </button>
            <span class="product__page--icon">
              <i *ngIf="productData?.wishlist_status === false" (click)="addProductToWishList(productData?.product_id)"
                 class="fa fa-heart-o" aria-hidden="true"></i>
              <i *ngIf="productData?.wishlist_status === true"
                 (click)="removeProductFromWishList(productData?.product_id)" class="fa fa-heart"
                 aria-hidden="true"></i>
            </span>
            <span class="product__page--icon">
              <i class="fa fa-share-alt"
                 [cdkCopyToClipboard]="'https://www.yasermallonline.com/product/' + productData?.product_id"
                 aria-hidden="true" (cdkCopyToClipboardCopied)="snackBar.open(translate.instant('copied_link'),
                 translate.instant('snack_bar_close'),{duration: 3500,panelClass: this.translate.instant('dir')})"></i>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="productData?.relatedProducts.length > 1">
      <div class="col-md-12">
        <div class="home-margin-x global__page--padding">
          <app-product-hor-listing [productArr]="productData?.relatedProducts"
                                   [title]="'Related Items'"></app-product-hor-listing>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="!isSingleProduct && searchedProductList"
       infiniteScroll
       [infiniteScrollDistance]="7"
       [infiniteScrollThrottle]="50"
       (scrolled)="loadMoreProducts()">
    <p class="cart__cont--text">{{searchedProductList?.heading_title}} {{searchedProductList?.product_total}} {{'items' | translate}}</p>
    <div class="single__product--holder" *ngFor="let product of searchedProductListArr">
      <app-single-product [productData]="product"></app-single-product>
    </div>
    <app-load-more-indicator *ngIf="showLoading"></app-load-more-indicator>
    <app-empty-page *ngIf="searchedProductListArr.length === 0 || searchedProductList?.product_total === '0'"
                    [pageTitle]="translate.instant('search_list')"></app-empty-page>
  </div>
</div>
<app-add-to-cart *ngIf="showAddToCartModal" (toggleModal)="showAddToCartModal = $event"
                 [productData]="productData"></app-add-to-cart>
