<div class="static__page--container fade-in container__max-width mobile_menu--padding" [class.header-margin-top]="!isMobileWebView">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <div class="en" *ngIf="translateService.instant('dir') === 'en'">
          <p class="static__page--title">TERMS AND CONDITIONS</p>
          This website is operated by Yaser ,mall&bros. Throughout the site, the terms "we", "us" and "our" refer to
          Yaser ,mall&bros. Yaser ,mall&bros offers this website, including all information, tools and services
          available from this site to you, the user, conditioned upon your acceptance of all terms, conditions, policies
          and notices stated here.
          By visiting our site and/ or purchasing something from us, you engage in our "Service" and agree to be bound
          by the following terms and conditions ("Terms of Service", "Terms"), including those additional terms and
          conditions and policies referenced herein and/or available by hyperlink. These Terms of Service apply to all
          users of the site, including without limitation users who are browsers, vendors, customers, merchants, and/ or
          contributors of content.
          Please read these Terms of Service carefully before accessing or using our website. By accessing or using any
          part of the site, you agree to be bound by these Terms of Service. If you do not agree to all the terms and
          conditions of this agreement, then you may not access the website or use any services. If these Terms of
          Service are considered an offer, acceptance is expressly limited to these Terms of Service.
          Any new features or tools which are added to the Yaser ,mall&bros also be subject to the Terms of Service. You
          can review the most current version of the Terms of Service at any time on this page. We reserve the right to
          update, change or replace any part of these Terms of Service by posting updates and/or changes to our website.
          It is your responsibility to check this page periodically for changes. Your continued use of or access to the
          website following the posting of any changes constitutes acceptance of those changes.

          <p class="static__page--title">SECTION 1 - ONLINE STORE TERMS</p>
          By agreeing to these Terms of Service, you represent that you are at least the age of majority in your state
          or province of residence, or that you are the age of majority in your state or province of residence and you
          have given us your consent to allow any of your minor dependents to use this site. You may not use our
          products for any illegal or unauthorized purpose nor may you, in the use of the Service, violate any laws in
          your jurisdiction (including but not limited to copyright laws). You must not transmit any worms or viruses or
          any code of a destructive nature. A breach or violation of any of the Terms will result in an immediate
          termination of your Services.

          <p class="static__page--title">SECTION 2 - GENERAL CONDITIONS</p>

          We reserve the right to refuse service to anyone for any reason at any time. You understand that your content
          (not including credit card information), may be transferred unencrypted and involve: (a) transmissions over
          various networks (b) changes to conform and adapt to technical requirements of connecting networks or devices.
          Credit card information is always encrypted during transfer over networks. You agree not to reproduce,
          duplicate, copy, sell, resell or exploit any portion of the Service, use of the Service, or access to the
          Service or any contact on the website through which the service is provided, without express written
          permission by us. The headings used in this agreement are included for convenience only and will not limit or
          otherwise affect these Terms.

          <p class="static__page--title">SECTION 3 - ACCURACY, COMPLETENESS AND TIMELINESS OF INFORMATION</p>

          We are not responsible if information made available on this site is not accurate, complete or current. The
          material on this site is provided for general information only and should not be relied upon or used as the
          sole basis for making decisions without consulting primary, more accurate, more complete or more timely
          sources of information. Any reliance on the material on this site is at your own risk.
          This site may contain certain historical information. Historical information, necessarily, is not current and
          is provided for your reference only. We reserve the right to modify the contents of this site at any time, but
          we have no obligation to update any information on our site. You agree that it is your responsibility to
          monitor changes to our site.

          <p class="static__page--title">SECTION 4 - MODIFICATIONS TO THE SERVICE AND PRICES</p>

          Prices for our products are subject to change without notice. We reserve the right at any time to modify or
          discontinue the Service (or any part or content thereof) without notice at any time. We shall not be liable to
          you or to any third-party for any modification, price change, suspension or discontinuance of the Service.

          <p class="static__page--title">SECTION 5 - ACCURACY OF BILLING AND ACCOUNT INFORMATION</p>

          We reserve the right to refuse any order you place with us. We may, in our sole discretion, limit or cancel
          quantities purchased per person, per account or per order. These restrictions may include orders placed by or
          under the same customer account, the same credit card, and/or orders that use the same billing and/or shipping
          address. In the event that we make a change to or cancel an order, we will attempt to notify you by contacting
          the e-mail and/or shipping address/phone number provided at the time the order was made. We reserve the right
          to limit or prohibit orders that, in our sole judgment, appear to be placed by dealers, resellers or
          distributors.
          You agree to provide current, complete and accurate purchase and account information for all purchases made at
          our store. You agree to promptly update your account and other information, including your email address and
          credit card numbers and shipping address, so that we can complete your transactions and contact you as needed.
          For more detail, please review our Returns Policy.

          <p class="static__page--title">SECTION 6 - OPTIONAL TOOLS</p>

          We may provide you with access to third-party tools over which we neither monitor nor have any control nor
          input.
          You acknowledge and agree that we provide access to such tools "as is" and "as available" without any
          warranties, representations or conditions of any kind and without any endorsement. We shall have no liability
          whatsoever arising from or relating to your use of optional third-party tools.
          Any use by you of optional tools offered through the site is entirely at your own risk and discretion and you
          should ensure that you are familiar with and approve of the terms on which tools are provided by the relevant
          third-party provider(s). We may also, in the future, offer new services and/or features through the website
          (including, the release of new tools and resources). Such new features and/or services shall also be subject
          to these Terms of Service.

          <p class="static__page--title">SECTION 7 - THIRD-PARTY LINKS</p>

          Certain content, products and services available via our Service may include materials from third-parties.
          Third-party links on this site may direct you to third-party websites that are not affiliated with us. We are
          not responsible for examining or evaluating the content or accuracy and we do not warrant and will not have
          any liability or responsibility for any third-party materials or websites, or for any other materials,
          products, or services of third-parties.
          We are not liable for any harm or damages related to the purchase or use of goods, services, resources,
          content, or any other transactions made in connection with any third-party websites. Please review carefully
          the third-party's policies and practices and make sure you understand them before you engage in any
          transaction. Complaints, claims, concerns, or questions regarding third-party products should be directed to
          the third-party.
          SECTION 8 - USER COMMENTS, FEEDBACK, AND OTHER SUBMISSIONS
          If, at our request, you send certain specific submissions (for example contest entries and social media) or
          without a request from us you send creative ideas, suggestions, proposals, plans, or other materials, whether
          online, by email, or otherwise (collectively, 'comments'), you agree that we may, at any time, without
          restriction, edit, copy, publish, distribute, translate and otherwise use in any medium any comments that you
          forward to us. We are and shall be under no obligation:
          • (1) to maintain any comments in confidence
          • (2) to pay compensation for any comments
          • (3) To respond to any comments.
          We may, but have no obligation to, monitor, edit or remove content that we determine in our sole discretion
          are unlawful, offensive, threatening, libelous, defamatory, pornographic, obscene or otherwise objectionable
          or violates any party’s intellectual property or these Terms of Service.
          You agree that your comments will not violate any right of any third-party, including copyright, trademark,
          privacy, personality or other personal or proprietary right. You further agree that your comments will not
          contain libelous or otherwise unlawful, abusive or obscene material, or contain any computer virus or other
          malware that could in any way affect the operation of the Service or any related website. You may not use a
          false e-mail address, pretend to be someone other than yourself, or otherwise mislead us or third-parties as
          to the origin of any comments. You are solely responsible for any comments you make and their accuracy. We
          take no responsibility and assume no liability for any comments posted by you or any third-party.

          <p class="static__page--title">SECTION 9 - PERSONAL INFORMATION</p>

          Your submission of personal information through the store is governed by our Privacy Policy.
          SECTION 10 - ERRORS, INACCURACIES AND OMISSIONS
          Occasionally there may be information on our site or in the Service that contains typographical errors,
          inaccuracies or omissions that may relate to product descriptions, pricing, promotions, offers, transit times
          and availability. We reserve the right to correct any errors, inaccuracies or omissions, and to change or
          update information or cancel orders if any information in the Service or on any related page is inaccurate at
          any time without prior notice (including after you have submitted your order).
          We undertake no obligation to update, amend or clarify information in the Service or on any related website,
          including without limitation, pricing information, except as required by law. No specified update or refresh
          date applied in the Service or on any related website, should be taken to indicate that all information in the
          Service or on any related website has been modified or updated.

          <p class="static__page--title">SECTION 11 - PROHIBITED USES</p>

          In addition to other prohibitions as set forth in the Terms of Service, you are prohibited from using the site
          or its content:
          • (a) For any unlawful purpose
          • (b) To solicit others to perform or participate in any unlawful acts
          • (c) To violate any international, local rules, laws, or ordinances
          • (d) To infringe upon or violate our intellectual property rights or the intellectual property rights of
          others
          • (e) to harass, abuse, insult, harm, defame, slander, disparage, intimidate, or discriminate based on gender,
          sexual orientation, religion, ethnicity, race, age, national origin, or disability
          • (f) To submit false or misleading information
          • (g) To upload or transmit viruses or any other type of malicious code that will or may be used in any way
          that will affect the functionality or operation of the Service or of any related website, other websites, or
          the Internet
          • (h) To collect or track the personal information of others
          • (i) To spam, phish, pharm, pretext, spider, crawl, or scrape
          • (j) For any obscene or immoral purpose
          • (k) To interfere with or circumvent the security features of the Service or any related website, other
          websites, or the Internet
          We reserve the right to terminate your use of the Service or any related website for violating any of the
          prohibited uses.

          <p class="static__page--title">SECTION 12 - DISCLAIMER OF WARRANTIES; LIMITATION OF LIABILITY</p>
          We do not guarantee, represent or warrant that your use of our service will be uninterrupted, timely, secure
          or error-free. We do not warrant that the results that may be obtained from the use of the service will be
          accurate or reliable.
          You agree that from time to time we may remove the service for indefinite periods of time or cancel the
          service at any time, without notice to you. You expressly agree that your use of, or inability to use, the
          service is at your sole risk. The service and all products and services delivered to you through the service
          are (except as expressly stated by us) provided 'as is' and 'as available' for your use, without any
          representation, warranties or conditions of any kind, either express or implied, including all implied
          warranties or conditions of merchantability, merchantable quality, fitness for a particular purpose,
          durability, title, and non-infringement.
          In no case shall Yaser ,mall&bros, our directors, officers, employees, affiliates, agents, contractors,
          interns, suppliers, service providers or licensors be liable for any injury, loss, claim, or any direct,
          indirect, incidental, punitive, special, or consequential damages of any kind, including, without limitation
          lost profits, lost revenue, lost savings, loss of data, replacement costs, or any similar damages, whether
          based in contract, tort (including negligence), strict liability or otherwise, arising from your use of any of
          the service or any products procured using the service, or for any other claim related in any way to your use
          of the service or any product, including, but not limited to, any errors or omissions in any content, or any
          loss or damage of any kind incurred as a result of the use of the service or any content (or product) posted,
          transmitted, or otherwise made available via the service, even if advised of their possibility. Because some
          countries or jurisdictions do not allow the exclusion or the limitation of liability for consequential or
          incidental damages, in such countries or jurisdictions, our liability shall be limited to the maximum extent
          permitted by law.

          <p class="static__page--title">SECTION 13 - INDEMNIFICATION</p>
          You agree to indemnify, defend and hold harmless Yaser ,mall&bros and our parent, subsidiaries, affiliates,
          partners, officers, directors, agents, contractors, licensors, service providers, subcontractors, suppliers,
          interns and employees, harmless from any claim or demand, including reasonable attorneys’ fees, made by any
          third-party due to or arising out of your breach of these Terms of Service or the documents they incorporate
          by reference, or your violation of any law or the rights of a third-party.

          <p class="static__page--title">SECTION 14 - SEVERABILITY</p>
          In the event that any provision of these Terms of Service is determined to be unlawful, void or unenforceable,
          such provision shall nonetheless be enforceable to the fullest extent permitted by applicable law, and the
          unenforceable portion shall be deemed to be severed from these Terms of Service, such determination shall not
          affect the validity and enforceability of any other remaining provisions.

          <p class="static__page--title">SECTION 15 - TERMINATION</p>
          The obligations and liabilities of the parties incurred prior to the termination date shall survive the
          termination of this agreement for all purposes.
          These Terms of Service are effective unless and until terminated by either you or us. You may terminate these
          Terms of Service at any time by notifying us that you no longer wish to use our Services, or when you cease
          using our site.
          If in our sole judgment you fail, or we suspect that you have failed, to comply with any term or provision of
          these Terms of Service, we also may terminate this agreement at any time without notice and you will remain
          liable for all amounts due up to and including the date of termination; and/or accordingly may deny you access
          to our Services (or any part thereof).

          <p class="static__page--title">SECTION 16 - ENTIRE AGREEMENT</p>
          The failure of us to exercise or enforce any right or provision of these Terms of Service shall not constitute
          a waiver of such right or provision.
          These Terms of Service and any policies or operating rules posted by us on this site or in respect to The
          Service constitutes the entire agreement and understanding between you and us and govern your use of the
          Service, superseding any prior or contemporaneous agreements, communications and proposals, whether oral or
          written, between you and us (including, but not limited to, any prior versions of the Terms of Service).
          Any ambiguities in the interpretation of these Terms of Service shall not be construed against the drafting
          party.

          <p class="static__page--title">SECTION 17 - GOVERNING LAW</p>
          These Terms of Service and any separate agreements whereby we provide you Services shall be governed by and
          construed in accordance with the laws of your country.

          <p class="static__page--title">SECTION 18 - CHANGES TO TERMS OF SERVICE</p>
          You can review the most current version of the Terms of Service at any time at this page.
          We reserve the right, at our sole discretion, to update, change or replace any part of these Terms of Service
          by posting updates and changes to our website. It is your responsibility to check our website periodically for
          changes. Your continued use of or access to our website or the Service following the posting of any changes to
          these Terms of Service constitutes acceptance of those changes.

          <p class="static__page--title">SECTION 19 - CONTACT INFORMATION</p>
          Questions about the Terms of Service should be sent to us at
          yasermall@live.com


        </div>
        <div class="ar" *ngIf="translateService.instant('dir') === 'ar'">

          <p class="static__page--title">الأحكام والشروط</p>

          يتم تشغيل هذا الموقع من قبل Yaser ,mall&bros في جميع أنحاء الموقع ، تشير مصطلحات "نحن" و "نحن" و "خاصتنا" إلى
          ياسر مول واخوانه. تقدم Yaser ,mall&bros هذا الموقع ، بما في ذلك جميع المعلومات والأدوات والخدمات المتاحة لك ،
          كمستخدم ، من هذا الموقع ، بشرط موافقتك على جميع الشروط والأحكام والسياسات والإشعارات المذكورة هنا.

          من خلال زيارة موقعنا و / أو شراء شيء منا ، فإنك تشارك في "الخدمة" الخاصة بنا وتوافق على الالتزام بالشروط
          والأحكام التالية ("شروط الخدمة" ، "الشروط") ، بما في ذلك تلك الشروط والأحكام والسياسات الإضافية المشار إليه
          هنا و / أو متاح من خلال ارتباط تشعبي. تنطبق شروط الخدمة هذه على جميع مستخدمي الموقع ، بما في ذلك على سبيل
          المثال لا الحصر المستخدمين من المتصفحات و / أو البائعين والعملاء والتجار و / أو المساهمين في المحتوى.

          يرجى قراءة شروط الخدمة هذه بعناية قبل الوصول إلى موقعنا الإلكتروني أو استخدامه. من خلال الوصول إلى أو استخدام
          أي جزء من الموقع ، فإنك توافق على الالتزام بشروط الخدمة هذه. إذا كنت لا توافق على جميع شروط وأحكام هذه
          الاتفاقية ، فلا يجوز لك الوصول إلى موقع الويب أو استخدام أي خدمات. إذا كانت شروط الخدمة هذه بمثابة عرض ، فإن
          القبول يقتصر صراحةً على شروط الخدمة هذه.
          تخضع أيضًا أي ميزات أو أدوات جديدة تتم إضافتها إلى Yaser ,mall&bros لشروط الخدمة. يمكنك مراجعة أحدث إصدار من
          شروط الخدمة في أي وقت على هذه الصفحة. نحتفظ بالحق في تحديث أو تغيير أو استبدال أي جزء من شروط الخدمة هذه عن
          طريق نشر التحديثات و / أو التغييرات على موقعنا. تقع على عاتقك مسؤولية مراجعة هذه الصفحة بشكل دوري لمعرفة
          التغييرات. استمرار استخدامك أو الوصول إلى موقع الويب بعد نشر أي تغييرات يشكل قبولًا لهذه التغييرات

          <p class="static__page--title">القسم 1 - شروط المتجر عبر الإنترنت</p>

          بالموافقة على شروط الخدمة هذه ، فإنك تقر بأنك على الأقل تبلغ سن الرشد في ولايتك أو إقليم إقامتك ، أو أنك تبلغ
          سن الرشد في ولايتك أو إقليم إقامتك وأنك منحتنا موافقتك على السماح لأي من المعالين القصر لاستخدام هذا الموقع.
          لا يجوز لك استخدام منتجاتنا لأي غرض غير قانوني أو غير مصرح به ولا يجوز لك ، في استخدام الخدمة ، انتهاك أي
          قوانين في ولايتك القضائية (بما في ذلك على سبيل المثال لا الحصر قوانين حقوق النشر). يجب ألا تنقل أي فيروسات
          متنقلة أو فيروسات أو أي رمز ذي طبيعة مدمرة. سيؤدي خرق أو انتهاك أي من الشروط إلى الإنهاء الفوري لخدماتك.

          <p class="static__page--title">القسم 2 - الشروط العامة</p>

          نحتفظ بالحق في رفض تقديم الخدمة لأي شخص لأي سبب وفي أي وقت. أنت تدرك أن المحتوى الخاص بك (لا يشمل معلومات
          بطاقة الائتمان) ، قد يتم نقله بدون تشفير ويتضمن: (أ) عمليات نقل عبر شبكات مختلفة (ب) تغييرات للتوافق والتكيف
          مع المتطلبات الفنية لتوصيل الشبكات أو الأجهزة.

          يتم دائمًا تشفير معلومات بطاقة الائتمان أثناء النقل عبر الشبكات. أنت توافق على عدم إعادة إنتاج أو تكرار أو نسخ
          أو بيع أو إعادة بيع أو استغلال أي جزء من الخدمة أو استخدام الخدمة أو الوصول إلى الخدمة أو أي جهة اتصال على
          الموقع الإلكتروني يتم تقديم الخدمة من خلالها ، دون إذن كتابي صريح من جانبنا. . تم تضمين العناوين المستخدمة في
          هذه الاتفاقية للراحة فقط ولن تحد أو تؤثر على هذه الشروط.

          <p class="static__page--title">القسم 3 - دقة المعلومات واكتمالها وحداثتها</p>

          نحن لسنا مسؤولين إذا كانت المعلومات المتوفرة على هذا الموقع غير دقيقة أو كاملة أو حديثة. يتم توفير المواد
          الموجودة على هذا الموقع للحصول على معلومات عامة فقط ولا ينبغي الاعتماد عليها أو استخدامها كأساس وحيد لاتخاذ
          القرارات دون استشارة مصادر المعلومات الأولية أو الأكثر دقة أو الأكثر اكتمالًا أو في الوقت المناسب. أي اعتماد
          على المواد الموجودة على هذا الموقع هو على مسؤوليتك الخاصة.
          قد يحتوي هذا الموقع على معلومات تاريخية معينة. المعلومات التاريخية ، بالضرورة ، ليست حديثة ويتم توفيرها للرجوع
          إليها فقط. نحتفظ بالحق في تعديل محتويات هذا الموقع في أي وقت ، لكننا لسنا ملزمين بتحديث أي معلومات على موقعنا.
          أنت توافق على أنه من مسؤوليتك مراقبة التغييرات على موقعنا.

          <p class="static__page--title">القسم 4 - تعديلات على الخدمة والأسعار</p>

          أسعار منتجاتنا عرضة للتغيير دون إشعار. نحن نحتفظ بالحق في أي وقت في تعديل أو إيقاف الخدمة (أو أي جزء أو محتوى
          منها) دون إشعار في أي وقت. لن نكون مسؤولين تجاهك أو تجاه أي طرف ثالث عن أي تعديل أو تغيير في الأسعار أو تعليق
          أو وقف للخدمة.

          <p class="static__page--title">القسم 5 - دقة الفواتير ومعلومات الحساب</p>

          نحتفظ بالحق في رفض أي طلب تضعه معنا. يجوز لنا ، وفقًا لتقديرنا الخاص ، تقييد أو إلغاء الكميات المشتراة لكل شخص
          أو لكل حساب أو لكل طلب. قد تشمل هذه القيود الطلبات المقدمة من قبل أو تحته نفس حساب العميل و / أو نفس بطاقة
          الائتمان و / أو الطلبات التي تستخدم نفس عنوان الفواتير و / أو الشحن. في حالة قيامنا بإجراء تغيير أو إلغاء طلب
          ، فسنحاول إخطارك عن طريق الاتصال بالبريد الإلكتروني و / أو عنوان الشحن / رقم الهاتف المقدم في وقت تقديم الطلب.
          نحتفظ بالحق في تقييد أو حظر الطلبات التي ، في حكمنا الوحيد ، يبدو أنها مقدمة من التجار أو البائعين أو
          الموزعين.
          أنت توافق على تقديم معلومات الشراء والحساب الحالية والكاملة والدقيقة لجميع المشتريات التي تتم في متجرنا. أنت
          توافق على تحديث حسابك والمعلومات الأخرى على الفور ، بما في ذلك عنوان بريدك الإلكتروني وأرقام بطاقة الائتمان
          وعنوان الشحن ، حتى نتمكن من إكمال معاملاتك والاتصال بك حسب الحاجة.
          لمزيد من التفاصيل ، يرجى مراجعة سياسة الإرجاع الخاصة بنا.

          <p class="static__page--title">القسم 6 - الأدوات الاختيارية</p>

          قد نوفر لك إمكانية الوصول إلى أدوات الجهات الخارجية التي لا نراقبها ولا نملك أي تحكم أو مدخلات.
          أنت تقر وتوافق على أننا نوفر الوصول إلى هذه الأدوات "كما هي" و "كما هي متوفرة" دون أي ضمانات أو إقرارات أو
          شروط من أي نوع ودون أي تأييد. لن نتحمل أي مسؤولية من أي نوع تنشأ عن أو تتعلق باستخدامك لأدوات اختيارية تابعة
          لجهات خارجية.
          إن أي استخدام من جانبك للأدوات الاختيارية المقدمة من خلال الموقع يكون على مسؤوليتك الخاصة وتقديرك تمامًا ويجب
          عليك التأكد من أنك على دراية بالشروط التي يتم توفير الأدوات من خلالها والموافقة عليها من قبل موفر (موفري)
          الطرف الثالث ذي الصلة. قد نقدم أيضًا ، في المستقبل ، خدمات و / أو ميزات جديدة من خلال موقع الويب (بما في ذلك ،
          إصدار أدوات وموارد جديدة). تخضع هذه الميزات و / أو الخدمات الجديدة أيضًا لشروط الخدمة هذه.

          <p class="static__page--title">القسم 7 - روابط الطرف الثالث</p>

          قد تشتمل بعض المحتويات والمنتجات والخدمات المتوفرة عبر خدمتنا على مواد من أطراف ثالثة.
          قد توجهك روابط الجهات الخارجية الموجودة على هذا الموقع إلى مواقع ويب تابعة لجهات خارجية غير تابعة لنا. نحن
          لسنا مسؤولين عن فحص أو تقييم المحتوى أو الدقة ولا نضمن ولن نتحمل أي مسؤولية أو مسؤولية عن أي مواد أو مواقع
          خاصة بطرف ثالث ، أو عن أي مواد أو منتجات أو خدمات أخرى لأطراف ثالثة.
          نحن لسنا مسؤولين عن أي ضرر أو أضرار تتعلق بشراء أو استخدام السلع أو الخدمات أو الموارد أو المحتوى أو أي
          معاملات أخرى تتم فيما يتعلق بأي مواقع ويب تابعة لجهات خارجية. يرجى مراجعة سياسات وممارسات الطرف الثالث بعناية
          والتأكد من فهمك لها قبل الدخول في أي معاملة. يجب توجيه الشكاوى أو المطالبات أو المخاوف أو الأسئلة المتعلقة
          بمنتجات الجهات الخارجية إلى الجهة الخارجية.


          <p class="static__page--title">القسم 8 - تعليقات المستخدم وردود الفعل والطلبات الأخرى</p>

          إذا قمت ، بناءً على طلبنا ، بإرسال بعض عمليات الإرسال المحددة (على سبيل المثال إدخالات المسابقة ووسائل التواصل
          الاجتماعي) أو دون طلب منا ، فإنك ترسل أفكارًا أو اقتراحات أو مقترحات أو خططًا إبداعية أو مواد أخرى ، سواء عبر
          الإنترنت أو عبر البريد الإلكتروني أو غير ذلك ( بشكل جماعي ، "التعليقات") ، فإنك توافق على أنه يجوز لنا ، في أي
          وقت ، دون قيود ، تعديل ونسخ ونشر وتوزيع وترجمة واستخدام أي تعليقات ترسلها إلينا بأي وسيلة أخرى. نحن ولن نكون
          ملزمين بما يلي:
          (1) للحفاظ على سرية أي تعليقات
          (2) لدفع تعويض عن أي تعليقات
          (3) للرد على أي تعليقات.
          يجوز لنا ، ولكن ليس لدينا أي التزام بمراقبة أو تعديل أو إزالة المحتوى الذي نحدده وفقًا لتقديرنا الخاص ، أنه
          غير قانوني أو مسيء أو مهدد أو تشهيري أو إباحي أو فاحش أو مرفوض أو ينتهك الملكية الفكرية لأي طرف أو شروط الخدمة
          هذه .
          أنت توافق على أن تعليقاتك لن تنتهك أي حق لأي طرف ثالث ، بما في ذلك حقوق النشر أو العلامات التجارية أو الخصوصية
          أو الشخصية أو أي حقوق شخصية أو حقوق ملكية أخرى. أنت توافق أيضًا على أن تعليقاتك لن تحتوي على مواد تشهيرية أو
          غير قانونية أو مسيئة أو فاحشة ، أو تحتوي على أي فيروسات كمبيوتر أو برامج ضارة أخرى يمكن أن تؤثر بأي شكل من
          الأشكال على تشغيل الخدمة أو أي موقع ويب ذي صلة. لا يجوز لك استخدام عنوان بريد إلكتروني مزيف ، أو التظاهر بأنك
          شخص آخر غيرك ، أو تضليلنا أو تضليل الأطراف الثالثة فيما يتعلق بأصل أي تعليقات. أنت وحدك المسؤول عن أي تعليقات
          تدلي بها ودقتها. نحن لا نتحمل أي مسؤولية ولا نتحمل أي مسؤولية عن أي تعليقات تنشرها أنت أو أي طرف ثالث.


          <p class="static__page--title">القسم 9 - المعلومات الشخصية</p>

          يخضع تقديمك للمعلومات الشخصية عبر المتجر لسياسة الخصوصية الخاصة بنا.

          <p class="static__page--title">القسم 10 - الأخطاء وعدم الدقة والسهو</p>

          من حين لآخر ، قد تكون هناك معلومات على موقعنا أو في الخدمة تحتوي على أخطاء مطبعية أو عدم دقة أو سهو قد يتعلق
          بأوصاف المنتج والتسعير والعروض الترويجية والعروض وأوقات العبور والتوافر. نحتفظ بالحق في تصحيح أي أخطاء أو عدم
          دقة أو سهو ، وتغيير المعلومات أو تحديثها أو إلغاء الطلبات إذا كانت أي معلومات في الخدمة أو على أي صفحة ذات صلة
          غير دقيقة في أي وقت دون إشعار مسبق (بما في ذلك بعد تقديمك لطلبك) .
          نحن لا نتعهد بتحديث أو تعديل أو توضيح المعلومات في الخدمة أو على أي موقع ويب ذي صلة ، بما في ذلك على سبيل
          المثال لا الحصر ، معلومات التسعير ، باستثناء ما يقتضيه القانون. لا يوجد تحديث محدد أو تاريخ تحديث مطبق في
          الخدمة أو على أي موقع ويب ذي صلة ، يجب أن يؤخذ للإشارة إلى أنه تم تعديل أو تحديث جميع المعلومات الموجودة في
          الخدمة أو على أي موقع ويب ذي صلة.


          <p class="static__page--title">القسم 11 - الاستخدامات المحظورة</p>

          بالإضافة إلى المحظورات الأخرى المنصوص عليها في شروط الخدمة ، يُحظر عليك استخدام الموقع أو محتواه:
          (أ) لأي غرض غير قانوني
          (ب) حث الآخرين على أداء أو المشاركة في أي أعمال غير قانونية
          (ج) انتهاك أي قواعد أو قوانين أو مراسيم دولية أو محلية
          (د) التعدي على أو انتهاك حقوق الملكية الفكرية الخاصة بنا أو حقوق الملكية الفكرية للآخرين
          (هـ) المضايقة أو الإساءة أو الإهانة أو الأذى أو التشهير أو التشهير أو الاستخفاف أو التخويف أو التمييز على أساس
          الجنس أو التوجه الجنسي أو الدين أو العرق أو العرق أو السن أو الأصل القومي أو الإعاقة
          (و) تقديم معلومات خاطئة أو مضللة
          (ز) لتحميل أو نقل فيروسات أو أي نوع آخر من الشفرات الخبيثة التي سيتم استخدامها أو يمكن استخدامها بأي طريقة من
          شأنها التأثير على وظيفة أو تشغيل الخدمة أو أي موقع ويب ذي صلة أو مواقع ويب أخرى أو الإنترنت
          (ح) لجمع أو تتبع المعلومات الشخصية للآخرين
          (ط) البريد الإلكتروني العشوائي أو التصيد الاحتيالي أو الصيدلاني أو الذريعة أو العنكبوت أو الزحف أو الكشط
          (ي) لأي غرض فاحش أو غير أخلاقي
          (ك) للتدخل أو التحايل على ميزات الأمان للخدمة أو أي موقع ويب ذي صلة أو مواقع ويب أخرى أو الإنترنت
          نحن نحتفظ بالحق في إنهاء استخدامك للخدمة أو أي موقع ويب ذي صلة لانتهاك أي من الاستخدامات المحظورة.

          القسم 12 - إخلاء المسؤولية عن الضمانات ؛ تحديد المسؤولية
          نحن لا نضمن أو نضمن أو نضمن أن استخدامك لخدمتنا سيكون دون انقطاع أو في الوقت المناسب أو آمنًا أو خاليًا من
          الأخطاء. نحن لا نضمن أن النتائج التي يمكن الحصول عليها من استخدام الخدمة ستكون دقيقة أو موثوقة.
          أنت توافق على أنه يجوز لنا من وقت لآخر إزالة الخدمة لفترات زمنية غير محددة أو إلغاء الخدمة في أي وقت ، دون
          إخطارك. أنت توافق صراحة على أن استخدامك للخدمة أو عدم قدرتك على استخدامها يكون على مسؤوليتك وحدك. الخدمة وجميع
          المنتجات والخدمات المقدمة لك من خلال الخدمة (باستثناء ما هو مذكور صراحة من قبلنا) مقدمة "كما هي" و "كما هي
          متوفرة" لاستخدامك ، دون أي تمثيل أو ضمانات أو شروط من أي نوع ، سواء كانت صريحة أو ضمنيًا ، بما في ذلك جميع
          الضمانات الضمنية أو شروط القابلية للتسويق والجودة التجارية والملاءمة لغرض معين والمتانة والملكية وعدم
          الانتهاك.
          لا يجوز بأي حال من الأحوال أن يتحمل Yaser ,mall&bros أو مدراءنا أو مسؤولينا أو موظفونا أو الشركات التابعة لنا
          أو الوكلاء أو المقاولون أو المتدربون أو الموردون أو مزودو الخدمة أو المرخصون المسؤولية عن أي إصابة أو خسارة أو
          مطالبة أو أي ضرر مباشر أو غير مباشر أو عرضي أو عقابي أو خاص أو الأضرار التبعية من أي نوع ، بما في ذلك ، على
          سبيل المثال لا الحصر ، خسارة الأرباح ، أو خسارة الإيرادات ، أو فقدان المدخرات ، أو فقدان البيانات ، أو تكاليف
          الاستبدال ، أو أي أضرار مماثلة ، سواء كانت قائمة على العقد ، أو الضرر (بما في ذلك الإهمال) ، أو المسؤولية
          الصارمة أو غير ذلك ، الناشئة عن استخدام أي خدمة أو أي منتجات تم شراؤها باستخدام الخدمة ، أو لأي مطالبة أخرى
          تتعلق بأي شكل من الأشكال باستخدامك للخدمة أو أي منتج ، بما في ذلك ، على سبيل المثال لا الحصر ، أي أخطاء أو سهو
          في أي محتوى ، أو أي خسارة أو ضرر من أي نوع يحدث نتيجة لاستخدام الخدمة أو أي محتوى (أو منتج) يتم نشره أو نقله
          أو إتاحته بطريقة أخرى عبر الخدمة ، حتى لو تم التنبيه بإمكانية ذلك. نظرًا لأن بعض المقاطعات أو الولايات
          القضائية لا تسمح باستثناء أو تحديد المسؤولية عن الأضرار التبعية أو العرضية ، في مثل هذه المقاطعات أو الولايات
          القضائية ، يجب أن تكون مسؤوليتنا محدودة إلى أقصى حد يسمح به القانون.


          <p class="static__page--title">القسم 13 - التعويض</p>

          أنت توافق على تعويض Yaser ,mall&bros والدفاع عنها وحمايتها من الضرر ، وشركتنا الأم ، والشركات التابعة لنا ،
          والشركات التابعة لنا ، والشركاء ، والمسؤولين ، والمديرين ، والوكلاء ، والمقاولين ، والمرخصين ، ومقدمي الخدمات
          ، والمقاولين من الباطن ، والموردين ، والمتدربين والموظفين ، من أي مطالبة أو طلب ، بما في ذلك المعقول أتعاب
          المحاماة ، التي يدفعها أي طرف ثالث بسبب أو الناشئة عن خرقك لشروط الخدمة هذه أو المستندات التي تتضمنها بالإحالة
          ، أو انتهاكك لأي قانون أو حقوق طرف ثالث.


          <p class="static__page--title">القسم 14 - القابلية للفصل</p>

          في حالة تحديد أي شرط من شروط الخدمة هذه على أنه غير قانوني أو باطل أو غير قابل للتنفيذ ، يجب أن يكون هذا الحكم
          قابلاً للتنفيذ إلى أقصى حد يسمح به القانون المعمول به ، ويعتبر الجزء غير القابل للتنفيذ منفصلاً عن شروط الخدمة
          ، لن يؤثر هذا القرار على صلاحية ونفاذ أي أحكام أخرى متبقية.

          <p class="static__page--title">القسم 15 - الإنهاء</p>

          تظل التزامات ومسؤوليات الأطراف التي تم تكبدها قبل تاريخ الإنهاء سارية بعد إنهاء هذه الاتفاقية لجميع الأغراض.
          شروط الخدمة هذه سارية المفعول ما لم يتم إنهاؤها من قبلك أو من قبلنا. يمكنك إنهاء شروط الخدمة هذه في أي وقت عن
          طريق إخطارنا بأنك لم تعد ترغب في استخدام خدماتنا ، أو عندما تتوقف عن استخدام موقعنا.
          إذا أخفقت في حكمنا الوحيد ، أو نشك في أنك فشلت ، في الامتثال لأي شرط أو شرط من شروط الخدمة هذه ، فيجوز لنا
          أيضًا إنهاء هذه الاتفاقية في أي وقت دون إشعار مسبق وستظل مسؤولاً عن جميع المبالغ المستحقة وإدراج تاريخ الإنهاء
          ؛ و / أو وفقًا لذلك قد يمنعك من الوصول إلى خدماتنا (أو أي جزء منها).

          <p class="static__page--title">القسم 16 - الاتفاق الكامل</p>

          لا يشكل إخفاقنا في ممارسة أو إنفاذ أي حق أو شرط من شروط الخدمة هذه تنازلاً عن هذا الحق أو الحكم.
          تشكل شروط الخدمة هذه وأي سياسات أو قواعد تشغيل منشورة من قبلنا على هذا الموقع أو فيما يتعلق بالخدمة الاتفاقية
          الكاملة والتفاهم بينك وبيننا وتحكم استخدامك للخدمة ، وتحل محل أي اتفاقيات واتصالات ومقترحات سابقة أو معاصرة
          سواء كانت شفهية أو كتابية ، بينك وبيننا (بما في ذلك ، على سبيل المثال لا الحصر ، أي إصدارات سابقة من شروط
          الخدمة).
          لا يجوز تفسير أي غموض في تفسير شروط الخدمة هذه على الجهة الصاغة.

          <p class="static__page--title">القسم 17 - القانون الحاكم</p>

          تخضع شروط الخدمة وأية اتفاقيات منفصلة نقدم بموجبها الخدمات لك وتفسر وفقًا لقوانين بلدك.

          <p class="static__page--title">القسم 18 - تغييرات على شروط الخدمة</p>

          يمكنك مراجعة أحدث إصدار من شروط الخدمة في أي وقت في هذه الصفحة.
          نحن نحتفظ بالحق ، وفقًا لتقديرنا الخاص ، في تحديث أو تغيير أو استبدال أي جزء من شروط الخدمة هذه عن طريق نشر
          التحديثات والتغييرات على موقعنا. تقع على عاتقك مسؤولية التحقق من موقعنا بشكل دوري لمعرفة التغييرات. استمرار
          استخدامك أو الوصول إلى موقعنا على الويب أو الخدمة بعد نشر أي تغييرات على شروط الخدمة هذه يشكل قبولًا لهذه
          التغييرات.

          <p class="static__page--title">القسم 19 - معلومات الاتصال</p>

          يجب إرسال الأسئلة المتعلقة بشروط الخدمة إلينا على
          Yasermall@live.com


        </div>
      </div>
    </div>
  </div>
</div>
