import {Component, OnInit} from '@angular/core';
import {ProductService} from '../../services/product.service';
import {TranslateService} from '@ngx-translate/core';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-wishlist-page',
  templateUrl: './wishlist-page.component.html',
  styleUrls: ['./wishlist-page.component.scss']
})
export class WishlistPageComponent implements OnInit {

  public wishList: any;

  constructor(private productService: ProductService, public translateService: TranslateService, public snackBar: MatSnackBar) {
  }

  ngOnInit(): void {
    this.getWishList();
  }

  public getWishList(): void {
    this.productService.getWishList().subscribe(
      (res) => {
        this.wishList = res.wishlistData;
      },
      (err) => {
        this.snackBar.open(err.error.error.message, this.translateService.instant('snack_bar_close'), {
          duration: 3500,
          panelClass: this.translateService.instant('dir')
        });
      }
    );
  }

}
