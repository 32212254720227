import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-cookie-banner',
  templateUrl: './cookie-banner.component.html',
  styleUrls: ['./cookie-banner.component.scss']
})
export class CookieBannerComponent implements OnInit {

  @Input() showCookie: boolean;

  constructor() {
  }

  ngOnInit(): void {
  }

  public hideCookie(): void {
    localStorage.setItem('hideCookie', 'true');
    this.showCookie = false;
  }

}
