import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, Renderer2} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {UserService} from '../../services/user.service';
import {Router} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';
import {AppComponent} from '../../app.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  public carouselDir: boolean;
  public slideConfig;
  public phoneNumber = '';
  public showErrorMsg = false;
  @Input() showLogin = false;
  @Output() closeLogin: EventEmitter<boolean> = new EventEmitter();
  public countDown = 60;
  public showResendCode = false;
  public loginStep = 'first';
  public confirmationCode = '';
  public timer;

  constructor(public translateService: TranslateService,
              private renderer: Renderer2, private userService: UserService, private router: Router,
              private snackBar: MatSnackBar, public app: AppComponent) {
    translateService.get('carousel_rtl').subscribe((text: string) => {
      this.carouselDir = text === 'true';
      this.slideConfig = {
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        infinite: true,
        arrows: false,
        dots: true,
        adaptiveHeight: true,
        rtl: this.carouselDir
      };
    });
  }

  ngOnInit(): void {
    this.renderer.addClass(document.body, 'modal-open');
  }

  ngOnDestroy(): void {
    this.router.navigate([], {
      queryParams: {
        showLogin: null
      },
      queryParamsHandling: 'merge'
    });
    this.renderer.removeClass(document.body, 'modal-open');
    this.resetTimer();
  }

  public checkNumber(): void {
    this.showErrorMsg = isNaN(Number(this.phoneNumber));
    if (!this.showErrorMsg) {
      this.userService.sendOtpCode({telephone: this.phoneNumber}).subscribe(
        (res) => {
          this.triggerCountDown();
          this.loginStep = 'second';
        },
        (err) => {
          this.snackBar.open(err.error.error.message, this.translateService.instant('snack_bar_close'), {
            duration: 3500,
            panelClass: this.translateService.instant('dir')
          });
        }
      );
    }
  }

  public moveToNextDigit(nextDigit, event): void {
    if (isFinite(event.key)) {
      document.getElementById(nextDigit).focus();
    }
  }

  public submitVerificationCode(): void {
    const code = {
      telephone: this.phoneNumber,
      code: this.confirmationCode
    };
    this.userService.submitCode(code).subscribe(
      (res) => {
        this.showLogin = false;
        localStorage.setItem('wk_token', res.wk_token);
        this.userService.load();
        this.app.getCart();
        this.ngOnDestroy();
      },
      (err) => {
        this.snackBar.open(err.error.error.message, this.translateService.instant('snack_bar_close'), {
          duration: 3500,
          panelClass: this.translateService.instant('dir')
        });
      }
    );
  }

  public triggerCountDown(): void {
    this.timer = setInterval(
      () => {
        this.countDown--;
        if (this.countDown < 1) {
          clearTimeout(this.timer);
          this.showResendCode = true;
        }
      }, 1000);
  }

  public resendCode(): void {
    this.userService.sendOtpCode({telephone: this.phoneNumber}).subscribe(
      (res) => {
        this.snackBar.open(res.message, this.translateService.instant('snack_bar_close'), {
          duration: 3500,
          panelClass: this.translateService.instant('dir')
        });
        this.showResendCode = !this.showResendCode;
        this.resetTimer();
        this.triggerCountDown();
      },
      (err) => {
        this.snackBar.open(err.error.error.message, this.translateService.instant('snack_bar_close'), {
          duration: 3500,
          panelClass: this.translateService.instant('dir')
        });
      }
    );
  }

  public resetTimer(): void {
    clearTimeout(this.timer);
    this.countDown = 60;
  }
}
