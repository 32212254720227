import {Component, OnInit} from '@angular/core';
import {UserService} from '../../services/user.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';
import {RouteEnum} from '../../enums/routes.enum';
import {Router} from '@angular/router';
@Component({
  selector: 'app-contact-page',
  templateUrl: './contact-page.component.html',
  styleUrls: ['./contact-page.component.scss']
})
export class ContactPageComponent implements OnInit {
  public contactForm: FormGroup;
  public isMobileWebView = false;
  public RouteEnum = RouteEnum;

  constructor(public userService: UserService, public translateService: TranslateService,
              private fb: FormBuilder, private snackBar: MatSnackBar, public router: Router) {
  }

  ngOnInit(): void {
    this.intializeContactForm();
    if (this.router.url.split('/').join('').includes(this.RouteEnum.Contact_mobile)) {
      this.isMobileWebView = true;
    }
  }

  private intializeContactForm(): void {
    this.contactForm = this.fb.group({
      name: ['', Validators.required],
      mobile: ['', Validators.required],
      message: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
    });
  }

  public submitContactUs(): void {
    const formData = this.contactForm.value;
    const form = {
      fullname: formData.name,
      mobilenumber: formData.mobile,
      email: formData.email,
      message: formData.message
    };
    this.userService.contactUsSubmission(form).subscribe(
      (res) => {
        this.snackBar.open(this.translateService.instant('contact_success_msg'), this.translateService.instant('snack_bar_close'), {
          duration: 3500,
          panelClass: this.translateService.instant('dir')
        });
      },
      (err) => {
        this.snackBar.open(err.error.error.message, this.translateService.instant('snack_bar_close'), {
          duration: 3500,
          panelClass: this.translateService.instant('dir')
        });
      }
    );
  }

}
