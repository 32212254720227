<ngx-slick-carousel [config]="slideConfig" class="home__slider--container" dir="{{'html_dir' | translate}}"
                    (afterChange)="isSwiping=false" (beforeChange)="isSwiping=true" *ngIf="slides?.length">
  <div ngxSlickItem class="slide slider__single--slide" [class.ads]="isAds" *ngFor="let slide of slides">
    <img [alt]="slide.image"
         [src]="slide.image"
         [class.slider-img]="!isAds"
         (click)="isAds && navigateToSlide($event, slide)"
         [height]="isAds ? '470' : '650'"
    />
  </div>
</ngx-slick-carousel>

