import {Component, EventEmitter, Input, NgIterable, OnInit, Output} from '@angular/core';
import {CheckoutService} from '../../services/checkout.service';
import {CartService} from '../../services/cart.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {environment} from '../../../environments/environment';
import {AppComponent} from '../../app.component';
import {BrowserService} from '../../services/browser.service';
import {FormatDateService} from '../../services/formatDate.service';
import {IApplyCoupon, ICartView} from "../../intefaces/Cart";
import {Card, IDeliveryMethodsByAddress, PaymentMethod} from "../../intefaces/Address";

enum DeliveryMethodTypeEnum {
  EXPRESS = 'express',
  PRIME = 'prime',
  PRIME_NOW = 'prime_now',
  NORMAL = 'normal'
}

enum DeliveryMethodIdsEnum {
  EXPRESS = '2',
  PRIME = '1',
  PRIME_NOW = '4',
  NORMAL = '3'
}

enum ShippingTypeEnum {
  DELIVERY = 'zones.zones',
  PICKUP = 'pickup.pickup'
}

export enum PaymentAmountPaymentEnum {
  SUB_TOTAL = 'SUB_TOTAL'
}

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent implements OnInit {
  @Output() checkoutObj: EventEmitter<any> = new EventEmitter();
  @Input() cartItems: ICartView;
  @Input() isAppliedCoupon: boolean;
  public isEnablePlaceOrder = false;
  public callBackModalCoupon: () => {};
  // Payment & Delivery types
  public deliveryType = 'zones.zones';
  // Delivery Slots
  public deliverySlots = [];
  public selectedDeliverySlotIdx;
  public selectedDeliverySlotId;
  public selectedSelectedDeliveryDate;
  public selectedTimeSlot;
  public selectedDeliveryMethod: { id: number; method: IDeliveryMethodsByAddress };
  public selectedPaymentMethod: { id: number; method: PaymentMethod };
  public selectedPaymentCardType: { id: number; card: Card };
  public deliveryMethods: NgIterable<IDeliveryMethodsByAddress>;
  public openConfirmPayment = false;
  public selectedCoupon = "";
  public openModalApplyCoupon = false;
  // Address
  public chosenAddressId;


  public deliveryFee: any;
  public serviceFee: any;
  public isSubTotal = false;

  // Notes
  public notes = '';
  public isMobile = this.browserService.isMobileDevice();

  constructor(private checkoutService: CheckoutService, private cartService: CartService, private snackBar: MatSnackBar,
              public router: Router, public translateService: TranslateService, public app: AppComponent,
              public browserService: BrowserService, public formatDate: FormatDateService) {
  }

  ngOnInit(): void {
    this.paymentAmountType();
  }

  public getCurrentLang(): string {
    return localStorage.getItem('lang') || 'ar';
  }

  public paymentAmountType(): void {
    this.checkoutService.paymentAmountType().subscribe(res => {
      if (res?.PAYMENT_AMOUNT_TYPE === PaymentAmountPaymentEnum.SUB_TOTAL) {
        this.isSubTotal = true;
      }
    })
  }

  public navigateToCheckoutPayment(orderId: number): void {
    window.location.href = `${this.getCurrentLang()}/checkout/payment?orderId=${orderId}`
  }

  public getDeliveryTimesForPickUp(shippingType: string): void {
    if (shippingType === ShippingTypeEnum.PICKUP) {
      this.checkoutService.getDeliveryTimes().subscribe(
        (res) => {
          this.deliverySlots = res.timeslots;
        }, (err) => {
          console.error(err);
        }
      );
    }
  }

  public getDeliveryTimesForDelivery(deliveryMethod: string): void {
    if (deliveryMethod === DeliveryMethodTypeEnum.NORMAL || deliveryMethod === DeliveryMethodTypeEnum.PRIME) {
      this.checkoutService.getDeliveryTimesForPrimeOrNormal(deliveryMethod).subscribe(
        (res) => {
          this.deliverySlots = res.timeslots;
        }, (err) => {
          console.error(err);
        }
      );
    }
  }


  public placeOrder(event): any {

    // if click out of the place order button
    if (event.target.id !== 'checkout-btn') {
      return;
    }

    // to validate flow
    if (this.validateCheckOutFlow(true) === false) {
      return;
    }

    // if the user payment type has SUB_TOTAL , we should open dialog
    if (this.isSubTotal && this.selectedPaymentMethod?.method?.id === 'vep') {
      return this.openConfirmPayment = true;
    }

    // if the user selects the “Card on Delivery” a confirmation dialog should appear
    if (this.selectedPaymentCardType) {
      return this.openConfirmPayment = true;
    }

    // for normal flow after validate
    if (event.target.id === 'checkout-btn' && this.isEnablePlaceOrder) {
      return this.placeOrderApi();
    }

  }

  public placeOrderApi(): void {

    if (this.validateCheckOutFlow(true) === false) {
      return;
    }
    const placeOrderObj = this.generatePlaceOrderObj();
    this.checkoutObj.emit(placeOrderObj);
    this.checkoutService.postCheckout(placeOrderObj).subscribe(
      (res) => {
        this.app.getCart();
        this.snackBar.open(this.translateService.instant('order_success'), this.translateService.instant('snack_bar_close'), {
          duration: 3500,
          panelClass: this.translateService.instant('dir')
        });
        setTimeout(
          () => {
            if (placeOrderObj.payment_method === "vep") {
              this.navigateToCheckoutPayment(res?.order_id)
            } else {
              this.router.navigateByUrl(`/${localStorage.getItem('lang')}/my-account?activeTab=order`).then();
            }
          }
        );
      }, (err) => {
        this.snackBar.open(err.error.error.message, this.translateService.instant('snack_bar_close'), {
          duration: 3500,
          panelClass: this.translateService.instant('dir')
        });
      }
    );
  }

  public getCart(): any {
    this.cartService.getCartItems(environment.listingImgSize).subscribe(
      (res) => {
        this.cartItems = res;
        return res;
      },
      (err) => {
        console.error(err);
      }
    );
  }


  public setDeliveryFee(): void {
    this.deliveryFee = this.selectedDeliveryMethod?.method?.delivery_fees;
    this.serviceFee = this.selectedDeliveryMethod?.method?.service_fees;
  }

  public generatePlaceOrderObj(): any {
    const placeOrderObj: any = {
      comment: this.notes,
      shipping_method: this.deliveryType,
    };

    const propsToInclude = {
      deliverydate: this.selectedSelectedDeliveryDate,
      time_slots: this.selectedDeliverySlotId,
      address_id: this.chosenAddressId,
      delivery_fees: this.selectedDeliveryMethod?.method?.delivery_fees,
      service_fees: this.selectedDeliveryMethod?.method?.service_fees,
      payment_method: this.selectedPaymentMethod?.method?.id,
      delivery_method_id: this.selectedDeliveryMethod?.method?.delivery_method_id,
      distance_km: this.selectedDeliveryMethod?.method?.distance_km,
      payment_card_type_id: this.selectedPaymentCardType?.card?.id,
    };

    for (const [key, value] of Object.entries(propsToInclude)) {
      if (value || value === 0) {
        placeOrderObj[key] = value;
      }
    }

    if (placeOrderObj.delivery_method_id !== 1) {
      delete placeOrderObj.service_fees;
    }

    return placeOrderObj;
  }


  public validateCheckOutFlow(withNotify = false): boolean {
    let isEnable = true;
    let errorText = '';
    if (!this.deliveryType) {
      errorText = 'shipping_method_error';
      isEnable = false;
    } else if (this.deliveryType === ShippingTypeEnum.PICKUP) {
      if (!this.selectedSelectedDeliveryDate || !this.selectedDeliverySlotId) {
        // Show error that the user should select timeSlots
        isEnable = false;
        errorText = 'time_slots_error';
      }
    } else if (this.deliveryType === ShippingTypeEnum.DELIVERY) {
      if (!this.chosenAddressId) {
        // Show error that the user should select address
        isEnable = false;
        errorText = 'select_address_error';
      } else if (this.selectedDeliveryMethod) {

        if (this.deliverySlots?.length > 0 && (!this.selectedSelectedDeliveryDate || !this.selectedDeliverySlotId)) {
          // Show error that the user should select timeSlots
          isEnable = false;
          errorText = 'time_slots_error';
        } else if (!this.selectedPaymentMethod) {
          // Show error that the user should select paymentMethod
          isEnable = false;
          errorText = this.translateService.instant('payment_method_error');
        } else if (this.selectedPaymentMethod.method.cards.length > 0 && !this.selectedPaymentCardType) {
          isEnable = false;
          errorText = this.translateService.instant('card_type_on_delivery')
        }

      } else {
        errorText = 'delivery_method_error';
        isEnable = false;
      }

    }

    // Enable/disable the submit button based on the validation result
    if (!isEnable && withNotify) {
      // Display the error message in a snackbar notification
      this.snackBar.open(this.translateService.instant(errorText), this.translateService.instant('snack_bar_close'), {
        duration: 3500,
        panelClass: this.translateService.instant('dir')
      });
    }

    this.isEnablePlaceOrder = isEnable;
    return isEnable;
  }


  public resetProprietiesBasedOnFlow(section): void {
    switch (section) {
      case 'address':
        this.selectedDeliverySlotIdx = null;
        this.selectedSelectedDeliveryDate = null;
        this.selectedDeliverySlotId = null;
        this.selectedDeliveryMethod = null;
        this.selectedPaymentMethod = null;
        this.selectedPaymentCardType = null;
        this.deliverySlots = null;
        this.setDeliveryFee();
        break;
      case 'deliveryMethod':
        this.selectedPaymentMethod = null;
        this.selectedDeliverySlotId = null;
        this.selectedDeliverySlotIdx = null;
        this.selectedSelectedDeliveryDate = null;
        this.selectedPaymentCardType = null;
        this.deliverySlots = null;
        this.setDeliveryFee();
        break;
      case 'timeslots':
        this.selectedPaymentMethod = null;
        this.selectedPaymentCardType = null;
        break;
      case 'paymentMethod':
        this.selectedPaymentCardType = null;
        break
    }
  }


  public resetAllProperties(): void {
    this.selectedDeliverySlotIdx = null;
    this.selectedDeliverySlotId = null;
    this.selectedSelectedDeliveryDate = null;
    this.selectedTimeSlot = null;
    this.selectedDeliveryMethod = null;
    this.selectedPaymentMethod = null;
    this.deliveryMethods = [];
    this.chosenAddressId = null;
    this.deliverySlots = null;
    this.selectedPaymentCardType = null;
  }

  public onChooseDeliveryPickUpType(): any {
    if (this.isAppliedCoupon) {
      this.openModalApplyCoupon = true;
      this.callBackModalCoupon = () => this.onChooseDeliveryPickUpType();
      return;
    }
    this.deliveryType = 'pickup.pickup';
    this.getDeliveryTimesForPickUp('pickup.pickup');
    this.resetAllProperties();
    this.setDeliveryFee();
    this.validateCheckOutFlow();
  }

  public onChooseDeliveryMethod(data: { id: number, method: IDeliveryMethodsByAddress }): any {
    if (Number(data.method.delivery_method_id) !== Number(this?.cartItems?.coupon?.coupon_applied_on_delivery_type)
      && this.isAppliedCoupon) {
      this.openModalApplyCoupon = true;
      this.callBackModalCoupon = () => this.onChooseDeliveryMethod(data);
      return;
    }
    this.selectedDeliveryMethod = data;
    this.getDeliveryTimesForDelivery(data.method?.type);
    this.resetProprietiesBasedOnFlow('deliveryMethod');
    this.validateCheckOutFlow()
  }

  public isShowCoupon(): boolean {
    if (this.isAppliedCoupon) {
      return true;
    }
    if (this.selectedDeliveryMethod) {
      return true;
    }
    return false;
  }

  public onChangeCoupon($event): void {
    this.selectedCoupon = $event;
  }

  public onApplyCoupon(): void {
    if (this.selectedCoupon?.length < 1) {
      return;
    }
    const couponBody = {
      coupon: this.selectedCoupon,
      coupon_delivery_type: this.selectedDeliveryMethod?.method?.delivery_method_id
    }
    this.cartService.applyCoupon(couponBody).subscribe((response: IApplyCoupon) => {
      if (response) {
        this.isAppliedCoupon = response.cart.coupon.applied_coupon;
        this.cartItems = response.cart;
      }
    }, error => {
      this.snackBar.open(error.error.error.message, this.translateService.instant('snack_bar_close'), {
        duration: 3500,
        panelClass: this.translateService.instant('dir')
      });
    })
  }

  public onRemoveCoupon(): void {
    const couponBody = {
      coupon: this.cartItems.coupon.code,
      coupon_delivery_type: this.cartItems.coupon.coupon_applied_on_delivery_type
    }
    this.cartService.removeCoupon(couponBody).subscribe((response) => {
      this.openModalApplyCoupon = false;
      this.isAppliedCoupon = false;
      this.selectedCoupon = '';
      if (this.callBackModalCoupon) {
        this.callBackModalCoupon();
      }
      this.getCart();
    })
  }

  public translateAppliedMethod(): string {
    if (this.isAppliedCoupon) {
      if (Number(this?.cartItems?.coupon.coupon_applied_on_delivery_type) === Number(DeliveryMethodIdsEnum.EXPRESS)) {
        return this.translateService.instant('applied_on_express')
      }
      if (Number(this?.cartItems?.coupon.coupon_applied_on_delivery_type) === Number(DeliveryMethodIdsEnum.PRIME)) {
        return this.translateService.instant('applied_on_prime')
      }
      if (Number(this?.cartItems?.coupon.coupon_applied_on_delivery_type) === Number(DeliveryMethodIdsEnum.PRIME_NOW)) {
        return this.translateService.instant('applied_on_prime_now')
      }
      if (Number(this?.cartItems?.coupon.coupon_applied_on_delivery_type) === Number(DeliveryMethodIdsEnum.NORMAL)) {
        return this.translateService.instant('applied_on_normal')
      }
    }
  }

  public paymentConfirmationDesc(): string {
    if (this.selectedPaymentCardType) {
      return this.translateService.instant('confirm_payment_card_msg');
    } else {
      return this.translateService.instant('confirm_payment_msg').replace('SUBTOTAL', this.cartItems?.sub_total_amount)
        .replace('__serviceFee__', this.serviceFee ? this.translateService.instant('serviceFee') : '');
    }
  }

  public translateCardOnDeliveryNote(): string {
    const deliveryMethodType = this.selectedDeliveryMethod?.method?.type;
    if (deliveryMethodType === DeliveryMethodTypeEnum.PRIME || deliveryMethodType === DeliveryMethodTypeEnum.PRIME_NOW) {
      return this.translateService.instant('card_on_delivery_note').replace('{placeholder}', this.translateService.instant('serviceFee'));
    } else {
      return this.translateService.instant('card_on_delivery_note').replace('{placeholder}', '');
    }
  }
}
