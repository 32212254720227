export const OrderStatusEnum = {
  ORDER_STATUS_PENDING: { id: 1, lbl: 'Pending|قيد الإنتظار' },
  ORDER_STATUS_PROCESSING: { id: 2, lbl: 'Processing|قيد التجهيز' },
  ORDER_STATUS_OUT_FOR_DELEIVRY: { id: 3, lbl: 'Out for delivery|خارج للتوصيل' },
  ORDER_STATUS_COMPLETED: { id: 5, lbl: 'Complete|تم تمام الطلب' },
  ORDER_STATUS_CANCELED: { id: 7, lbl: 'ملغي|Canceled' },
  ORDER_STATUS_DENIED: { id: 8, lbl: 'رفض|Denied' },
  ORDER_STATUS_FAILED: { id: 10, lbl: 'فشل|Failed' },
  ORDER_STATUS_REVERSED: { id: 12, lbl: 'مرتجع|Reversed' },
  ORDER_STATUS_EXPIRED: { id: 14, lbl: 'منتهي الصلاحية|Expired' },
  ORDER_STATUS_DELEIVERED: { id: 15, lbl: 'تم التوصيل|Delivered' },
  IS_EXPRESS_ORDER: 1,
  ORDER_STATUS_ITEMS_UNAVAILABLE_PENDING_CUSTOMER: { id: 100, lbl: 'Items unavailable|مواد غير متوفره' },
  ORDER_STATUS_PICKED_UP: { id: 101, lbl: 'Picked Up|تم الاستلام داخل المول' },
};
