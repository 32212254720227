<div id="mobile__menu" class="mobile__menu--container">
  <div class="mobile__menu--item" [routerLinkActive]="['active']" [routerLink]="'/' + RouteEnum[getCurrentLang()].Home">
    <i class="fa fa-home" aria-hidden="true"></i>
    <p>{{'mobile_home' | translate}}</p>
  </div>
  <div class="mobile__menu--item" [routerLinkActive]="['active']" [routerLink]="'/' + RouteEnum[getCurrentLang()].Category">
    <i class="fa fa-th-large" aria-hidden="true"></i>
    <p>{{'mobile_categories' | translate}}</p>
  </div>
  <div class="mobile__menu--item" [routerLinkActive]="['active']" [routerLink]="'/' + getCurrentLang() +'/sub-category/0/deals'" [queryParams]="{deals: 'true'}">
    <i class="fa fa-tags" aria-hidden="true"></i>
    <p>{{'mobile_deals' | translate}}</p>
  </div>
  <div class="mobile__menu--item" [routerLinkActive]="['active']" [routerLink]="'/' + RouteEnum[getCurrentLang()].Wishlist">
    <i class="fa fa-heart" aria-hidden="true"></i>
    <p>{{'mobile_wishlist' | translate}}</p>
  </div>
</div>
