import {Component, OnInit} from '@angular/core';
import {CategoryService} from '../../services/category.service';
import {ProductService} from '../../services/product.service';
import {VisualService} from '../../services/visual.service';
import {environment as env} from '../../../environments/environment';
import {BrowserService} from '../../services/browser.service';
import {TranslateService} from '@ngx-translate/core';
import {ActivatedRoute, Router} from '@angular/router';
import {CheckoutService} from "../../services/checkout.service";

export interface IPopupAds {
  title: string;
  type: string;
  link: string;
  image: string;
  dominant_color: string;
  category_name: string;
  is_subcategory: boolean;
}

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent implements OnInit {

  public topCategoryList: any;
  public hotDeals: any;
  public recentlyViewed: any;
  public featuredProducts: any;
  public latestProducts: any;
  public homePageSlides: any;
  public homePageAds: any;
  public popupAds: IPopupAds;
  public closePopup = false;
  public popupShown;


  constructor(private categoryService: CategoryService,
              private productService: ProductService,
              private checkoutService: CheckoutService,
              private visualService: VisualService,
              public browserService: BrowserService,
              public translate: TranslateService,
              private router: Router
  ) {
  }

  ngOnInit(): void {
    this.getCategories();
    this.getHotDeals();
    this.getFeaturedProducts();
    this.getLatestProducts();
    this.getHomePageSlides();
    this.getRecentlyViewed();
    this.getHomePage();

    if (sessionStorage.getItem('popupShown')) {
      this.popupShown = sessionStorage.getItem('popupShown');
    } else {
      sessionStorage.setItem('popupShown', 'false');
      this.popupShown = 'false';
    }
  }

  public getCategories(): void {
    this.categoryService.getAllCategories().subscribe(
      (res) => {
        this.topCategoryList = res.categories;
      },
      (err) => {
        console.error(err);
      }
    );
  }

  public getHotDeals(): void {
    this.productService.getHotDeals(env.listingImgSize, env.loadLimit).subscribe(
      (res) => {
        this.hotDeals = res;
      },
      (err) => {
        console.error(err);
      }
    );
  }

  public getFeaturedProducts(): void {
    this.productService.getFeaturedProducts(env.listingImgSize, env.loadLimit).subscribe(
      (res) => {
        this.featuredProducts = res;
      },
      (err) => {
        console.error(err);
      }
    );
  }

  public getLatestProducts(): void {
    this.productService.getLatestProducts(env.listingImgSize, env.loadLimit).subscribe(
      (res) => {
        this.latestProducts = res;
      },
      (err) => {
        console.error(err);
      }
    );
  }

  public getRecentlyViewed(): void {
    this.productService.recentlyViewed(env.listingImgSize, env.loadLimit).subscribe(
      (res) => {
        this.recentlyViewed = res;
      },
      (err) => {
        console.error(err);
      }
    );
  }

  public getHomePageSlides(): void {
    this.visualService.getHomePageSlides(1000, 3).subscribe(
      (res) => {
        this.homePageSlides = res.homepageBanners;
      },
      (err) => {
        console.error(err);
      }
    );
  }

  public getHomePage(): void {
    this.visualService.getHomePage().subscribe(
      (res) => {
        this.homePageAds = res?.ads;
        this.popupAds = res?.ads_popup[0];
      },
      (err) => {
        console.error(err);
      }
    );
  }

  public onAdsClick(): void {
    if (this.popupAds.type === 'category') {
      this.router.navigateByUrl(`/${localStorage.getItem('lang')}/sub-category/${this.popupAds.link}/${this.popupAds?.category_name}`);
    }
    if (this.popupAds.type === 'product') {
      this.router.navigateByUrl(`/${localStorage.getItem('lang')}/product/` + this.popupAds.link);
    }
    sessionStorage.setItem('popupShown', 'true');
  }

  public onClosePopup(): void {
    sessionStorage.setItem('popupShown', 'true');
  }

}
