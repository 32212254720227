<div class="loading__screen--container">
  <div class="loading-center">
    <ng-lottie
      width="300px"
      height="200px"
      containerClass="moving-box another-class"
      class="center-loading"
      [options]="options"
    ></ng-lottie>
  </div>
</div>
