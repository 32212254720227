import { Component, OnInit } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Router} from '@angular/router';
import {RouteEnum} from '../../enums/routes.enum';

@Component({
  selector: 'app-privacy-page',
  templateUrl: './privacy-page.component.html',
  styleUrls: ['./privacy-page.component.scss']
})
export class PrivacyPageComponent implements OnInit {
  public isMobileWebView = false;
  public RouteEnum = RouteEnum;
  constructor(public translateService: TranslateService, public router: Router) { }

  ngOnInit(): void {
    if (this.router.url.split('/').join('').includes(this.RouteEnum.Privacy_mobile)) {
      this.isMobileWebView = true;
    }
  }

}
