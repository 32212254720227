import {Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild} from '@angular/core';

@Component({
  selector: 'app-results-header',
  templateUrl: './results-header.component.html',
  styleUrls: ['./results-header.component.scss']
})
export class ResultsHeaderComponent implements OnInit {

  @Input() title = '';
  @Input() resultCount = '';
  @Input() sortOptions = [];
  @Output() toggleModal: EventEmitter<boolean> = new EventEmitter();
  @Output() selectedSort: EventEmitter<string> = new EventEmitter();
  @Input() isModalToggeled: boolean;

  constructor(private renderer: Renderer2) {
  }

  ngOnInit(): void {
  }

  public toggleFilterModal(): void {
    if (this.isModalToggeled) {
      this.renderer.removeClass(document.body, 'modal-open');
      this.isModalToggeled = !this.isModalToggeled;
      this.toggleModal.emit(false);
    } else {
      this.renderer.addClass(document.body, 'modal-open');
      this.isModalToggeled = !this.isModalToggeled;
      this.toggleModal.emit(true);
      setTimeout(
        () => {
          this.isModalToggeled = false;
        }, 2000
      );
    }
  }

  public selectSortOption(option): void {
    this.selectedSort.emit(this.sortOptions[option]);
  }

}
