<div id="desktop-header" class="header__container fade-in" *ngIf="!isMobile">
  <div class="container__max-width text-center">
    <div class="container-fluid">
      <div class="row">
        <div class="col-1">
          <img [routerLink]="'/' + RouteEnum[getCurrentLang()].Home" class="header__logo"
               src="/assets/images/global/logo.svg">
        </div>
        <div class="col-7">
          <div class="header__search-bar">
            <input class="header__search-input"
                   placeholder="{{'search' | translate}}"
                   type="text"
                   aria-label="Number"
                   matInput
                   #autoComplete='matAutocompleteTrigger'
                   [(ngModel)]="searchInput"
                   [matAutocomplete]="auto"
                   (ngModelChange)="searchForProduct()"
                   (keyup.enter)="navigateToProduct($event.target.value); autoComplete.closePanel()">
            <i *ngIf="searchInput" (click)="searchInput = ''" class="fa fa-times delete-search" aria-hidden="true"></i>
            <mat-autocomplete #auto="matAutocomplete">
              <mat-option *ngFor="let option of searchResults" [value]="option.product_id"
                          [attr.data-productId]="option.value"
                          (click)="navigateToProduct(option.product_id)">
                {{option.name}}
              </mat-option>
            </mat-autocomplete>
            <img class="search__bar--icon" src="/assets/images/global/Search.svg">
          </div>
        </div>
        <div class="col-1">
          <img src="/assets/images/global/heart.svg" [routerLink]="'/' + RouteEnum[getCurrentLang()].Wishlist">
        </div>
        <div class="col-1" *ngIf="!userService.userInfo" (click)="showLogin = !showLogin">
          <img src="/assets/images/global/user.svg">
        </div>
        <div class="col-1" *ngIf="userService.userInfo" [routerLink]="'/' + RouteEnum[getCurrentLang()].Account">
          <img src="/assets/images/global/user.svg">
        </div>
        <div class="col-1">
          <div class="cart__btn--container">
            <img src="/assets/images/global/cart.svg" [routerLink]="'/' + RouteEnum[getCurrentLang()].Cart">
            <span *ngIf="cartObjCount" class="cart__count"
                  [routerLink]="'/' + RouteEnum[getCurrentLang()].Cart">{{cartObjCount}}</span>
          </div>
        </div>
        <div class="col-1">
          <p class="header__lang--toggle"
             (click)="toggleTranslation(translationService.instant('lang'))">{{'lang_display' | translate}}</p>
        </div>
      </div>
    </div>
  </div>
  <div class="header__nav--container text-center">
    <div class="container__max-width">
      <p class="header__nav--item"
         [routerLink]="'/' + RouteEnum[getCurrentLang()].Category">{{'all_categories' | translate}}</p>
      <p class="header__nav--item" *ngFor="let category of categoryList.slice(0,6)"
         [routerLink]="['/' + getCurrentLang() + '/sub-category/' + category?.path , category?.name.replaceAll(' ','-')]">{{category?.name}}</p>
    </div>
  </div>
  <div *ngIf="unavailableOrders?.orderTotals > 0 && !isMobile" class="unavailable_banner fade-in" (click)="onUnavailableBanner()">
    {{'unavailable_banner' | translate}}
  </div>
</div>


<div id="header__mobile" class="header__mobile--container" *ngIf="isMobile">
  <img class="mobile__menu" src="/assets/images/global/side-menu.svg" (click)="triggerMobileSideMenu()">
  <img class="mobile__logo" src="/assets/images/global/logo.svg" [routerLink]="'/' + RouteEnum[getCurrentLang()].Home"
       (click)="showMobileSideMenu = false">
  <div class="header__search-bar">
    <input class="header__search-input"
           placeholder="{{'search' | translate}}"
           type="text"
           aria-label="Number"
           matInput
           [(ngModel)]="searchInput"
           [matAutocomplete]="auto"
           (ngModelChange)="searchForProduct()"
           (keyup.enter)="navigateToProduct($event.target.value)">
    <mat-autocomplete #auto="matAutocomplete">
      <mat-option *ngFor="let option of searchResults" [value]="option.product_id" [attr.data-productId]="option.value"
                  (click)="navigateToProduct(option.product_id)">
        {{option.name}}
      </mat-option>
    </mat-autocomplete>
    <img class="search__bar--icon" src="/assets/images/global/Search.svg">
  </div>
  <div class="cart__btn--container">
    <img class="mobile__cart" src="/assets/images/global/cart.svg" [routerLink]="'/' + RouteEnum[getCurrentLang()].Cart"
         (click)="showMobileSideMenu = false">
    <span *ngIf="cartObjCount" class="cart__count"
          [routerLink]="'/' + RouteEnum[getCurrentLang()].Cart">{{cartObjCount}}</span>
  </div>
</div>

<div *ngIf="unavailableOrders?.orderTotals > 0 && isMobile" class="unavailable_banner_mobile fade-in"
     (click)="onUnavailableBanner()">
  {{'unavailable_banner' | translate}}
</div>

<div class="mobile__side--menu"
     [ngClass]="{'slide-in-left': translationService.instant('dir') === 'en', 'slide-in-right': translationService.instant('dir') === 'ar'}"
     *ngIf="showMobileSideMenu">
  <div class="side__menu--list">
    <p class="side__menu--item" (click)="triggerMobileSideMenu()">
      <a [routerLink]="'/' + RouteEnum[getCurrentLang()].Account">
        {{'menu_account' | translate}}
      </a>
    </p>
    <p class="side__menu--item" (click)="triggerMobileSideMenu()">
      <a [routerLink]="'/' + RouteEnum[getCurrentLang()].Contact">
        {{'menu_contact' | translate}}
      </a>
    </p>
    <!-- <p class="side__menu&#45;&#45;item" (click)="triggerMobileSideMenu()">
       <a [routerLink]="'/' + RouteEnum[getCurrentLang()].Account">
         {{'menu_about' | translate}}
       </a>
     </p>-->
    <p class="side__menu--item" (click)="triggerMobileSideMenu()">
      <a [routerLink]="'/' + RouteEnum[getCurrentLang()].Privacy">
        {{'menu_privacy' | translate}}
      </a>
    </p>
    <p class="side__menu--item" (click)="triggerMobileSideMenu()">
      <a [routerLink]="'/' + RouteEnum[getCurrentLang()].Terms">
        {{'menu_terms' | translate}}
      </a>
    </p>
    <p class="side__menu--item" (click)="logOut(); triggerMobileSideMenu();" *ngIf="userService.userInfo">
      <a>
        {{'menu_logout' | translate}}
      </a>
    </p>
    <p class="side__menu--item" (click)="navigateToLogin();" *ngIf="!userService.userInfo">
      <a>
        {{'menu_login' | translate}}
      </a>
    </p>
    <p class="side__menu--item" (click)="triggerMobileSideMenu()">
      <a class="lang" (click)="toggleTranslation(translationService.instant('lang'))">{{'lang_display' | translate}}</a>
    </p>
  </div>
</div>

<app-login *ngIf="showLogin" [showLogin]="showLogin" (closeLogin)="showLogin = false"></app-login>
