import {Component, Input, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-product-hor-listing',
  templateUrl: './product-hor-listing.component.html',
  styleUrls: ['./product-hor-listing.component.scss']
})
export class ProductHorListingComponent implements OnInit {
  public slideConfig;
  @Input() productArr = [];
  @Input() link = [];
  @Input() deals = false;
  @Input() title: string;
  @Input() showAll = true;

  constructor(public translate: TranslateService) {
    translate.get('carousel_rtl').subscribe((text: string) => {
      const carouselDir = text === 'true';
      this.slideConfig = {
        draggable: true,
        accessibility: false,
        variableWidth: true,
        arrows: true,
        dots: false,
        swipeToSlide: true,
        infinite: false,
        rtl: carouselDir
      };
    });
  }

  ngOnInit(): void {
  }

  getCurrentLang(): string {
    return localStorage.getItem('lang') || 'ar';
  }
}
