import {Component, Input, OnInit, Renderer2} from '@angular/core';
import {BrowserService} from '../../services/browser.service';
import {TranslateService} from '@ngx-translate/core';
import {RouteEnum} from '../../enums/routes.enum';
import {CategoryService} from '../../services/category.service';
import {ProductService} from '../../services/product.service';
import {ActivatedRoute, Router} from '@angular/router';
import {UserService} from '../../services/user.service';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  public RouteEnum = RouteEnum;
  public isMobile = this.browserService.isMobileDevice();
  public showLogin = false;
  public categoryList = [];
  public searchInput = '';
  public searchResults: any;
  public unavailableOrders: any;
  @Input() cartObjCount: number;

  public showMobileSideMenu = false;

  constructor(private browserService: BrowserService,
              private translateService: TranslateService,
              private categoryService: CategoryService,
              private productService: ProductService,
              private router: Router,
              private route: ActivatedRoute,
              public userService: UserService,
              public translationService: TranslateService,
              private renderer: Renderer2) {
  }

  ngOnInit(): void {
    this.router.events.subscribe(() => {
      this.showMobileSideMenu = false;
    });
    this.browserService.setLoading(true);
    this.categoryService.getAllCategories().subscribe(
      (res) => {
        this.browserService.setLoading(false);
        this.categoryList = res.categories;
      },
      (err) => {
        this.browserService.setLoading(false);
      }
    );

    this.checkIfUserHasUnavailableOrder();

  }

  public checkIfUserHasUnavailableOrder(): void {
      this.productService.getUnavailableOrdersForUser().subscribe(res => {
        (window as any).hasUnavailableOrders = Number(res.orderTotals) > 0;
        if (Number(res.orderTotals) > 0) {
          this.userService.modifyElementStyles();
        }
        this.unavailableOrders = res;
      });
  }

  public toggleTranslation(lang): void {
    let url = window.location.href;
    if (url.includes('/en/')) {
      url = url.replace('/en/', '/ar/');
    } else if (url.includes('/ar/')) {
      url = url.replace('/ar/', '/en/');
    } else {
      const domain = window.location.origin;
      const defaultLang = 'ar';
      url = `${domain}/${defaultLang}${url}`;
    }
    window.location.href = url;
  }

  public searchForProduct(): void {
    this.productService.searchSuggestForProduct(this.searchInput).subscribe(
      (res) => {
        this.searchResults = res.search_data;
        if (this.searchResults) {
          this.searchResults.push(
            {product_id: this.searchInput, name: this.translateService.instant('search_view_all') + this.searchInput});
        }
      },
      (err) => {

      }
    );
  }

  public navigateToProduct(productId): void {
    this.router.navigate([`/${localStorage.getItem('lang')}/product/`, productId]);
    this.searchInput = '';
  }

  public triggerMobileSideMenu(): void {
    if (this.showMobileSideMenu) {
      this.showMobileSideMenu = false;
      this.renderer.removeClass(document.body, 'modal-open');
    } else {
      this.showMobileSideMenu = true;
      this.renderer.addClass(document.body, 'modal-open');
    }
  }

  public logOut(): void {
    localStorage.removeItem(' wk_token');
    localStorage.removeItem('wk_token');
    this.showMobileSideMenu = false;
    location.reload();
  }

  public navigateToLogin(): void {
    this.browserService.navigateToLogin();
  }

  getCurrentLang(): string {
    return localStorage.getItem('lang') || 'ar';
  }

  public onUnavailableBanner(): void {
    const orderTotals = Number(this.unavailableOrders?.orderTotals);

    if (orderTotals > 1) {
      this.router.navigate([`${this.getCurrentLang()}/my-account`], { queryParams: { activeTab: 'order' } });
    }

    if (orderTotals === 1) {
      const orderId = this.unavailableOrders?.orderData?.[0].order_id;
      this.router.navigate([`${this.getCurrentLang()}/replace/order/${orderId}`]);
    }
  }
}
