<div class="contact__page--container page-min-height fade-in container__max-width" [class.header-margin-top]="!isMobileWebView">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <div class="contact__page--info">
          <p class="contact__title">{{'contact_us_title' | translate}}</p>
            <p class="contact__desc">{{'contact_us_desc' | translate}}
              <a href="tel: +96265814168"><span class="cc__number">{{'cc_number' | translate}}</span></a>
            </p>
        </div>

        <form [formGroup]="contactForm" (ngSubmit)="submitContactUs()">
          <div class="contact__inputs--wrapper">
            <div class="contact_input--container">
              <input placeholder="{{'contact_fullname' | translate}}" formControlName="name" class="contact__input--field">
            </div>
            <div class="contact_input--container">
              <input placeholder="{{'contact_mobile' | translate}}" formControlName="mobile" class="contact__input--field">
            </div>
            <div class="contact_input--container">
              <input placeholder="{{'contact_email' | translate}}" formControlName="email" class="contact__input--field">
            </div>
            <div class="contact_input--container message">
              <p class="contact__msg--title">{{'contact_message' | translate}}</p>
              <textarea placeholder="{{'contact_msg_placeholder'  | translate}}" formControlName="message" class="contact__input--field textarea"></textarea>
            </div>
          </div>
          <button class="contact__submit--btn" [disabled]="!contactForm?.valid">{{'send' | translate}}</button>
        </form>
      </div>
    </div>
  </div>
</div>
