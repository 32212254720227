<div
  class="account__page--container fade-in page-min-height global-padding-bottom container__max-width header-margin-top">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-4">
        <div class="account__nav--container">
          <p class="account__nav--element" (click)="changeToggle('basic-info')"
             [class.active]="activeToggle === 'basic-info'">{{'basic_info' | translate}}</p>
          <p class="account__nav--element" (click)="changeToggle('address')"
             [class.active]="activeToggle === 'address'">{{'addresses' | translate}}</p>

          <p class="account__nav--element" (click)="changeToggle('cards')"
             [class.active]="activeToggle === 'cards'">{{'manage_cards' | translate}}</p>
          <p class="account__nav--element" (click)="changeToggle('order')"
             [class.active]="activeToggle === 'order'">{{'order_history' | translate}}</p>
          <p class="account__nav--element" (click)="changeToggle('loyalty')"
             [class.active]="activeToggle === 'loyalty'">{{'profile_loyalty' | translate}}</p>
          <p class="account__nav--element" (click)="logOut()">{{'profile_logout' | translate}}</p>
        </div>
      </div>
      <div class="col-md-8">
        <div class="account__outlet--container">
          <app-basic-info *ngIf="activeToggle === 'basic-info'"></app-basic-info>
          <app-address-container *ngIf="activeToggle === 'address'"></app-address-container>
          <app-manage-cards *ngIf="activeToggle === 'cards'"></app-manage-cards>
          <app-order-history *ngIf="activeToggle === 'order'"></app-order-history>
          <app-loyalty-card *ngIf="activeToggle === 'loyalty'"></app-loyalty-card>
        </div>
      </div>
    </div>
  </div>
</div>
