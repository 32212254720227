import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, Renderer2} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Observable, of} from 'rxjs';
import {AddressService} from '../../services/address.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';
import {BrowserService} from '../../services/browser.service';
import {DEFAULT_COORDS} from '../../../constants';
import LatLngLiteral = google.maps.LatLngLiteral;

@Component({
  selector: 'app-address-map-popup',
  templateUrl: './address-map-popup.component.html',
  styleUrls: ['./address-map-popup.component.scss']
})
export class AddressMapPopupComponent implements OnInit, OnDestroy {
  public addressForm: FormGroup;
  public areasArr: any;
  public lat;
  public defaultLat;
  public lng;
  public defaultLng;
  public latLngArr;
  public centerLatitude;
  public centerLongitude;
  public zoom = 17;
  public polygonPaths = [];
  polygonPaths$: Observable<Array<any>>;
  public zoneName = '';
  public isDeliverable = false;
  public isShowForm = false;
  public isValidForm;
  public selectedType;
  public isMobile = this.browserService.isMobileDevice();
  public navbarHeight;
  public isRTL = this.translateService.instant('dir') === 'ar';
  @Input() title: string;
  @Input() addressData: any;
  @Input() isEditAddress: boolean;
  @Output() showAddress: EventEmitter<boolean> = new EventEmitter();
  @Output() updatedAddress: EventEmitter<boolean> = new EventEmitter();

  constructor(private renderer: Renderer2, private fb: FormBuilder, private addressService: AddressService, public snackBar: MatSnackBar,
              public translateService: TranslateService, public browserService: BrowserService) {
  }

  ngOnInit(): void {
    if (!this.isMobile) {
      document.getElementById('desktop-header').style.position = 'unset';
    }

    if (this.isEditAddress) {
      this.isShowForm = true;
      this.latLngArr = this.addressData.latlng;
      this.lat = Number(this.latLngArr?.split(',')[0]);
      this.lng = Number(this.latLngArr?.split(',')[1]);
      this.selectedType = this.addressData.address_type;
    } else {
      this.isShowForm = false;
    }

    if (this.isMobile) {
      const headerElement = document.getElementById('header__mobile');
      const navigationElement = document.getElementById('mobile__menu');
      this.renderer.addClass(headerElement, 'hide');
      this.renderer.addClass(navigationElement, 'hide');
      this.renderer.addClass(document.body, 'modal-open');
      this.navbarHeight = window.outerHeight - window.innerHeight;
    }

    if (this.isEditAddress === false) {
      this.addressService.getCurrentLocation().subscribe(res => {
        this.lat = res.lat;
        this.lng = res.lng;
        this.defaultLat = res.lat;
        this.defaultLng = res.lng;
        this.getDeliveryZoneList();

      }, () => {
        this.lat = DEFAULT_COORDS.lat;
        this.lng = DEFAULT_COORDS.lng;
        this.defaultLat = DEFAULT_COORDS.lat;
        this.defaultLng = DEFAULT_COORDS.lng;
        this.getDeliveryZoneList();
      });
    }

    this.addressForm = this.fb.group({
      address_name: [this.addressData ? this.addressData.address_name : '', Validators.required],
      address_type: [this.addressData ? this.addressData.address_type : ''],
      building_number: [this.addressData ? this.addressData.building_number : '', Validators.required],
      city: [this.addressData ? this.addressData.city : this.zoneName],
      country: [this.addressData ? this.addressData.country : ''],
      country_id: [this.addressData ? this.addressData.country_id : ''],
      default_address: [this.addressData ? this.addressData.default_address : false],
      floor_number: [this.addressData ? this.addressData.floor_number : '', Validators.required],
      latlng: [this.addressData ? this.addressData.latlng : ''],
      phone_number: [this.addressData ? this.addressData.phone_number : '', Validators.required],
      street: [this.addressData ? this.addressData.street : '', Validators.required],
      zone_id: [this.addressData ? this.addressData.zone_id : '']
    });

    if (this.isEditAddress && this.addressForm.valid) {
      this.isValidForm = true;
      this.checkValidationForms();
    }
  }


  ngOnDestroy(): void {
    if (this.isMobile) {
      const headerElement = document.getElementById('header__mobile');
      const navigationElement = document.getElementById('mobile__menu');
      this.renderer.removeClass(headerElement, 'hide');
      this.renderer.removeClass(navigationElement, 'hide');
    }
    this.renderer.removeClass(document.body, 'modal-open');

    if (!this.isMobile) {
      document.getElementById('desktop-header').style.position = 'fixed';
    }
  }


  public getDeliveryZoneList(): void {
    if (this.isShowForm) {
      return null;
    }
    this.addressService.getDeliveryZones().subscribe(
      (res) => {
        res.country_data[0].zone.forEach((zone) => {
          this.polygonPaths.push({
            country: res.country_data[0].name,
            country_id: res.country_data[0].country_id,
            zone_id: zone.zone_id,
            zone_name: zone.name,
            polygon: zone.polygon
          });
        });

        this.polygonPaths$ = of(this.polygonPaths);
        this.markerDragEnd();
      },
      (err) => {
        console.error(err);
      }
    );

  }

  public markerDragEnd(): void {
    this.polygonPaths$.subscribe(polygonPaths => {
      try {
        const markerLatLng = new google.maps.LatLng(this.lat, this.lng);
        for (const path of polygonPaths) {
          const polygon = new google.maps.Polygon({paths: path.polygon});
          if (google.maps.geometry.poly.containsLocation(markerLatLng, polygon)) {
            this.isDeliverable = true;
            this.addressForm.patchValue({
              country: path.country,
              country_id: path.country_id,
              city: path.zone_name,
              zone_id: path.zone_id,
              latlng: `${this.lat},${this.lng}`
            });
            this.getStreetName(this.lat, this.lng);
            break;
          } else {
            this.isDeliverable = false;
          }
        }
      } catch (error) {
        console.error(error);
      }
    });

    if (!this.isDeliverable) {
      this.snackBar.open(this.translateService.instant('non_deliverable'), this.translateService.instant('snack_bar_close'), {
        duration: 4000,
        panelClass: this.translateService.instant('dir')
      });
    }

  }

  public mapReady(map): void {
    map.addListener('dragend', () => {
      this.lat = this.centerLatitude;
      this.lng = this.centerLongitude;
      this.markerDragEnd();
    });

    if (map) {
      map.setOptions({
        streetViewControl: false,
        fullscreenControl: false
      });

      setTimeout(() => {
        const zoomIn = document.querySelector('[title="Zoom in"]');
        const zoomOut = document.querySelector('[title="Zoom out"]');
        // @ts-ignore
        zoomIn.style.touchAction = "none"
        // @ts-ignore
        zoomOut.style.touchAction = "none"
      }, 1300);

    }

    if (navigator.geolocation && map) {
      const myLocationControlDiv = document.createElement('div');
      myLocationControlDiv.className = 'my-location-control';
      const myLocationControlIcon = document.createElement('img');
      myLocationControlIcon.src = '/assets/images/global/gps-icon.svg';
      myLocationControlIcon.alt = 'My location';
      myLocationControlDiv.appendChild(myLocationControlIcon);
      map.controls[google.maps.ControlPosition.RIGHT_CENTER].push(myLocationControlDiv);

      myLocationControlDiv.addEventListener('click', () => {
        navigator.geolocation.getCurrentPosition((position) => {
          const geocoder = new google.maps.Geocoder();
          const latLng = new google.maps.LatLng(position.coords.latitude, position.coords.longitude);

          geocoder.geocode({location: latLng}, (results, status) => {
            if (status === 'OK' && results[0]) {
              this.lat = position.coords.latitude;
              this.lng = position.coords.longitude;
              this.markerDragEnd();
            }
          });
        });
      });
    }
  }

  public centerChanged(coords: LatLngLiteral): void {
    this.centerLatitude = coords.lat;
    this.centerLongitude = coords.lng;
  }


  public onConfirmLocation(): void {
    if (this.isDeliverable) {
      this.isShowForm = true;
      this.checkValidationForms();
    }
  }


  public checkValidationForms(): void {
    this.addressForm.statusChanges.subscribe(status => {
      if (status === 'VALID') {
        this.isValidForm = true;
      } else if (status === 'INVALID') {
        this.isValidForm = false;
      }
    });
  }


  onToggleAddressTypes(typeNum: number): void {
    if (typeNum === this.selectedType) {
      this.addressForm.patchValue({address_type: ''});
      this.selectedType = -1;
    } else {
      this.addressForm.patchValue({address_type: typeNum});
      this.selectedType = typeNum;
    }

  }


  public getStreetName(lat: number, lng: number): void {
    this.addressService.getAddressInfo(lat, lng).subscribe(data => {
      const address = data.results[0].address_components;
      for (const component of address) {
        if (component.types[0] === 'route') {
          this.addressForm.patchValue({street: component.long_name});
        }
      }
    });
  }

  public viewMap(): void {
    if (this.isEditAddress) {
      this.isShowForm = false;
      this.getDeliveryZoneList();
    } else {
      this.isShowForm = false;
    }
  }


  public submitAddress(): void {
    if (!this.isValidForm) {
      return null;
    }
    if (this.addressData) {
      this.addressForm.value.address_id = this.addressData.address_id;
    }
    this.addressService.addAddress(this.addressForm.value).subscribe(
      (res) => {
        this.snackBar.open(res.message, this.translateService.instant('snack_bar_close'), {
          duration: 6000,
          panelClass: this.translateService.instant('dir')
        });
        this.updatedAddress.emit(true);
        this.showAddress.emit(false);
        this.ngOnDestroy();
      },
      (err) => {
        this.snackBar.open(err.error.error.message, this.translateService.instant('snack_bar_close'), {
          duration: 6000,
          panelClass: this.translateService.instant('dir')
        });
      }
    );
  }


  public deleteAddressBtn(): void {
    this.addressService.deleteAddress(this.addressData.address_id).subscribe(
      (res) => {
        this.snackBar.open(res.message, this.translateService.instant('snack_bar_close'), {
          duration: 6000,
          panelClass: this.translateService.instant('dir')
        });
        this.updatedAddress.emit(true);
        this.showAddress.emit(false);
        this.ngOnDestroy();
      },
      (err) => {
        this.snackBar.open(err.error.error.message, this.translateService.instant('snack_bar_close'), {
          duration: 6000,
          panelClass: this.translateService.instant('dir')
        });
      }
    );
  }
}
