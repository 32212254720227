<div class="cookie__banner--container slide-in-bottom" *ngIf="showCookie">
  <div class="container__max-width">
    <p class="cookie__text">
      {{'cookie__text' | translate}}
      <a [routerLink]="'/privacy-policy'" style="font-family: fontHeavy; color: #FFFFFF">{{'privacy_policy_cookie' | translate}}</a>
    </p>
    <button class="cookie__btn" (click)="hideCookie()">
      {{'accept' | translate}}
    </button>
  </div>
</div>
