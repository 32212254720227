import {Component, Input, OnInit} from '@angular/core';
import {AnimationOptions} from 'ngx-lottie';

@Component({
  selector: 'app-empty-page',
  templateUrl: './empty-page.component.html',
  styleUrls: ['./empty-page.component.scss']
})
export class EmptyPageComponent implements OnInit {
  options: AnimationOptions = {
    path: '/assets/images/lottie/no-result.json',
  };
  constructor() {
  }

  @Input() pageTitle;

  ngOnInit(): void {
  }

}
