import { Injectable } from '@angular/core';
import {ApiService} from './api.service';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  constructor(private apiService: ApiService) {
  }

  public getAllCategories(): Observable<any> {
    const url = `common/getAllCategories&width=${600}`;
    return this.apiService.get(url);
  }

  public getSubCategories(categoryId, width?): Observable<any> {
    const url = `common/getSubCategory&category_id=${categoryId}&width=${width}`;
    return this.apiService.get(url);
  }
}
