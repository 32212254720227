import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  public CSRF_HEADER_NAME = 'X-Csrf-Token';

  constructor(
    private httpClient: HttpClient
  ) { }



  /**
   * Extended wrapper for delete requests
   * @param url
   * @param options
   * @returns {Observable<any>}
   */
  public delete(
    url: string,
    options?
  ): Observable<any> {
    return this.httpClient.delete(
      this._createApiPrefix() + url,
      this.extendOptions()
      );
  }

  /**
   * Extended wrapper for post requests
   * @param url
   * @param body
   * @param options
   * @returns {Observable<any>}
   */
  public post(
    url: string,
    body: any,
    options?
  ): Observable<any> {
    return this.httpClient.post(
      this._createApiPrefix() + url,
      body, this.extendOptions());
  }

  /**
   * Extended wrapper for upload requests
   * @param url
   * @param body
   * @param options
   * @returns {Observable<any>}
   */
  public postToken(
    url: string,
    body: any,
    options?
  ): Observable<any> {
    return this.httpClient.post(
      this._createApiPrefix() + url,
      body, this.extendOptionsToken());
  }

  /**
   * Extended wrapper for put requests
   * @param url
   * @param body
   * @param options
   * @returns {Observable<any>}
   */
  public put(
    url: string,
    body: any,
    options?
  ): Observable<any> {
    return this.httpClient.put(
      this._createApiPrefix() + url,
      body, this.extendOptions());
  }

  /**
   * Extended wrapper for delete requests
   * @param url
   * @param options
   * @returns {Observable<any>}
   */
  /*public delete(
    url: string,
    options?
  ): Observable<any> {
    return this.httpClient.delete(
      this._createApiPrefix() + url,
      this.extendOptions({
        Authorization: 'Bearer ' + this.cookieService.get('access_token')
      }));
  }*/

  /**
   * Extended wrapper for get requests
   * @param url
   * @param options
   * @returns any
   */
  public get(
    url: string,
    options?
  ): Observable<any> {
    return this.httpClient.get(this._createApiPrefix() + url, this.extendOptions(options));
  }

  /**
   * Extend and add default requests options (headers)
   * @param options
   * @param body
   * @returns any
   */
  public extendOptions(options?, body?: string): any {
    const extendedOptions = options || {};
    let headers = extendedOptions.headers || new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json; charset=UTF-8');
    if (localStorage.getItem('lang')) {
      if (localStorage.getItem('lang') === 'en') {
        headers = headers.append('lang', 'en-gb');
      } else {
        headers = headers.append('lang', localStorage.getItem('lang'));
      }
    }
    if (localStorage.getItem('wk_token')) {
      headers = headers.append('wk_token', localStorage.getItem('wk_token'));
      headers = headers.append('Wk-Token', localStorage.getItem('wk_token'));
    }
    headers = headers.append('platform', 'web');
    /*headers = headers.append('wk_token', localStorage.getItem('wk_token'));
    headers = headers.append('Wk-Token', localStorage.getItem('wk_token'));*/
    extendedOptions.headers = headers;

    extendedOptions.body = extendedOptions.body || body || '';
    return extendedOptions;
  }

  /**
   * Extend and add default requests options (headers)
   * @param options
   * @param body
   * @returns any
   */
  public extendOptionsToken(options?, body?: string): any {
    const extendedOptions = options || {};
    let headers = extendedOptions.headers || new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    extendedOptions.headers = headers;

    extendedOptions.body = extendedOptions.body || body || '';
    return extendedOptions;
  }

  /**
   * Create url path to API without action information
   *
   * @private
   * @returns {string}
   */
  private _createApiPrefix(): string {
    return environment.api.server /*+ environment.api.folder + environment.api.version*/;
  }
}
