import {Component, OnInit} from '@angular/core';
import {CheckoutService} from "../../services/checkout.service";
import {ICardData} from "../../intefaces/Payment";

@Component({
  selector: 'app-manage-cards',
  templateUrl: './manage-cards.component.html',
  styleUrls: ['./manage-cards.component.scss']
})
export class ManageCardsComponent implements OnInit {
  public savedCards: ICardData[];
  public openCheckPopup = false;

  constructor(private checkoutService: CheckoutService) {
  }

  ngOnInit(): void {
    this.getSavedCards();
  }

  public getCurrentLang(): string {
    return localStorage.getItem('lang') || 'ar';
  }

  public getSavedCards(): void {
    this.checkoutService.getSavedCards().subscribe(res => {
      this.savedCards = res?.saved_cards;
    })
  }

  public deleteCard(regId): void {

    this.checkoutService.deleteSavedCards(regId).subscribe(res => {
      if (res) {
        window.location.href = window.location.href + '?activeTab=cards'
      }
    })
  }

}
