import {Component, OnInit} from '@angular/core';
import {UserService} from '../../services/user.service';
import {BrowserService} from '../../services/browser.service';
import {CheckoutService} from "../../services/checkout.service";
import {ActivatedRoute} from "@angular/router";
import {MatSnackBar} from "@angular/material/snack-bar";
import {TranslateService} from "@ngx-translate/core";

export enum PaymentStatusEnum {
  SUCCESS_PA = "SUCCESS_PA",
  SUCCESS_CP = "SUCCESS_CP",
  SUCCESS_DB = "SUCCESS_DB",
  SUCCESS_RF = "SUCCESS_RF",
  PENDING = "PENDING",
  FAILED = "FAILED"
}

@Component({
  selector: 'app-order-history',
  templateUrl: './order-history.component.html',
  styleUrls: ['./order-history.component.scss']
})
export class OrderHistoryComponent implements OnInit {

  public orderHistory;

  constructor(private userService: UserService, public browserService: BrowserService,
              private checkoutService: CheckoutService, private route: ActivatedRoute,
              private snackBar: MatSnackBar, public translateService: TranslateService) {
  }

  ngOnInit(): void {
    this.getOrderHistory();
    this.checkPaymentStatusApi();
  }

  public getOrderHistory(): void {
    this.userService.getUserOrders().subscribe(
      (res) => {
        this.orderHistory = res.orderData;
      },
      (err) => {
        console.error(err);
      }
    );
  }

  public getCurrentLang(): string {
    return localStorage.getItem('lang') || 'ar';
  }

  public checkPaymentStatus(order): { bool: boolean, status: string } {
    switch (order?.payment_status) {
      case PaymentStatusEnum.SUCCESS_CP:
      case PaymentStatusEnum.SUCCESS_DB:
      case PaymentStatusEnum.SUCCESS_RF:
      case PaymentStatusEnum.SUCCESS_PA:
        return {bool: true, status: 'paid'};
      case PaymentStatusEnum.PENDING:
        return {bool: false, status: 'unpaid'};
      case PaymentStatusEnum.FAILED:
        return {bool: false, status: 'failed'};
      default:
        return {bool: false, status: 'unknown'};
    }
  }

  public checkPaymentStatusApi(): void {

    if (!window.location.search.includes("resourcePath")) {
      return
    }
    const params = this.route.snapshot.queryParams;
    const resourcePath = params?.resourcePath || null;
    const orderId = params?.orderId || ''
    if (resourcePath) {
      this.checkoutService.paymentStatus(resourcePath).subscribe(res => {
        this.snackBar.open(this.translateService.instant('success_payment_msg').replace('__ORDER_ID__', orderId), 'Close', {
          duration: 5000,
          horizontalPosition: 'center',
          verticalPosition: 'top',
          panelClass: ['custom-snackbar_success'],
        });

        const url = window.location.href;
        const cleanUrl = url.split('?')[0];
        window.history.replaceState({}, document.title, cleanUrl);

      }, error => {
        this.snackBar.open(this.translateService.instant('error_payment_msg').replace('__ORDER_ID__', orderId), 'Close', {
          duration: 5000,
          horizontalPosition: 'center',
          verticalPosition: 'top',
          panelClass: ['custom-snackbar_error'],
        });

        const url = window.location.href;
        const cleanUrl = url.split('?')[0];
        window.history.replaceState({}, document.title, cleanUrl);
      })
    }
  }

}
