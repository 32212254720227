import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {RouteEnum} from '../../enums/routes.enum';
import {CheckoutService} from '../../services/checkout.service';
import {CartService} from '../../services/cart.service';
import {TranslateService} from '@ngx-translate/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Router} from "@angular/router";
import {ICartView} from "../../intefaces/Cart";

@Component({
  selector: 'app-order-summary',
  templateUrl: './order-summary.component.html',
  styleUrls: ['./order-summary.component.scss']
})
export class OrderSummaryComponent implements OnInit, OnChanges {
  public RouteEnum = RouteEnum;

  @Input() cartDetails: ICartView;
  @Input() checkOutObj: any;
  @Input() deliveryFee;
  @Input() serviceFee;
  @Input() isCheckout = false;
  @Input() allowCheckout = false;
  @Input() isEnablePlaceOrder = false;

  couponValue = '';


  constructor(private checkoutService: CheckoutService,
              private cartService: CartService,
              private translateService: TranslateService,
              private snackBar: MatSnackBar,
              private router: Router,
  ) {
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.checkIfCouponApplied();
    this.checkIfFreeDelivery();
  }

  public triggerCheckout(): void {
    this.checkoutService.postCheckout(this.checkOutObj).subscribe(
      (res) => {

      }, (err) => {

      }
    );
  }


  public checkIfCouponApplied(): void {
    if (this.cartDetails?.coupon) {
      this.couponValue = this.cartDetails.coupon.name;
    }
  }

  private checkIfFreeDelivery(): void {
    if (this.cartDetails?.coupon?.shipping) {
      this.deliveryFee = 0;
    }
  }

  getCurrentLang(): string {
    return localStorage.getItem('lang') || 'ar';
  }

  public onContinueToCheckOut(): void {
    if (this.cartDetails?.checkout_eligible === false) {
      this.snackBar.open(this.cartDetails?.error_warning, this.translateService.instant('snack_bar_close'), {
        duration: 3500,
        panelClass: this.translateService.instant('dir')
      });
    } else {
      this.router.navigate([`${this.getCurrentLang()}/checkout`]);
    }
  }

  public calculateGrandTotalAndDiscount(): { grandTotal: number, discount: number } {
    let grandTotal = this.cartDetails?.total_price + (this.deliveryFee ? this.deliveryFee : 0) + (this.serviceFee ? this.serviceFee : 0);
    let discount = 0;
    if (this?.cartDetails?.coupon?.applied_coupon && this.deliveryFee) {
      if (this.cartDetails.coupon.coupon_free_delivery_fees) {
        grandTotal = grandTotal - this.deliveryFee;
        discount = this.deliveryFee;
      }
      if (this.cartDetails.coupon.coupon_free_service_fees && this.serviceFee) {
        grandTotal = grandTotal - this.serviceFee;
        discount = discount + this.serviceFee;
      }
    }

    return {grandTotal, discount};
  }
}
