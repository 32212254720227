<div class="sub__page--container fade-in container__max-width header-margin-top" *ngIf="productsOfSubCategory">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-4">
        <div class="mobile__filter__wrapper slide-in-bottom" *ngIf="isMobile && showFilter">
          <app-filter (filterCategoryId)="getProducts($event)" (toggleModal)="showFilter = $event"
                      [subCategoryList]="subCategoryList" [productCategoryData]="productsOfSubCategory" (priceFilterArr)="priceArr = $event; sortByPriceRange()"></app-filter>
        </div>
        <div class="desktop__filter__wrapper" *ngIf="!isMobile">
          <app-filter (filterCategoryId)="getProducts($event)" [productCategoryData]="productsOfSubCategory" (priceFilterArr)="priceArr = $event; sortByPriceRange()" [subCategoryList]="subCategoryList"></app-filter>
        </div>
      </div>
      <div class="col-md-8">
        <div class="results__nav--container">
          <app-results-header (toggleModal)="showFilter = $event" [title]="productsOfSubCategory?.name"
                              [resultCount]="productsOfSubCategory?.product_total"
                              [sortOptions]="sortOptions"
                              (selectedSort)="sortByUserSelect($event)"></app-results-header>
        </div>
        <app-empty-page *ngIf="!productsOfSubCategory?.products?.length" [pageTitle]="translateService.instant('search')"></app-empty-page>
        <div infiniteScroll
             [infiniteScrollDistance]="7"
             [infiniteScrollThrottle]="50"
             (scrolled)="loadMoreProducts()"
             *ngIf="productsOfSubCategory" class="results__container">
          <ng-container *ngFor="let product of productsOfSubCategory?.products; let i = index">
            <div class="sub--single_product">
              <app-single-product [productData]="product"></app-single-product>
            </div>
            <div class="sub-category__app--wrapper" *ngIf="((i+1) % 10) === 0 && i > 0 && productsOfSubCategory?.banners">
              <app-ad-banner [advert]="productsOfSubCategory.banners"></app-ad-banner>
            </div>
          </ng-container>
        </div>
        <app-load-more-indicator *ngIf="showLoading"></app-load-more-indicator>
      </div>
    </div>
  </div>
</div>
