<div class="cart__listing--container">
  <div *ngIf="!isCheckout && !isReplaced" class="cart__listing--header">
    <div class="container-fluid>">
      <div class="row">
        <div class="col-md-6">
          <p class="cart__listing--title">{{'product_name' | translate}}</p>
        </div>
        <div class="col-md-2 text-center">
          <p class="cart__listing--title">{{'product_price' | translate}}</p>
        </div>
        <div class="col-md-2 text-center">
          <p class="cart__listing--title">{{'product_quantity' | translate}}</p>
        </div>
        <div class="col-md-2 text-center">
          <p class="cart__listing--title">{{'product_total' | translate}}</p>
        </div>
      </div>
    </div>
  </div>


  <div class="cart__listing--body" *ngIf="!browserService.isMobileDevice()">
    <div class="container-fluid">
      <p class="cart__product--count" *ngIf="isCheckout">{{'total_products' | translate}}: {{cartItems?.length}}</p>
      <div class="row cart__listing--row" *ngFor="let item of cartItems"
           [class.product__disabled]="item?.stock === false"
           (click)="linkToSubCategory(item)" [ngStyle]="{'cursor': item?.stock === false && 'pointer'  }">

        <div class="col-md-6">
          <div class="cart__listing--item d-flex align-items-center">
            <div class="cart__item--actions" *ngIf="!isCheckout && !isOrderDetails">
              <i class="fa fa-trash item__delete" aria-hidden="true"
                 (click)="removeProductFromCart(item?.key , $event)"></i>
            </div>
            <img onError="this.src='/assets/images/global/re-order.svg'" *ngIf="isOrderDetails && isDelivered"
                 src="/assets/images/global/replace-order.svg" class="reorder__item--image" (click)="reorder.emit(item)"/>
            <img onError="this.src='/assets/images/global/no-img.png'" *ngIf="item?.thumb || item?.image"
                 class="cart__item--image"
                 src="{{item?.thumb || item?.image}}"/>

            <div class="cart__item--name" [class.cart__item--name-full]="!item?.thumb && !item?.image"
                 [class.product__disabled]="item?.stock === false">
              {{item?.name}}
              <span *ngIf="isCheckout" class="checkout__custom--qty">
                {{'quantity' | translate}}: {{item?.quantity}}
              </span>
              <ng-container *ngFor="let option of item?.options">
                <span *ngIf="option?.option_id === '16'" class="checkout__custom--note"
                      [class.product__disabled]="item?.stock === false">
                  {{item?.options[0].value ? '*' + item?.options[0].value : ''}}
                </span>
              </ng-container>
              <span *ngIf="isReplaced" class="replacement">
                 {{'replacement' | translate}}
                </span>
            </div>

          </div>
        </div>


        <div class="col-md-2 text-center price">
          <p class="cart__item--price" *ngIf="!isCheckout" [class.product__disabled]="item?.stock === false">
            {{item?.price}} {{'currency' | translate}}
          </p>
        </div>


        <div class="col-md-2 col-6 text-center disabled_qua" [class.product__disabled]="item?.stock === false">
          <div *ngIf="!isCheckout">
            <i class="fa fa-minus-circle item__plus" *ngIf="!isOrderDetails"
               (click)="changeQuantity(item.key,item.quantity,'minus')" aria-hidden="true"></i>
            <p class="cart__quantity--count" [class.product__disabled]="item?.stock === false">{{item?.quantity}}</p>
            <i class="fa fa-plus-circle item__minus" [class.product__disabled]="item?.stock === false"
               *ngIf="!isOrderDetails" (click)="changeQuantity(item.key,item.quantity,'add')"
               aria-hidden="true"></i>
          </div>
        </div>

        <div class="col-md-2 col-6 text-center">
          <p class="cart__item--total" *ngIf="item?.stock !== false">
            {{'currency' | translate}} {{item?.total}}
          </p>
          <p class="cart__item--total" *ngIf="item?.stock === false">
            <span style="color:#D91100">{{'out_of_stock' | translate}}</span>
          </p>
        </div>
      </div>
    </div>
  </div>

  <!------------------------------------------------- MOBILE----------------------------------------------->
  <div *ngIf="browserService.isMobileDevice()">
    <div *ngFor="let item of cartItems" (click)="linkToSubCategory(item)">

      <div class="d-flex align-items-center mb-4 cont-cart-mobile">
        <i *ngIf="!isCheckout && !isOrderDetails" class="fa fa-trash item__delete" aria-hidden="true"
           (click)="removeProductFromCart(item?.key , $event)"></i>

        <img onError="this.src='/assets/images/global/re-order.svg'" *ngIf="isOrderDetails && isDelivered"
             src="/assets/images/global/replace-order.svg" class="reorder__item--image" (click)="reorder.emit(item)"/>
        <div>
          <img onError="this.src='/assets/images/global/no-img.png'" *ngIf="item?.thumb || item?.image"
               src="{{item?.thumb || item?.image}}" width="80px" height="80px"/>
        </div>


        <div class="d-flex flex-column justify-content-between cont-title-price-mobile"
             [class.product__disabled]="item?.stock === false">

          <div class="title_price_mobile">
            <div class="cart__item--name" [class.cart__item--name-full]="!item?.thumb && !item?.image"
                 [class.product__disabled]="item?.stock === false">
              {{item?.name}} </div>

            <div class="price_qyt_mobile">
          <span>
           <span class="font-bold"> {{'price' | translate}}</span> : {{item?.price}} {{'currency' | translate}}
          </span>
              <span *ngIf="item?.weight?.isQuantity  || !item.weight?.weightInOptions">
              <span class="font-bold">{{'product_quantity' | translate}}</span>: {{item?.quantity}}
            </span>
              <span *ngIf="!item?.weight?.isQuantity && item.weight?.weightInOptions">
              <span class="font-bold">{{'weight' | translate}}</span>:  {{item?.weight_cart_label}} / {{item?.weight?.unit}}
            </span>
            </div>
            <div *ngIf="isReplaced" class="replacement">
              {{'replacement' | translate}}
            </div>
          </div>

          <div class="d-flex justify-content-between total_price_mobile">
            <div>{{'total_price' | translate}}</div>
            <div>{{item?.total}} {{'currency' | translate}}</div>
          </div>

        </div>
      </div>

      <div *ngIf="!isCheckout && !isOrderDetails" class="disabled_qua cont_qyt_change_mobile"
           [class.product__disabled]="item?.stock === false">
        <div class="qyt_change_mobile">
          <i class="fa fa-minus-circle item__plus" *ngIf="!isOrderDetails"
             (click)="changeQuantity(item.key,item.quantity,'minus')" aria-hidden="true"></i>
          <p class="cart__quantity--count" [class.product__disabled]="item?.stock === false">{{item?.quantity}}</p>
          <i class="fa fa-plus-circle item__minus" [class.product__disabled]="item?.stock === false"
             *ngIf="!isOrderDetails" (click)="changeQuantity(item.key,item.quantity,'add')"
             aria-hidden="true"></i>
        </div>
      </div>

    </div>

  </div>

</div>
