import {Component, OnInit} from '@angular/core';
import {CategoryService} from '../../services/category.service';
import {RouteEnum} from '../../enums/routes.enum';

@Component({
  selector: 'app-category-page',
  templateUrl: './category-page.component.html',
  styleUrls: ['./category-page.component.scss']
})
export class CategoryPageComponent implements OnInit {

  public categoryArr = [];
  public specialCategoryArr = [];
  public RouteEnum = RouteEnum;
  constructor(private categoryService: CategoryService) {
  }

  ngOnInit(): void {
    this.categoryService.getAllCategories().subscribe(
      (res) => {
        this.categoryArr = res.categories;
        this.specialCategoryArr = res.special_categories;
      },
      (err) => {

      }
    );
  }
  getCurrentLang(): string {
    return localStorage.getItem('lang') || 'ar';
  }
}
