import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {UserService} from '../../services/user.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-basic-info',
  templateUrl: './basic-info.component.html',
  styleUrls: ['./basic-info.component.scss']
})
export class BasicInfoComponent implements OnInit {

  public infoForm: FormGroup;
  public dateChange = false;
  minDate: Date;
  maxDate: Date;

  constructor(private fb: FormBuilder,
              private userService: UserService, public snackBar: MatSnackBar, public translateService: TranslateService) {
  }

  ngOnInit(): void {
    this.infoForm = this.fb.group({
      userFirstName: ['', Validators.required],
      userLastName: ['', Validators.required],
      userEmail: ['', [Validators.required, Validators.email]],
      phoneNumber: ['', [Validators.required, Validators.minLength(9)]],
      dateOfBirth: ['', Validators.required]
    });
    if (this.userService.userInfo) {
      this.infoForm.controls.userFirstName.setValue(this.userService.userInfo.firstname);
      this.infoForm.controls.userLastName.setValue(this.userService.userInfo.lastname);
      this.infoForm.controls.userEmail.setValue(this.userService.userInfo.email);
      this.infoForm.controls.phoneNumber.setValue(this.userService.userInfo.telephone);
      this.infoForm.controls.dateOfBirth.setValue(this.userService.userInfo.date_of_birth);
    }
    const currentYear = new Date().getFullYear();
    this.minDate = new Date(currentYear - 60, 0, 1);
    this.maxDate = new Date(currentYear, 11, 31);
  }

  public submitUserInfo(): void {
    if (this.infoForm.valid) {
      const formData = this.infoForm.value;
      const adjustedDate = new Date(formData.dateOfBirth);
      if (this.dateChange) {
        adjustedDate.setDate(adjustedDate.getDate() + 1);
      }
      const userObj = {
        firstname: formData.userFirstName,
        lastname: formData.userLastName,
        email: formData.userEmail,
        date_of_birth: adjustedDate.toISOString().split('T')[0],
        telephone: formData.phoneNumber
      };
      this.userService.editUserInfo(userObj).subscribe(
        (res) => {
          location.reload();
          this.snackBar.open(res.message, this.translateService.instant('snack_bar_close'), {
            duration: 3500,
            panelClass: this.translateService.instant('dir')
          });
        },
        (err) => {
          this.snackBar.open(err.error.error.message, this.translateService.instant('snack_bar_close'), {
            duration: 3500,
            panelClass: this.translateService.instant('dir')
          });
        }
      );
    }
  }

}
