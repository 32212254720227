<div class="login__popup--wrapper" *ngIf="slideConfig && showLogin">
  <div class="scale-in-center">
    <div class="login__popup--container">
      <img class="login__popup--close" (click)="showLogin = !showLogin; closeLogin.emit(false)"
           src="/assets/images/global/close.svg">
      <div *ngIf="loginStep === 'first'" class="login__first--step">
        <ngx-slick-carousel class="login__slider--container" [config]="slideConfig" dir="{{'html_dir' | translate}}">
          <div ngxSlickItem class="slide login__slide">
            <div class="lottie-center">
              <ng-lottie
                width="300px"
                height="200px"
                containerClass="moving-box another-class"
                class="center-loading"
                [options]="{path: '/assets/images/lottie/Onbarding_01_Welcome.json'}"
              ></ng-lottie>
            </div>
            <p class="login__slide--title">
              {{'login_title_1' | translate}}
            </p>
            <p class="login__slide--desc">
              {{'login_desc_1' | translate}}
            </p>
          </div>
          <div ngxSlickItem class="slide login__slide">
            <div class="lottie-center">
              <ng-lottie
                width="300px"
                height="200px"
                containerClass="moving-box another-class"
                class="center-loading"
                [options]="{path: '/assets/images/lottie/Onboarding_02_Choose-Products.json'}"
              ></ng-lottie>
            </div>
            <p class="login__slide--title">
              {{'login_title_2' | translate}}
            </p>
            <p class="login__slide--desc">
              {{'login_desc_2' | translate}}
            </p>
          </div>
          <div ngxSlickItem class="slide login__slide">
            <div class="lottie-center">
              <ng-lottie
                width="300px"
                height="200px"
                containerClass="moving-box another-class"
                class="center-loading"
                [options]="{path: '/assets/images/lottie/Onboarding_03_Fast-Delivery.json'}"
              ></ng-lottie>
            </div>
            <p class="login__slide--title">
              {{'login_title_3' | translate}}
            </p>
            <p class="login__slide--desc">
              {{'login_desc_3' | translate}}
            </p>
          </div>
        </ngx-slick-carousel>
        <div class="login__bottom--section">
          <p class="login__bottom--title">
            {{'login_create_account' | translate}}
          </p>
          <input class="login__mobile--input" type="text" pattern="\d*" maxlength="10" placeholder="{{'enter_number' | translate}}"
                 [(ngModel)]="phoneNumber">
          <p *ngIf="showErrorMsg" class="login__error--msg fade-in">{{'valid_number_error' | translate}}</p>
          <button class="login__btn" (click)="checkNumber()"
                  [class.disabled]="phoneNumber.length > 10  || phoneNumber.length < 10">
            {{'continue' | translate}}
          </button>
        </div>
      </div>
      <div *ngIf="loginStep === 'second'" class="login__second--step">
        <p class="number__verify--title">
          {{'verify_mobile' | translate}}
        </p>
        <p class="number__verify--text">
          {{'code_sent' | translate}}
        </p>
        <p class="number__text--container">
          {{phoneNumber}}
        </p>
        <div class="verification__code--container">
          <input class="login__mobile--input" type="text" pattern="\d*" maxlength="4" placeholder="{{'enter_code' | translate}}"
                 [(ngModel)]="confirmationCode">
          <button class="login__submit--btn" [disabled]="confirmationCode?.length < 4" (click)="submitVerificationCode()">{{'submit' | translate}}</button>
        </div>
        <p class="change__mobile--number" (click)="loginStep = 'first'; resetTimer(); confirmationCode = ''">
          {{'change_number' | translate}}
        </p>
        <p *ngIf="!showResendCode" class="resend__count--down">
          00:<span *ngIf="countDown < 10">0</span>{{countDown}}
        </p>
        <p (click)="resendCode()" *ngIf="showResendCode" class="resend__count--text fade-in">
          {{'resend_code' | translate}}
        </p>
      </div>
    </div>
  </div>
</div>
