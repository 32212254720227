import {Component, Input, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-home-slider',
  templateUrl: './home-slider.component.html',
  styleUrls: ['./home-slider.component.scss']
})
export class HomeSliderComponent implements OnInit {
  public carouselDir: boolean;
  public slideConfig;
  public isSwiping = false;
  @Input() isAds = false;
  @Input() slides = [];

  constructor(public translate: TranslateService, private router: Router) {
    translate.get('carousel_rtl').subscribe((text: string) => {
      this.carouselDir = text === 'true';
      this.slideConfig = {
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        arrows: false,
        dots: true,
        adaptiveHeight: true,
        rtl: this.carouselDir,
      };
    });
  }

  ngOnInit(): void {
  }


  public navigateToSlide(event, slideData): void {
    if (this.isSwiping) {
      event.stopPropagation();
    } else {
      if (slideData.type === 'category') {
        this.router.navigateByUrl(`/${localStorage.getItem('lang')}/sub-category/${slideData.link}/${slideData?.category_name}`);
      }
      if (slideData.type === 'product') {
        this.router.navigateByUrl(`/${localStorage.getItem('lang')}/product/` + slideData.link);
      }
    }
  }

}
