import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ProductService} from '../../services/product.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';
import {AppComponent} from '../../app.component';
import {ReplaceItemService} from '../../services/replaceItem.service';
import {Item} from '../../intefaces/IRplaceOrder';

@Component({
  selector: 'app-add-to-cart',
  templateUrl: './add-to-cart.component.html',
  styleUrls: ['./add-to-cart.component.scss']
})
export class AddToCartComponent implements OnInit {
  @Input() productType = 'meat';
  @Input() productData: any;
  @Input() orderProductData: Item;
  @Input() isReplace = false;
  @Output() toggleModal: EventEmitter<boolean> = new EventEmitter();

  public weightValue;
  public quantityValue;
  public priceAfterChange;
  public serviceChecked = false;

  // Cutting service vars
  public cuttingServiceSelected;
  public cuttingServiceId;
  public cuttingServiceSelectedPrice = 0;
  public cuttingOptionId;

  // Weight of chicken by piece
  public selectedChickenObj;
  public selectedChickenKey;
  public selectedChickenOptionId;

  // Spices service price
  public checkBoxServicePrice;
  public checkBoxServiceOptionId;
  public checkBoxServiceOptionValue;

  // Notes Option
  public customNotes;
  public notesOptionKey;

  constructor(private productService: ProductService,
              private snackBar: MatSnackBar,
              public translateService: TranslateService,
              public app: AppComponent,
              private replaceItemService: ReplaceItemService) {
  }

  ngOnInit(): void {
    this.weightValue = this.productData?.weight.min;
  }

  public addWeight(step): void {
    this.weightValue = this.weightValue + step;
    this.calculateNewPrice(this.weightValue, this.cuttingServiceSelectedPrice, parseFloat(this.selectedChickenObj?.price) || 0,
      this.checkBoxServicePrice);
  }

  public decreaseWeight(step): void {
    this.weightValue = this.weightValue - step;
    this.calculateNewPrice(this.weightValue, this.cuttingServiceSelectedPrice, parseFloat(this.selectedChickenObj?.price) || 0,
      this.checkBoxServicePrice);
  }

  public chooseChickenWeight(weightObj, optionKey): void {
    this.selectedChickenObj = weightObj;
    this.selectedChickenKey = weightObj.product_option_value_id;
    this.selectedChickenOptionId = optionKey;
    this.calculateNewPrice(this.weightValue, this.cuttingServiceSelectedPrice, parseFloat(this.selectedChickenObj?.price) || 0,
      this.checkBoxServicePrice);
  }

  public addCuttingOrRemovingService(service, price, serviceId, optionId): void {
    this.cuttingServiceId = serviceId;
    if (service === this.cuttingServiceSelected) {
      this.cuttingOptionId = null;
      this.cuttingServiceSelected = '';
      this.cuttingServiceSelectedPrice = 0;
      this.calculateNewPrice(this.weightValue, this.cuttingServiceSelectedPrice, parseFloat(this.selectedChickenObj?.price) || 0,
        this.checkBoxServicePrice);
    } else {
      this.cuttingOptionId = optionId;
      this.cuttingServiceSelected = service;
      this.cuttingServiceSelectedPrice = price;
      this.calculateNewPrice(this.weightValue, this.cuttingServiceSelectedPrice, parseFloat(this.selectedChickenObj?.price) || 0,
        this.checkBoxServicePrice);
    }
  }


  public addCheckboxService(servicePrice, optionKey, optionValue): void {
    if (this.serviceChecked) {
      this.checkBoxServicePrice = servicePrice;
      this.checkBoxServiceOptionId = optionKey;
      this.checkBoxServiceOptionValue = optionValue;
      this.calculateNewPrice(this.weightValue, this.cuttingServiceSelectedPrice, parseFloat(this.selectedChickenObj?.price) || 0,
        this.checkBoxServicePrice);
    } else {
      this.checkBoxServicePrice = 0;
      this.calculateNewPrice(this.weightValue, this.cuttingServiceSelectedPrice, parseFloat(this.selectedChickenObj?.price) || 0,
        this.checkBoxServicePrice);
    }
  }

  public addProductToCart(): void {
    const productObj: any = {
      product_id: this.productData.product_id,
      options: {}
    };
    if (this.productData?.weight) {
      productObj.quantity = this.weightValue;
    }
    if (this.selectedChickenOptionId) {
      // productObj.options.push({[this.selectedChickenOptionId]: this.selectedChickenKey});
      productObj.options[this.selectedChickenOptionId] = this.selectedChickenKey;
    }
    if (this.cuttingOptionId) {
      // productObj.options.push({[this.cuttingOptionId]: this.cuttingServiceId});
      productObj.options[this.cuttingOptionId] = this.cuttingServiceId;
    }
    if (this.checkBoxServiceOptionId) {
      // productObj.options.push({[this.checkBoxServiceOptionId]: this.checkBoxServiceOptionValue});
      productObj.options[this.checkBoxServiceOptionId] = this.checkBoxServiceOptionValue;
    }
    if (this.notesOptionKey) {
      // productObj.options.push({[this.notesOptionKey]: this.customNotes});
      productObj.options[this.notesOptionKey] = this.customNotes;
    }
    this.productService.addProductToCart(productObj).subscribe(
      (res) => {
        this.app.getCart();
        this.toggleModal.emit(false);
        this.snackBar.open(res.message, this.translateService.instant('snack_bar_close'), {
          duration: 3500,
          panelClass: this.translateService.instant('dir')
        });
      },
      (err) => {
        this.snackBar.open(err.error.error.message, this.translateService.instant('snack_bar_close'), {
          duration: 3500,
          panelClass: this.translateService.instant('dir')
        });
      }
    );
  }

  public calculateNewPrice(quantity: number = 0, service: number = 0, chickenWeight: number = 0, spices: number = 0): void {
    let customChickenPrice = 0;
    if (this.productData?.weight.isChickenByPiece) {
      if (chickenWeight) {
        customChickenPrice = (chickenWeight * 0.01) * (parseFloat(this.productData.special) || parseFloat(this.productData.price));
      } /*else {
        chickenWeight = 10;
        customChickenPrice = (chickenWeight) * (parseFloat(this.productData.special) || parseFloat(this.productData.price));
      }*/
    }
    this.priceAfterChange =
      ((parseFloat(this.productData.special) || parseFloat(this.productData.price)) + service + customChickenPrice + spices) * quantity;
  }

  public onReplaceProduct(): void {

    const replaceProductObj: any = {
      productId: this.productData.product_id,
      orderId: this.orderProductData.order_id,
      orderProductId: this.orderProductData.order_product_id,
      options: {}
    };

    if (this.productData?.weight) {
      replaceProductObj.quantity = this.weightValue;
    }
    if (this.selectedChickenOptionId) {
      replaceProductObj.options[this.selectedChickenOptionId] = this.selectedChickenKey;
    }
    if (this.cuttingOptionId) {
      replaceProductObj.options[this.cuttingOptionId] = this.cuttingServiceId;
    }
    if (this.checkBoxServiceOptionId) {
      replaceProductObj.options[this.checkBoxServiceOptionId] = this.checkBoxServiceOptionValue;
    }
    if (this.notesOptionKey) {
      replaceProductObj.options[this.notesOptionKey] = this.customNotes;
    }
    this.replaceItemService.replaceItem(replaceProductObj).subscribe((res) => {
        this.toggleModal.emit(false);
        window.location.reload();
      },
      (err) => {
        window.location.reload();
      });
  }

}
