import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CartService} from '../../services/cart.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';
import {BrowserService} from '../../services/browser.service';
import {AppComponent} from '../../app.component';

@Component({
  selector: 'app-cart-listing',
  templateUrl: './cart-listing.component.html',
  styleUrls: ['./cart-listing.component.scss']
})
export class CartListingComponent implements OnInit {
  @Input() isCheckout: boolean;
  @Input() cartItems: any;
  @Input() isOrderDetails: boolean;
  @Input() isDelivered: boolean;
  @Input() isReplaced: boolean;
  @Output() cartObj: EventEmitter<any> = new EventEmitter();
  @Output() reorder: EventEmitter<any> = new EventEmitter();
  @Output() productDeleted: EventEmitter<any> = new EventEmitter();

  constructor(private cartService: CartService, private snackBar: MatSnackBar, public translateService: TranslateService,
              public browserService: BrowserService, public app: AppComponent) {
  }

  ngOnInit(): void {
  }

  public removeProductFromCart(productId, e): void {
    e.stopPropagation();
    this.cartService.removeProductFromCart(productId).subscribe(
      (res) => {
        this.app.getCart();
        this.productDeleted.emit(true);
        this.snackBar.open(res.message, this.translateService.instant('snack_bar_close'), {
          duration: 3500,
          panelClass: this.translateService.instant('dir')
        });
      },
      (err) => {
        this.snackBar.open(err.error.error.message, this.translateService.instant('snack_bar_close'), {
          duration: 3500,
          panelClass: this.translateService.instant('dir')
        });
      }
    );
  }

  public changeQuantity(productKey, quantity, operator): void {
    let quantityObj: any;
    if (operator === 'add') {
      quantity++;
      quantityObj = {
        quantity: {
          [productKey]: quantity
        }
      };
    } else {
      quantity--;
      quantityObj = {
        quantity: {
          [productKey]: quantity
        }
      };
    }
    this.cartService.updateQuantityInCart(quantityObj).subscribe(
      (res) => {
        this.cartObj.emit(res.cart);
        this.cartItems = res.cart.products;
        this.app.getCart();
      }, (err) => {
        this.snackBar.open(err.error.error.message, this.translateService.instant('snack_bar_close'), {
          duration: 3500,
          panelClass: this.translateService.instant('dir')
        });
      }
    );
  }

  public getCurrentLang(): string {
    return localStorage.getItem('lang') || 'ar';
  }

  public linkToSubCategory(item): void {
    if (item?.stock === false && !this.isCheckout) {
      const subCat = item?.sub_category?.name?.replace(/(&amp;|[^\w\s])/gi, '').replace(/\s+/g, '-');
      window.location.href = `/${this.getCurrentLang()}/sub-category/${item?.sub_category?.id}/${subCat}`;
    }
  }


}
