<div class="order__history--container fade-in">
  <p class="basic__info--title">
    {{'order_history' | translate}}
  </p>
  <p class="basic__info--desc">
    {{'order_history_desc' | translate}}
  </p>
  <div class="single__order--container" [ngClass]="'order-' + order.status_id" *ngFor="let order of orderHistory">

    <div class="order__upper--section" [ngClass]="'order-' + order.status_id">
      <div class="order__title">{{order.status}}</div>
      <div class="order__amount"
           [ngStyle]="{'textAlign' : getCurrentLang() === 'ar' ?  'left' : 'right'}"> {{'order_history_total' | translate}}
        : {{order.total}} {{'currency' | translate}}</div>
    </div>
    <div class="border__upper--section" [ngClass]="'order-' + order.status_id"></div>

    <div class="d-flex justify-content-between">
      <div>
        <p class="order__info--title">{{'order_history_date' | translate}}</p>
        <p class="order__info--value">{{order.dateAdded}}</p>
      </div>
      <div>
        <p class="order__info--title">{{'order_history_number' | translate}}</p>
        <p class="order__info--value">#{{order.order_id}}</p>
      </div>
      <div *ngIf="!browserService.isMobileDevice()">
        <ng-container *ngIf="order?.status_id == 100; else replaceLink">
          <a class="view__order--details"
             [routerLink]="['/', getCurrentLang(), 'replace', 'order', order.order_id]">{{ 'order_history_details' | translate }}</a>
        </ng-container>
        <ng-template #replaceLink>
          <a class="view__order--details"
             [routerLink]="['/', getCurrentLang(), 'order', order.order_id]">{{ 'order_history_details' | translate }}</a>
        </ng-template>
      </div>
    </div>

    <div class="text-center" *ngIf="browserService.isMobileDevice()">
      <ng-container *ngIf="order?.status_id == 100; else replaceLink">
        <a class="view__order--details"
           [routerLink]="['/', getCurrentLang(), 'replace', 'order', order.order_id]">{{ 'order_history_details' | translate }}</a>
      </ng-container>

      <ng-template #replaceLink>
        <a class="view__order--details"
           [routerLink]="['/', getCurrentLang(), 'order', order.order_id]">{{ 'order_history_details' | translate }}</a>
      </ng-template>
    </div>

    <div *ngIf="order?.payment_code === 'vep'">
      <div>
        {{'payment_status' | translate}}
      </div>
      <div *ngIf="checkPaymentStatus(order).bool === false" class="unpaid">
        {{ checkPaymentStatus(order).status  | translate }}
      </div>
      <div *ngIf="checkPaymentStatus(order).bool === true" class="paid">
        {{  'paid'   | translate }}
      </div>

    </div>

  </div>
</div>
