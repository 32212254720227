import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ReplaceItemService {

  constructor(private apiService: ApiService) {
  }

  public getUnavailableItemsApi(orderId): Observable<any> {
    const url = 'cart/getUnavailableItems';
    return this.apiService.post(url, {
      orderId
    });
  }

  public refundItem(orderId, orderProductId, productId): Observable<any> {
    const url = 'cart/deleteProductItem';
    return this.apiService.post(url, {
        orderProductId,
        orderId,
        productId
      }
    );
  }

  public replaceItem({orderId, orderProductId, productId, quantity, options}): Observable<any> {
    const url = 'cart/replaceProductItem';
    return this.apiService.post(url, {
        orderProductId,
        orderId,
        productId,
        quantity,
        options
      }
    );
  }

}
